import '../byline';
import '../dynamic_size_image';
import '../entry_group_list';
import '../entry_stat';
import '../video_embed';
import './index.scss';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import UserAgent from 'lib/user_agent';
import Time from 'lib/time';
import GroupView from './scripts/group_view';

Initializer.registerComponent('site/entry_box/entry_group', function(element) {
  new GroupView({ el: element });
});

Initializer.registerComponent('site/entry_box/compact_video_entry', function(element, data) {
  if (UserAgent.isMobile()) {
    var imageWrapper = $(element).find('.c-entry-box--compact__image-wrapper');
    imageWrapper.removeAttr('src');
    Initializer.get('site/video_embed').embed(imageWrapper[0], data);
  } else {
    $(element).on('click', '.c-entry-box--compact__image-wrapper', (evt) => {
      evt.preventDefault();
      Initializer.get('site/video_embed').modal(data);
    });
  }
}, { waitForConsent: true });

Initializer.registerComponent('site/entry_box/stream_item', function(element, data) {
  var dateFormat = Time.combinedRelative(data.timestamp * 1000);

  if (dateFormat) {
    $(element).find('[data-relative-timestamp]').text(dateFormat);
  }
});
