/* global Chorus: false */
/**
* <p>Context manages data for the current session state.
* This includes data for the current user, member, community, network, and organization.</p>
*
* <p><b>Structure</b><br>
* The Context model is actually five models in one, and is designed to mirror the
* structure of the core Chorus model architecture:</p>
* <ul>
*   <li><code>currentUser</code> mirrors Chorus <code>current_user</code></li>
*   <li><code>currentMember</code> mirrors Chorus <code>current_member</code></li>
*   <li><code>currentCommunity</code> mirrors Chorus <code>current_community</code></li>
*   <li><code>currentNetwork</code> mirrors Chorus <code>current_network</code></li>
*   <li><code>currentOrganization</code> mirrors Chorus <code>current_organization</code></li>
* </ul>
* <p>Each of these models may be imported and observed individually. Note that
* the data available on each of these models is only a subset of their Chorus equivalent.</p>
*
* <p><b>Fetching</b><br>
* These models must load data asynchronously via ajax. Therefore, whenever you need to
* access data from one of these models, you MUST first call <code>fetch</code> on the model,
* and then chain a callback onto the fetch promise (see example below). Alternatively,
* you may bind listeners onto the model and simply handle updates whenever the model
* changes (though remember to still call <code>fetch</code> to start loading data).</p>
*
* <p>All of these models (user, member, community, network, and organization) fetch data through
* a single ajax request that loads data for all models. Technically, fetching one
* model will fetch all others, however you should still treat these models as if they
* each make standalone requests (to avoid dependencies in how the models are used).
* Under the hood, the Context module caches the one ajax request made, and returns that
* for all subsequent fetches. Thus, there is <em>no</em> performance penalty for
* calling <code>fetch</code> repeatedly across one or more Context models. So, the rule is:
* if you need data from one of these models, call <code>fetch</code> on it and trust
* data to only load once.</p>
*
* <p><b>Importing</b><br>
* The Context module exports a singleton instance of each of its models,
* so just import and use those existing instances.</p>
*
* @example
* // Note the syntax for importing individual models...
* import { currentUser } from 'lib/context';
* import { currentMember } from 'lib/context';
* import { currentCommunity } from 'lib/context';
* import { currentNetwork } from 'lib/context';
* import { currentOrganization } from 'lib/context';
*
* // Promise callback (for async procedures)
* currentUser.fetch().then(function() {
*   // ... you can now safely access currentUser data ...
* });
*
* // -- OR --
*
* // Event binding (for indefinite state handling)
* currentMember.on('change', function() {
*   // ... update with the new state of currentMember ...
* });
*
* // Don't forget to call fetch to make sure data gets loaded!
* currentMember.fetch();
*
* @module models/context
*/

import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';
import Cookies from 'js-cookie';

// privacy constants
const preferenceCookie = 'chorus_preferences';
const defaultPreferences = {
  cookies: 'none',
  doNotSell: false
};


// Detects privacy consent page mode.
// Instructs the model to block all user login data.
const preferencesVersion = 1;
const gdprConsent = document.cookie.indexOf('_chorus_geoip_continent=EU') > -1 && document.cookie.indexOf('_chorus_privacy_consent=') === -1;
const privacyMode = gdprConsent || document.cookie.indexOf('_chorus_ccpa_consent_donotsell') > -1;

/**
* Makes models jointly-fetchable through the context.
* Individual models (User, Member, etc) defer to context to make a single network request,
* and then allows context to handle populating each model with loaded data.
* These fetchable methods allow any method to request loading and observe fetch state.
* @mixin
*/
let Fetchable = {
  /**
  * Starts fetching context data once,
  * and then returns the same XHR promise thereafter.
  * There is no penalty for calling this method numerous times among multiple context models.
  * Repeat calls will simply bounce off the existing XHR promise.
  * Use this method by chaining onto the returned promise.
  *
  * @params {Object} options an object with option params. Accepts all Backbone options, as well as:
  * <ul><li>`reload`: reloads context data with a new Ajax request</li></ul>
  * @returns {Promise}
  *
  * @example
  * import { modelName } from 'lib/context';
  * modelName.fetch().then(function() { ... });
  */
  fetch: function(opts) {
    return this.ctx._fetch(opts);
  },

  /** Resets the model to a blank (default) state */
  reset: function() {
    this.set(this.defaults, { silent: true });
  }
};

let Context = Backbone.Model.extend(_.extend(
/** @lends module:models/context.Context.prototype */
  {
  /**
  * <p><b>For internal context use ONLY. Do not interface with directly.</b></p>
  * <p>This is The One Model to Rule Them All,
  * and thus handles the fetching, caching, and seeding of all child models.
  * This model is for internal context use. Do not interface with this model directly.
  * Instead, import the individual models (currentUser, currentMember, etc)
  * and interface with them individually.</p>
  * @constructs
  * @mixes Fetchable
  */
    url: '/services/user_context'+(privacyMode ? '?privacy=true' : ''),
    log: [],

    defaults: {
      auth_url: '',
      login_url: '',
      env: null,
      messaging: '',
      privacy: privacyMode,
      tos_notifications: [],
      preferences: { v: preferencesVersion, privacy: defaultPreferences }
    },

    initialize: function() {
      var u, m, c, n, o;

      // Load preferences from cookie,
      // If it doesn't exist, or is an older version, overwrite with defaults
      // If cookies isn't valid JSON, overwrite it with defaults
      try {
        const cookiePreferences = Cookies.getJSON(preferenceCookie);
        if (cookiePreferences && cookiePreferences.v == preferencesVersion) {
          this.set('preferences', cookiePreferences);
        } else {
          this.writePreferencesToCookie();
        }
      } catch {
        this.writePreferencesToCookie();
      }

      /**
    * The currentUser model singleton. Import and use this directly.
    * @example
    * import { currentUser } from 'models/context';
    * @type {context.User}
    */
      this.currentUser = u = new User();

      /**
    * The currentMember model singleton. Import and use this directly.
    * @example
    * import { currentMember } from 'models/context';
    * @type {context.Member}
    */
      this.currentMember = m = new Member();

      /**
    * The currentCommunity model singleton. Import and use this directly.
    * @example
    * import { currentCommunity } from 'models/context';
    * @type {context.Community}
    */
      this.currentCommunity = c = new Community();

      /**
    * The currentNetwork model singleton. Import and use this directly.
    * @example
    * import { currentNetwork } from 'models/context';
    * @type {context.Network}
    */
      this.currentNetwork = n = new Network();

      /**
    * The currentOrganization model singleton. Import and use this directly.
    * @example
    * import { currentOrganization } from 'models/context';
    * @type {context.Organization}
    */
      this.currentOrganization = o = new Organization();

      // Assign all context references for fetchable members:
      u.ctx = m.ctx = c.ctx = n.ctx = o.ctx = this.ctx = this;
    },

    parse: function(data) {
      if (privacyMode) {
        data.user = data.member = {};
      }

      this.currentUser.set(data.user || {});
      this.currentMember.set(data.member || {});
      this.currentCommunity.set(data.community || {});
      this.currentNetwork.set(data.network || {});
      this.currentOrganization.set(data.organization || {});

      // Delete model fields from seed data:
      ['user', 'member', 'community', 'network', 'organization'].forEach(function(key) { delete data[key]; });

      // only include applicable notifications
      if (data.tos_notifications) {
        var uniqueNotices = {};

        data.tos_notifications = data.tos_notifications.filter((notice) => {
          var relevant = !uniqueNotices[notice.id];
          if (relevant && notice.geo_continent_constraint) {
            relevant = document.cookie.indexOf('_chorus_geoip_continent='+notice.geo_continent_constraint) >= 0;
          }
          if (relevant) {
            uniqueNotices[notice.id] = true;
          }
          return relevant;
        });
      }

      return data;
    },

    /*
  * Starts fetching the model once, and then perpetually returns the XHR receipt:
  * Use this by chaining onto the returned promise.
  * There is no penalty for calling this method numerous times among multiple components.
  * Repeat calls will simply bounce off the existing XHR promise.
  */
    _fetch: function(opts) {
      if (!this._ajax) {
        this.log.push('fetch');
        this._ajax = Backbone.Model.prototype.fetch.call(this, opts).done(() => this.log.push('fetch complete'));
      }
      return this._ajax;
    },

    /*
  * Starts fetching the user model once, and then perpetually returns the XHR receipt:
  * Use this by chaining onto the returned promise.
  * There is no penalty for calling this method numerous times among multiple components.
  * Repeat calls will simply bounce off the existing XHR promise.
  */
    fetchUser: function(opts) {
      if (opts && opts.refresh) {
        this.currentUser.reset();
        this._user = null;
        if (typeof Chorus === 'object') {
          Chorus.ssoLogin = null;
        }
      }

      if (!this._user) {
        var deferred = this._user = $.Deferred();

        this.log.push('Fetching Primary Context');
        this._fetch().done(() => {
          this.log.push('Primary complete, with user: '+this.currentUser.get('id'));

          // Initial context fetch is complete, check for user ID...
          if (this.currentUser.get('id') || privacyMode) {
            return deferred.resolve();
          }

          // User is not logged in (no user id).
          // Installing SSO services...
          var ssoUrl = this.get('auth_url') +'/sso/unison_request?community_id='+ this.currentCommunity.get('id') +'&t='+ Date.now();
          this.log.push('Fetching SSO-1: '+ssoUrl);

          _.loadScript(ssoUrl, () => {
            this.log.push('SSO-1 complete, with status: '+Chorus.ssoLogin);

            // SSO services installed, check for user login...
            if (!Chorus.ssoLogin) {
              return deferred.resolve();
            }

            // User may log in (an SSO login endpoint was provided).
            // Installing personalized SSO script to log the user in...
            var ssoLoginUrl = Chorus.ssoLogin;
            Chorus.ssoLogin = null;
            this.log.push('Fetching SSO-2: '+ssoLoginUrl);

            _.loadScript(ssoLoginUrl, () => {
              this.log.push('SSO-2 complete, with status: '+Chorus.ssoLogin);

              // Personalized login script installed, confirming login...
              if (!Chorus.ssoLogin) {
                return deferred.resolve();
              }

              // User is logged in.
              // Refetching user context with new logged-in session state....
              this.log.push('Fetching Secondary Context');
              this.url += '?t='+Date.now();
              Backbone.Model.prototype.fetch.call(this, opts).done(() => {
                this.log.push('Secondary complete, with user: '+this.currentUser.get('id'));
                deferred.resolve();
              });
            });
          });
        });
      }
      return this._user.promise();
    },

    /**
  * Specifies if the current environment is a production server.
  */
    isProd: function() {
      return this.get('env') === 'production';
    },

    /**
     * Set preferences wrapper.  Required to get around events not triggering
     */
    setPreferences: function(preferences) {
      this.set('preferences', preferences);
      this.writePreferencesToCookie();
      return this;
    },

    /**
   * Write preferences to cookie
   */
    writePreferencesToCookie: function() {
      const options = { expires: 365, secure: true };
      Cookies.set(preferenceCookie, this.get('preferences'), options);
    }

  }, Fetchable));

let User = Backbone.Model.extend(_.extend(Fetchable,
/** @lends module:models/context.User.prototype */
  {
  /**
  * This model manages data for the current user.
  * Mirrors the Chorus <code>current_user</code> model.
  *
  * @example
  * import { currentUser } from 'models/context';
  *
  * currentUser.fetch().then(function() {
  *   if (currentUser.isLoggedIn()) { ...
  * });
  *
  * @mixes Fetchable
  * @constructs
  */

  /*
  * User data attributes
  * to be pre-emptively populated from localStorage,
  * then fetched from Chorus to confirm login status.
  */
    defaults: {
      account_settings_url: null,
      ban_acknowledgement_needed: false,
      can_edit: false,
      contributor_agreement_accepted: false,
      country: null,
      edit_profile_url: null,
      email: null,
      first_name: null,
      id: null,
      is_network_member: false,
      is_community_member: false,
      last_login: null,
      last_name: null,
      network_admin: false,
      profile_image_url: null,
      profile_url: null,
      status: null,
      time_zone: null,
      username: null,
      user_version: null,
      geo: null,
      passes_paywall: false,
      paywall_member_id: null,
      auth0_id: null,
      must_verify_email: false
    },

    fetch: function(opts) {
      return this.ctx.fetchUser(opts);
    },

    /**
  * Specifies if the current user is logged in (or, if they have a user ID).
  */
    isLoggedIn: function() {
      return !!this.get('id');
    }

  }));

let Member = Backbone.Model.extend(_.extend(Fetchable,
/** @lends module:models/context.Member.prototype */
  {
  /**
  * This model manages data for the current user's community membership.
  * Mirrors the Chorus <code>current_member</code> model.
  *
  * @example
  * import { currentMember } from 'models/context';
  *
  * currentMember.fetch().then(function() { ... });
  *
  * @mixes Fetchable
  * @constructs
  */
    defaults: {
      ban_acknowledgement_needed: false,
      community_id: null,
      comment_count: null,
      display_on_masthead: false,
      favorite: false,
      last_visited_on: null,
      legacy_nickname: null,
      short_bio: null,
      status: null,
      user_id: null
    },

    fetch: function(opts) {
      return this.ctx.fetchUser(opts);
    }

  }));

let Organization = Backbone.Model.extend(_.extend(
/** @lends module:models/context.Organization.prototype */
  {
  /**
  * This model manages data for the currently active organization.
  * Mirrors the Chorus <code>current_organization</code> model.
  *
  * @example
  * import { currentOrganization } from 'models/context';
  *
  * currentOrganization.fetch().then(function() { ... });
  *
  * @mixes Fetchable
  * @constructs
  */
    defaults: {
      domain: null,
      id: false,
      name: null
    }

  }, Fetchable));

let Network = Backbone.Model.extend(_.extend(
/** @lends module:models/context.Network.prototype */
  {
  /**
  * This model manages data for the currently active network.
  * Mirrors the Chorus <code>current_network</code> model.
  *
  * @example
  * import { currentNetwork } from 'models/context';
  *
  * currentNetwork.fetch().then(function() { ... });
  *
  * @mixes Fetchable
  * @constructs
  */
    defaults: {
      domain: null,
      id: false,
      name: null,
      primary_community_url: '',
      primary_community_id: null
    }

  }, Fetchable));

let Community = Backbone.Model.extend(_.extend(
/** @lends module:models/context.Community.prototype */
  {
  /**
  * This model manages data for the currently active community.
  * Mirrors the Chorus <code>current_community</code> model.
  *
  * @example
  * import { currentCommunity } from 'models/context';
  *
  * currentCommunity.fetch().then(function() { ... });
  *
  * @mixes Fetchable
  * @constructs
  */

    defaults: {
      domain: null,
      id: false,
      name: null,
      terms_of_agreement: '',
      community_guidelines_url : null
    }

  }, Fetchable));

let ctx = window.Context = new Context();
export default ctx;
export const currentUser = ctx.currentUser;
export const currentMember = ctx.currentMember;
export const currentCommunity = ctx.currentCommunity;
export const currentNetwork = ctx.currentNetwork;
export const currentOrganization = ctx.currentOrganization;
