import '../../apps/auth';
import '../nav_list';
import '../disclaimer';
import '../input_header';
import './index.scss';
import Initializer from 'lib/initializer';
import GlobalHeaderView from './scripts/view';

Initializer.registerComponent('site/global_header', function(element, data) {
  new GlobalHeaderView({ el: element, data: data });
}, {
  priority: Initializer.HIGH
});
