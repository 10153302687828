import _ from 'lib/utils';

var popup;


/**
* Social sharing tools.
* These helpers manage formatting and sharing links via social services.
* Methods are provided to format URLs for sharing, and to pop social service windows.
* @example
* import Sharing from 'lib/sharing';
*
* Sharing.sharePage('facebook'); // << shares the current page
* Sharing.share('http://aweso.me', 'facebook');
*
* @module lib/sharing
*/
let Sharing = {
  campaign: null,
  content: null,
  medium: null,

  sources: {
    facebook: 'http://www.facebook.com/sharer.php?u=',
    twitter: 'https://twitter.com/intent/tweet?url=',
    pinterest: 'https://www.pinterest.com/pin/create/button/?url=',
    linkedin: 'https://www.linkedin.com/shareArticle?url=',
    flipboard: 'https://share.flipboard.com/bookmarklet/popout?v=2&url=',
    pocket: 'https://getpocket.com/save?url=',
    reddit: 'https://reddit.com/submit?url='
  },

  /**
  * Pops a sharer window for the provided URL and requested service name.
  * Automatically limits to one open sharer window at a time.
  * @param {String} url location to share.
  * @param {String} service name of the service to share on.
  * @param {Object} Additional params to pass to the servie, e.g. "text" to twitter.
  * @returns {undefined}
  */
  share: function(url, service, extraParams) {
    service = service.toLowerCase();
    var popupWidth = 570;
    var popupHeight = 570;

    switch (service) {
    case 'facebook': popupHeight = 279; break;
    case 'twitter': popupHeight = 279; break;
    case 'linkedin': popupHeight = 520; break;
    case 'pinterest': popupWidth = popupHeight = 750; break;
    case 'pocket': popupHeight = 615; break;
    case 'reddit': popupWidth = 850; break;
    }
    if (url[0] !== '#' && this.sources[service]) {
      // Close any existing popup window:
      if (popup && popup.close) popup.close();

      // Open new popup:
      var left = (screen.width / 2) - (popupWidth / 2);
      var top = (screen.height / 2) - (popupHeight / 2);
      popup = window.open(this.formatSharer(url, service, extraParams), '', 'width=' + popupWidth + ', height=' + popupHeight + ', top=' + top + ', left=' + left);

      if (global.twttr && service === 'twitter') {
        // Accommodate Twitter's notorious `widget.js`:
        // - Scenario: an embedded Twitter widget will include `widget.js`.
        // - Issue: widget applies its own popup behavior to all Twitter share links.
        // This results in two Twitter share popups when our share button is clicked.
        // - Fix: we disable `window.open` for the remainder of the event loop
        // so that our share window will open, but Twitter's will not.
        // NOTE: we are deliberately NOT using "evt.stopPropagation()" here,
        // because it blocks analytics tracking on social share clicks.
        var open = window.open;
        window.open = function() {};
        setTimeout(function() { window.open = open; }, 0);
      }
    }
  },

  /**
  * Convenience method for popping a share window for the current page URL.
  * @param {String} service name of the service to share on.
  * @returns {undefined}
  */
  sharePage: function(service) {
    this.share(location.href, service);
  },

  /**
  * Formats a link for a sharing service. Process:
  * <ol>
  *   <li>Checks to see if the link is already formatted as a sharer
  *     (ie: "<code>https://twitter.com/tweet?url=[encoded_url]&via=vox</code>").
  *     Pre-encoded links will be disassembled, their encoded URL formatted,
  *     and then reassembled.</li>
  *   <li>Otherwise, formats a plain URL for its intended sharing service.</li>
  * </ol>
  * @param {String} url location to format.
  * @param {String} service name of the service to format for.
  * @param {Object} Additional params to pass to the servie, e.g. "text" to twitter.
  * @returns {String} the service-formatted url.
  */
  formatSharer: function(url, service, extraParams) {
    var a = this.parseLink(url);
    // Extract host slug from shareable link:
    var host = a.hostname.replace(/.*(twitter|facebook|pinterest|linkedin|flipboard|pocket|reddit).*/, '$1');

    // Check if this is a pre-formatted sharer link.
    // ex: "https://twitter.com/intent/tweet?url=http%3A%2F%2Faweso.me&via=vox"
    if (host === service || (!service && this.sources[host])) {
      var sharerKey = (host === 'facebook') ? 'u' : 'url';
      var params = _.deparamitize(a.search);
      // Check if params contain the host's appropriate sharer key.
      // If so, we can pretty safely assume that this is a pre-formatted sharer link.
      if (params.hasOwnProperty(sharerKey)) {
        // Unpack encoded sharer link, format it, and then repackage it into a new sharer URL.
        url = decodeURIComponent(params[sharerKey]);
        params[sharerKey] = encodeURIComponent(this.formatLink(url, host));
        return [this.sources[host].split('?')[0], '?', _.paramitize(_.extend(params, extraParams || {})), a.hash].join('');
      }
    }
    var returnUrl = this.sources[service] + encodeURIComponent(this.formatLink(url, service));
    if (extraParams) {
      returnUrl = returnUrl + '&' + _.paramitize(extraParams);
    }

    return returnUrl;
  },

  /**
  * Applies UTM formatting to a plain-text URL.
  * All existing link params are serialized,
  * extended with UTM data, and then reconstituted.
  * You generally shouldn't need to call this method directly.
  * Favor using <code>formatSharer</code> instead.
  * @param {String} url location to format.
  * @param {String} service name of the service intended for sharing on.
  * @returns {String} the UTM-formatted URL string.
  */
  formatLink: function(url, service) {
    // Parse url via DOM anchor element:
    var a = this.parseLink(url);

    // Assemble a compiled sharing params object:
    // This stacks on {UTM Params} < {Link Params}
    var params = _.extend({
      utm_campaign: encodeURIComponent(this.campaign || location.hostname.replace(/(www\.)?(.+)\.com/, '$2')),
      utm_content: encodeURIComponent(this.content || 'entry'),
      utm_medium: encodeURIComponent(this.medium || 'social'),
      utm_source: service
    }, _.deparamitize(a.search));

    // host needs to end in a /
    var host = [a.protocol, '//', a.hostname].join('');
    host = host.replace(/\/?$/, '/');

    // Reassemble the url with normalized formatting:
    url = [host, a.pathname.replace(/(^\/|\/$)/g, ''), '?', _.paramitize(params), a.hash].join('');
    return url;
  },


  /**
  * Parses a url string into an anchor with easily accessible properties.
  * @param {String} url location to parse
  * @returns {Element} an anchor element with properties for protocol, hostname, pathname, and hash.
  * @private
  */
  parseLink: function(url) {
    var a = document.createElement('a');
    a.href = url;
    return a;
  }
};

export default Sharing;
