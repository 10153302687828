import $ from 'jquery';
import _ from 'lib/utils';

/**
* Provides a standard interface for managing ajax transactions.
* This is a mixin library, designed to extend other objects with a standard ajax protocol.
* @example
* import Ajax from 'lib/ajax';
*
* var Messenger = Ajax.extend({
*   urls: {
*     'inbox': '/postal/inbox',
*     'send': '/postal/send'
*   },
*
*   ajaxOptions: {method: 'post'},
*
*   send: function(mssg) {
*     return this.ajax('send', {
*       data: {message: mssg},
*       success: function() { ... },
*       error: function() { ... }
*     });
*   }
* })
*
* Messenger
*  .send('The monkey flies at midnight')
*  .done(function() { ... })
*  .fail(function() { ... })
* @module lib/ajax
*/

let AjaxUtils = {
  /**
  * Default options used for all Ajax requests.
  * Any defaults set here are superseded by
  * options passed directly into an <code>ajax</code> call.
  */
  ajaxOptions: {},

  /**
  * <p>URL endpoints table.
  * Defines key/value pairs for all endpoints that this object talks to.
  * Format key/value pairs as:</p>
  * <p><code>'name': '/endpoint/url/:id'</code></p>
  * <p>When a URL includes "/:path/:segment" portions,
  * these fields are swapped for attributes read from this object.
  */
  urls: {},

  /**
  * Gets a url from the <code>urls</code> object, with <code>/:path/:segments</code>
  * mapped to this object's attribute values of the same name.
  * @param {String} action key name to reference from the <code>urls</code> object.
  * @returns {String} the formatted URL with path segments mapped to this object.
  */
  urlFor: function(action, https=false) {
    var url = this.urls[action];

    if (url) {
      url = url.replace(/:([^\d\/\.]+)/g, (match, capture) => {
        if (typeof this.get === 'function') return this.get(capture);
        return this[capture];
      });

      if (https) {
        var protocol = /^(https?:)?\/\//;
        url = url.replace(protocol, 'https://');

        if (!protocol.test(url)) {
          url = location.origin.replace(protocol, 'https://') +'/'+ url.replace(/^\//, '');
        }
      }
    }
    return url;
  },

  /**
  * Kicks off an Ajax transaction from the object's table of URL endpoints.
  * @param {String} action URL key from the <code>urls</code> object to fetch for.
  * @param {Object} options object to extend the request with.
  * @returns {Promise} the promise object for the pending Ajax transaction.
  */
  ajax: function(action, options) {
    var deferred = $.Deferred();
    var settings = _.extend({
      method: 'get',
      dataType: 'json',
      timeout: 15000
    }, this.ajaxOptions, options || {});

    // Generate fetch URL:
    settings.url = settings.url || this.urlFor(action, settings.https);

    // Configure JSON data formatting for non-HTTPS post requests:
    if (typeof settings.data === 'object' &&
        String(settings.method).toLowerCase() === 'post' &&
        !settings.contentType) {
      settings.data = JSON.stringify(settings.data);
      settings.contentType = 'application/json';
      settings.processData = false;
    }

    settings.success = (response, status, xhr) => {
      // Invoke any passed callback function:
      if (options && typeof options.success === 'function') {
        options.success.call(this, response, xhr);
      }

      // Resolve the promised response:
      deferred.resolve(response);
    };

    settings.error = (xhr, status, message) => {
      // Collect any returned message field from the API:
      message = (xhr.responseJSON && xhr.responseJSON.message) || message;

      // Invoke any passed callback function:
      if (options && typeof options.error === 'function') {
        options.error.call(this, message, xhr);
      }

      // Reject the promised response:
      deferred.reject(message);
    };

    // Start Ajax, and attach a cancelation handle onto the returned promise:
    var xhr = $.ajax(settings);

    var promise = deferred.promise();

    promise.cancel = () => {
      if (xhr.state() === 'pending') xhr.abort();
    };

    return promise;
  },

  /**
  * Returns a resolved or rejected Ajax promise object,
  * which may be returned in place of an actual Ajax call.
  * Useful for when data has already been prefetched or cached,
  * so a stub promise may be returned instead of opening a new network request.
  * @param {Object} options to stub with. Accepts "success:boolean", "message:string".
  * @returns {Promise} a <em>resolved</em> promise indicating the stubbed state.
  */
  ajaxStub: function(opts) {
    return $.Deferred()[opts.success ? 'resolve' : 'reject'](opts.message).promise();
  },

  /**
  * Extend Ajax methodologies onto a provided object.
  * This is a convenience for applying Ajax helper methods onto an object.
  * @param {Object} object to extend with Ajax.
  */
  extend: function(obj) {
    for (var attr in this) {
      if (this.hasOwnProperty(attr) && !(attr in obj) && attr !== 'extend') {
        obj[attr] = this[attr];
      }
    }
    return obj;
  }
};

export default AjaxUtils;
