import Backbone from 'exoskeleton';
import $ from 'jquery';

let FilterListView = Backbone.View.extend({
  preventDefaultEvents : false,

  handleSelectChange: function(event) {
    if (this.preventDefaultEvents) {
      event.preventDefault();
      var slug = $(event.currentTarget).attr('value');
      this.selectItem(slug);
    } else {
      window.location = event.currentTarget.value;
    }
  },

  handleClick: function(event) {
    if (this.preventDefaultEvents) {
      event.preventDefault();
      var slug = $(event.currentTarget).attr('href');
      this.selectItem(slug);
    }
  },

  selectItem : function(slug) {
    this.$el.trigger('filter', slug);
    this.$('.is-selected').removeClass('is-selected');
    this.$('option[selected="selected"]').removeAttr('selected');
    this.$('*[data-slug="' + slug +'"]').addClass('is-selected');
    this.$('option[value="' + slug +'"]').attr('selected', 'selected');
  },

  events: {
    'change select': 'handleSelectChange',
    'click a': 'handleClick'
  },

  initialize: function(opts = {}) {
    this.$el.addClass('js-active');
    this.preventDefaultEvents = opts.prevent_default_events || false;
  }
});

export default FilterListView;
