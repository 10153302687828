import UserAgent from 'lib/user_agent';
import $ from 'jquery';

// Subtags are added to the end of all supported affiliate link URLs in stories.
// They allow our analytics team to track how people use those links.

// The following constructs the subtag data that must be retrieved on the client side.
// This includes the device the user is using, the place the user came from (referrer),
// and UTM tags in the story url, which are inserted via third-party JS.

// The logic for which tags a particular affiliate link URL needs is executed on the server.
// This just fills in the data where needed, by looking for placeholders.

// list of values that need to be informed by the client

const device = getDevice();
const referrer = shortenReferrer(document.referrer);
const UTM_PARAMS = {
  source: 'utm_source',
  medium: 'utm_medium',
  campaign: 'utm_campaign'
};


// list of placeholder strings returned by server

const devicePlaceholder = 'd-placeholder';
const sourcePlaceholder = 's-placeholder';
const mediumPlaceholder = 'm-placeholder';
const campaignPlaceholder = 'c-placeholder';
const referrerPlaceholder = 'r-placeholder';
const formatPlaceholder = 't-placeholder';


function getDevice() {
  const viewportWidth = window.innerWidth;

  // is mobile just means 'touch device', so we also check screen width for mobile vs tablet

  let deviceAbbreviation = '';
  if ( UserAgent.isMobile() && viewportWidth < 768 ) {
    deviceAbbreviation = 'M';
  } else if ( UserAgent.isMobile() && viewportWidth > 768 ) {
    deviceAbbreviation = 'T';
  } else {
    deviceAbbreviation = 'D';
  }
  return deviceAbbreviation;
}

//  shortens referrer to shortest usable string:
//  removes www.
//  keeps rest of host
//  keeps first directory in path
//  removes query and hash
//  host and path are separated by `/`
//  ported from nymag

function shortenHost(host) {
  return host.substr(0, 4) === 'www.' ? host.substr(4) : host;
}

function shortenReferrer(referrer) {
  var parts = referrer && referrer.match(/\/\/([^\/]+)(\/[^\/#?]+)?/),
    shortenedReferrer = '',
    formattedHost, firstDirectory;

  if (parts) {
    formattedHost = shortenHost(parts[1] || '').toLowerCase();
    firstDirectory = parts[2] || '';
    shortenedReferrer = formattedHost + (firstDirectory || '');
  }
  return shortenedReferrer;
}

// Gets the UTM value from a specific URM parameter
// from either the current page URL,
// or from the original page URL stored in dataLayer.
// For context, we have some code in GTM, enabled by site config,
// which cleans up the URL after GA reporting by removing the UTM
// params and storing the original URL in window.dataLayer.
function getUTM(param) {
  let utm = getUTMFromUrl(param, window.location.href);

  if (utm === '' && window.dataLayer && window.dataLayer.find(i => !!i.unmodified_page_url)) {
    utm = getUTMFromUrl(param, window.dataLayer.find(i => !!i.unmodified_page_url).unmodified_page_url);
  }

  return utm;
}

// Returns the value of a UTM param from a URL,
// or a blank string, if not present.
function getUTMFromUrl(param, url) {
  const linkToParse = new URL(url);
  const queryParams = linkToParse.searchParams;
  const utmParameterValue = queryParams.get(param);
  return (utmParameterValue ? utmParameterValue.trim() : '');
}

// returns subtag string truncated to length limit if needed
function applySubtagMaxLength(subtag, length) {
  var maxLength = length - 3 * (subtag.split(',').length - 1 + subtag.split('|').length - 1);

  return subtag.substr(0, maxLength);
}

// removes part of a string based on start and end indices
function cut(str, cutStart, cutEnd) {
  return str.substr(0, cutStart) + str.substr(cutEnd);
}

/**
 * extend server-side subtag on affiliate links with client-side params
 * @param {DOM element} element
 * @param {Object} data containing:
 * - subtag_max_length
 * - subtag_delim_length
 * - subtag_key
 * - subtag_data
 * - encode_subtag
 */
export default function addSubtags(element, data) {
  // element passed in may be <a> or a parent element
  var elements = $(element).is('a') ? $(element) : $(element).find('a').filter('[has-subtag="true"]');
  // loop through the links in the element
  elements.each(function() {
    var subtagKey = data.subtag_key;
    var subtagData = Object.assign({}, data.subtag_data);
    var originalSubtag = subtagData[subtagKey];

    // associate correct value with placeholder
    const placeholders = [
      [devicePlaceholder, device],
      [referrerPlaceholder, referrer],
      [sourcePlaceholder, getUTM(UTM_PARAMS.source)],
      [mediumPlaceholder, getUTM(UTM_PARAMS.medium)],
      [campaignPlaceholder, getUTM(UTM_PARAMS.campaign)],
      [formatPlaceholder, 'w']
    ];

    // loop through array of placeholders
    var delimiterLength = data.subtag_delim_length;
    var updatedSubtag = placeholders.reduce(function(result, placeholder) {
      var subtagVal = placeholder[1];
      if (subtagVal) {
        return result.replace(placeholder[0], subtagVal);
      }
      // if no subtag val, remove the key by using substr indices
      var placeholderText = placeholder[0];
      var key = placeholderText.split('-')[0];
      var keyDelimLength = parseInt(delimiterLength) + key.length;
      var beginning = result.indexOf(placeholderText) - keyDelimLength;
      var end = result.indexOf(placeholderText) + placeholderText.length;
      return cut(result, beginning, end);
    }, originalSubtag);

    // encode subtag if boolean is true
    if (data.encode_subtag) {
      updatedSubtag = encodeURIComponent(updatedSubtag);
    }

    // apply max length only to the _subtag_ portion of the url
    var maxLength = data.subtag_max_length;
    var truncatedSubtag = applySubtagMaxLength(updatedSubtag, maxLength);

    // replace the original subtag with the modified subtag in the rewritten url
    var rewrittenUrl = data.rewritten_url;
    rewrittenUrl = rewrittenUrl.replace(originalSubtag, truncatedSubtag);
    $(this).attr('href', rewrittenUrl);

    // remove subtag data attributes
    $(this).removeAttr('has-subtag');
  });
}
