import './index.scss';
import 'promise-polyfill';
import Initializer from 'lib/initializer';

var pending;

function load() {
  pending = pending || import(/* webpackChunkName: "app-table" */ './scripts/main');
  return pending;
}

Initializer.registerComponent('apps/interactive_table', function(element, data) {
  load().then(module => new module.default({ el: element, opts: data }));
});
