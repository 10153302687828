import './index.scss';
import Initializer from 'lib/initializer';
import DynamicImages from 'models/dynamic_images';


Initializer.registerComponent('site/dynamic_size_image', function(element, data) {
  DynamicImages.addEl(element, data);
}, {
  priority: Initializer.HIGH
});


// Legacy lazy images loader:
window.chorus_lazy_assets = function() {
  var images = document.querySelectorAll('.vox-lazy-load');
  for (var i=0; i < images.length; i++ ) {
    var el = images[i];

    DynamicImages.addEl(el, {
      image_id: el.getAttribute('data-imgkey') || null,
      asset_id: el.getAttribute('data-chorus-asset-id') || null,
      ratio: el.getAttribute('data-ratio') || '*'
    });
  }
};


// Expose model globally for debugging:
window.DynamicImages = DynamicImages;

window.chorus_lazy_assets();
