import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';

Initializer.registerComponent('site/entry_correction', function(element) {
  $(element).find('[data-toggle]').on('click', function(e) {
    e.preventDefault();
    $(element).toggleClass('expanded');
  });
});
