const TIMEOUT = 5000;

export default {

  /**
   * Determines if adblock is present using ConcertAds adblock detection or
   * assumes adblock is present if ConcertAds script is blocked
   * @return {Promise}
   */
  checkForAdblock: function() {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve((window.concertAds && window.concertAds.adsBlocked) || (!window.concertAds));
      }, TIMEOUT);

    });
  }

};
