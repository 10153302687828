import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import { currentUser } from 'models/context';

Initializer.registerComponent('site/global_footer', function (element, data) {
  currentUser.fetch().then(() => {
    if (currentUser.isLoggedIn()) {
      var data = currentUser.toJSON();
      var menu = `
          <a href="${data.edit_profile_url}" class="u-block">Edit Profile</a>
          <a href="${data.account_settings_url}" class="u-block">Account Settings</a>
          <a href="/logout" class="u-block" data-ui="logout">Log out</a>
        `;
      var $el = $(element);
      $el.find('.c-footer__section--auth').html(menu);
      $el.on('click', '[data-ui="logout"]', function(evt) {
        var link = evt.currentTarget;
        var form = document.createElement('form');
        var input = document.createElement('input');
        input.type = 'submit';
        form.appendChild(input);
        form.method = 'post';
        form.action = link.href;
        form.style.display = 'none';
        document.body.appendChild(form);
        input.click();
        evt.preventDefault();
      });
    }
  });

  const cookiePreferenceButton = element.querySelector('#cookie-pref');
  if (cookiePreferenceButton) {
    cookiePreferenceButton.addEventListener('click', function (evt) {
      evt.preventDefault();
      document.querySelector('#privacy-options').classList.remove('m-privacy-options__hidden');
    });
  }
});
