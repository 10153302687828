import Backbone from 'exoskeleton';
import $ from 'jquery';

let ArchivesLoadMoreView = Backbone.View.extend({

  events: {
    'click .c-archives-load-more__button': 'handleClick'
  },

  initialize: function(evt) {
    this.$button = this.$('.c-archives-load-more__button');
    this.hasMoreEntries = true;
    this.currentPage = 1;
    this.resultsHTML = '';
    this.$resultsContainer = this.$('.c-archives-load-more__results');
  },

  handleClick: function() {
    // Disable button
    this.$button.prop('disabled', true);

    this.fetch().then(() => {
      // Append results to container
      this.$resultsContainer.append(this.resultsHTML);

      // Re-enable or remove button
      if (this.hasMoreEntries) {
        this.$button.prop('disabled', false);
      } else {
        this.$button.remove();
      }
    });
  },

  /**
  * Fetches more archive entries as JSON
  */
  fetch: function() {
    if (!this._load) {
      this._load = $.Deferred();
      var apiEndpoint = '/fetch' + window.location.pathname + '/' + ++this.currentPage;
      $.ajax({
        url: apiEndpoint,
        method: 'get',
        format: 'json',
        complete: (xhr) => {
          var response = JSON.parse(xhr.responseText);
          this.resultsHTML = response.html;
          this.hasMoreEntries = response.has_more;
          this._load.resolve();
          this._load = null;
        },
        error: () => {
          delete this._load;
        }
      });
    }

    return this._load.promise();
  }

});
export default ArchivesLoadMoreView;
