/**
* Relative time formatter
* This helper function formates relative date strings, such as:
* "Now", "A minute ago", "3 weeks ago", etc.
* @example
* import time from 'lib/time';
*
* time.relative(Date.now());
* // >> "Now"
* time.relative(new Date('July 4, 2015').getTime());
* // >> "6 months ago"
*
* @module lib/time
*/
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 4; // << general reporting threshold.
const YEAR = DAY * 365;
const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const WEEKDAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function formatTime(timestamp) {
  if (typeof timestamp === 'number') {
    timestamp = new Date(timestamp);
  } else if (typeof timestamp === 'string') {
    timestamp = new Date(timestamp).getTime();
  } else if (timestamp instanceof Date) {
    timestamp = timestamp.getTime();
  }
  return timestamp;
}

function formatRelative(quantity, label, article) {
  var unit = (quantity === 1) ? (article || 'A') : quantity;
  var plural = (quantity === 1) ? '' : 's';
  return `${ unit } ${ label }${ plural } ago`;
}

export default {
  EST: -5,

  /**
  * Calculates relative time.
  * @param {Number|Date|String} timestamp past time to relativize.
  * @param {Number|Date|String} [current] time to relativize from. Uses Date.now() by default.
  * @returns {String} relative time string.
  */
  relative: function(timestamp, now) {
    now = formatTime(now || Date.now());
    timestamp = formatTime(timestamp || now);
    var elapsed = now - timestamp;

    if (elapsed < SECOND) {
      return 'Now';
    } else if (elapsed < MINUTE) {
      return formatRelative(Math.round(elapsed / SECOND), 'second');
    } else if (elapsed < HOUR) {
      return formatRelative(Math.round(elapsed / MINUTE), 'minute');
    } else if (elapsed < DAY) {
      return formatRelative(Math.round(elapsed / HOUR), 'hour', 'An');
    } else if (elapsed < WEEK) {
      return formatRelative(Math.round(elapsed / DAY), 'day');
    }

    var pres = new Date(now);
    var past = new Date(timestamp);
    var months = (pres.getMonth() - past.getMonth()) + (pres.getFullYear() - past.getFullYear()) * 12;

    if (elapsed < MONTH || months === 0) {
      return formatRelative(Math.round(elapsed / WEEK), 'week');
    } else if (elapsed < YEAR) {
      return formatRelative(months, 'month');
    } else {
      return formatRelative(Math.round(elapsed / YEAR), 'year');
    }
  },

  /**
  * Formats an absolute timestamp.
  * @param {Number|Date|String} timestamp to format.
  * @returns {String} absolute time string.
  */
  absolute: function(timestamp) {
    var d = (timestamp instanceof Date) ? timestamp : new Date(timestamp);
    var h = (d.getHours() + 11) % 12 + 1;
    var hh = (d.getHours() < 12) ? 'AM' : 'PM';
    var m = ('00'+d.getMinutes()).slice(-2);
    // var s = ('00'+d.getSeconds()).slice(-2);
    return `${MONTH_NAMES[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} | ${h}:${m} ${hh}`;
  },

  /**
  * Formats a relative timestamp for recent events,
  * then switches to absolute dates beyond time threshold.
  * @param {Number|Date|String} timestamp to format.
  * @returns {String} relative or absolute time string.
  */
  recentRelative: function(timestamp, limit, now) {
    now = formatTime(now || Date.now());
    limit = formatTime(now - (limit || DAY));
    timestamp = formatTime(timestamp || now);
    return (timestamp > limit) ? this.relative(timestamp, now) : this.absolute(timestamp);
  },

  /**
  * Gets a local date mapped to a server in a provided timezone.
  * @param {Number} timezone offset (in hours) of server.
  * @param {Number|Date|String} timestamp to format.
  */
  server: function(timezone, now) {
    var client = new Date(formatTime(now || Date.now()));
    var utc = client.getTime() + client.getTimezoneOffset() * MINUTE;
    return new Date(utc + timezone * HOUR);
  },

  /**
  * Gets the name of a month.
  */
  nameOfMonth: function(index) {
    return MONTH_NAMES[index];
  },

  /**
  * Gets the name of a week day.
  */
  nameOfDay: function(index) {
    return WEEKDAY_NAMES[index];
  },

  timezoneAbbreviation: function(tzName) {
  /* eslint-disable */
  /*
    some of these have apostrophes, and rather than escape them and risk it not working, i'm just going to use
    double quotes. Sorry/Not sorry. - ben alt
  */
    var abv = {
      "australian central daylight savings time": "ACDT", "australian central standard time": "ACST", "acre time": "ACT", "asean common time": "ACT", "australian central western standard time": "ACWST", "atlantic daylight time": "ADT", "australian eastern daylight savings time": "AEDT", "australian eastern standard time": "AEST", "afghanistan time": "AFT", "alaska daylight time": "AKDT", "alaska standard time": "AKST", "alma-ata time": "ALMT", "amazon summer time": "AMST", "amazon time": "AMT", "armenia time": "AMT", "anadyr time": "ANAT", "aqtobe time": "AQTT", "argentina time": "ART", "arabia standard time": "AST", "atlantic standard time": "AST", "australian western standard time": "AWST", "azores summer time": "AZOST", "azores standard time": "AZOT", "azerbaijan time": "AZT",
      "brunei time": "BDT", "british indian ocean time": "BIOT", "baker island time": "BIT", "bolivia time": "BOT", "brasília summer time": "BRST", "brasilia time": "BRT", "bangladesh standard time": "BST", "bougainville standard time": "BST", "british summer time": "BST", "bhutan time": "BTT",
      "central africa time": "CAT", "cocos islands time": "CCT", "central daylight time": "CDT", "cuba daylight time": "CDT", "central european summer time": "CEST", "central european time": "CET", "chatham daylight time": "CHADT", "chatham standard time": "CHAST", "choibalsan standard time": "CHOT", "choibalsan summer time": "CHOST", "chamorro standard time": "CHST", "chuuk time": "CHUT", "clipperton island standard time": "CIST", "central indonesia time": "CIT", "cook island time": "CKT", "chile summer time": "CLST", "chile standard time": "CLT", "colombia summer time": "COST", "colombia time": "COT", "central standard time": "CST", "china standard time": "CST", "cuba standard time": "CST", "china time": "CT", "cape verde time": "CVT", "central western standard time": "CWST", "christmas island time": "CXT",
      "davis time": "DAVT", "dumont d'urville time": "DDUT", "aix": "DFT",
      "easter island summer time": "EASST", "easter island standard time": "EAST", "east africa time": "EAT", "eastern caribbean time": "ECT", "ecuador time": "ECT", "eastern daylight time": "EDT", "eastern european summer time": "EEST", "eastern european time": "EET", "eastern greenland summer time": "EGST", "eastern greenland time": "EGT", "eastern indonesian time": "EIT", "eastern standard time": "EST",
      "further-eastern european time": "FET", "fiji time": "FJT", "falkland islands summer time": "FKST", "falkland islands time": "FKT", "fernando de noronha time": "FNT",
      "galápagos time": "GALT", "galapagos time": "GALT", "gambier islands time": "GAMT", "georgia standard time": "GET", "french guiana time": "GFT", "gilbert island time": "GILT", "gambier island time": "GIT", "greenwich mean time": "GMT", "south georgia and the south sandwich islands time": "GST", "gulf standard time": "GST", "guyana time": "GYT",
      "hammer time": "STOP", "hawaii–aleutian daylight time": "HDT", "heure avancée d'europe centrale": "HAEC", "hawaii–aleutian standard time": "HST", "hong kong time": "HKT", "heard and mcdonald islands time": "HMT", "hovd summer time": "HOVST", "hovd time": "HOVT",
      "indochina time": "ICT", "international day line west time zone": "IDLW", "israel daylight time": "IDT", "indian ocean time": "IOT", "iran daylight time": "IRDT", "irkutsk time": "IRKT", "iran standard time": "IRST", "indian standard time": "IST", "irish standard time": "IST", "israel standard time": "IST",
      "japan standard time": "JST", "kaliningrad time": "KALT", "kyrgyzstan time": "KGT", "kosrae time": "KOST", "krasnoyarsk time": "KRAT", "korea standard time": "KST",
      "lord howe standard time": "LHST", "lord howe summer time": "LHST", "line islands time": "LINT",
      "magadan time": "MAGT", "mawson station time": "MAWT", "mountain daylight time": "MDT", "middle european time same zone as cet": "MET", "middle european summer time same zone as cest": "MEST", "marshall islands time": "MHT", "macquarie island station time": "MIST", "marquesas islands time": "MIT", "myanmar standard time": "MMT", "moscow time": "MSK", "malaysia standard time": "MST", "mountain standard time": "MST", "mauritius time": "MUT", "maldives time": "MVT", "malaysia time": "MYT",
      "new caledonia time": "NCT", "newfoundland daylight time": "NDT", "norfolk island time": "NFT", "novosibirsk time": "NOVT", "nepal time": "NPT", "newfoundland standard time": "NST", "newfoundland time": "NT", "niue time": "NUT", "new zealand daylight time": "NZDT", "new zealand standard time": "NZST",
      "omsk time": "OMST", "oral time": "ORAT",
      "pacific daylight time": "PDT", "peru time": "PET", "kamchatka time": "PETT", "papua new guinea time": "PGT", "phoenix island time": "PHOT", "philippine time": "PHT", "pakistan standard time": "PKT", "saint pierre and miquelon daylight time": "PMDT", "saint pierre and miquelon standard time": "PMST", "pohnpei standard time": "PONT", "pacific standard time": "PST", "philippine standard time": "PST", "paraguay summer time": "PYST", "paraguay time": "PYT",
      "réunion time": "RET", "reunion time": "RET", "rothera research station time": "ROTT",
      "sakhalin island time": "SAKT", "samara time": "SAMT", "south african standard time": "SAST", "solomon islands time": "SBT", "seychelles time": "SCT", "samoa daylight time": "SDT", "singapore time": "SGT", "sri lanka standard time": "SLST", "srednekolymsk time": "SRET", "suriname time": "SRT", "samoa standard time": "SST", "singapore standard time": "SST", "showa station time": "SYOT",
      "tahiti time": "TAHT", "thailand standard time": "THA", "french southern and antarctic time": "TFT", "tajikistan time": "TJT", "tokelau time": "TKT", "timor leste time": "TLT", "turkmenistan time": "TMT", "turkey time": "TRT", "tonga time": "TOT", "tuvalu time": "TVT",
      "ulaanbaatar summer time": "ULAST", "ulaanbaatar standard time": "ULAT", "coordinated universal time": "UTC", "uruguay summer time": "UYST", "uruguay standard time": "UYT", "uzbekistan time": "UZT",
      "venezuelan standard time": "VET", "vladivostok time": "VLAT", "volgograd time": "VOLT", "vostok station time": "VOST", "vanuatu time": "VUT",
      "wake island time": "WAKT", "west africa summer time": "WAST", "west africa time": "WAT", "western european summer time": "WEST", "western european time": "WET", "western indonesian time": "WIT", "western standard time": "WST",
      "yakutsk time": "YAKT", "yekaterinburg time": "YEKT"
    }
    /* eslint-enable */
    return abv[tzName.toLowerCase()] || tzName;
  },

  /**
  * Formats a timestamp relative to today ("Today at 1:23 pm EST")
  * @param {Number|Date|String} timestamp past time to relativize.
  * @param {Number|Date|String} [current] time to relativize from.
  * @returns {String|null} time string relative to today or null if date isn't relative to today.
  */
  relativeToToday: function(timestamp, now) {
    if (!timestamp || (typeof timestamp == 'number' && timestamp < 1)) {
      throw "'timestamp' parameter '" + timestamp + "' is invalid";
    }

    now = new Date(formatTime(now || Date.now()));
    timestamp = new Date(formatTime(timestamp || now));

    if (timestamp.toDateString() !== now.toDateString()) {
      return null;
    }

    var format = timestamp.toTimeString(); // 08:00:02 GMT-0500 (EST) or 08:00:02 GMT-0500 (Central Daylight Time)
    var time = format.split(' ')[0];
    var period = parseInt(time.split(':')[0]) >= 12 ? 'pm' : 'am';

    // convert time to 12-hour clock
    var hh = parseInt(time.split(':')[0]) % 12;
    if (hh === 0) hh = 12;
    var mm = time.split(':')[1]; // 0-padded

    // match the contents of "({TimeZone})"
    var fullTz = /\((.+)\)/.exec(format);
    var tz = '';

    if (fullTz && fullTz[1]) {
      // attempt to use TLA if available, otherwise first word
      tz = this.timezoneAbbreviation(fullTz[1]);
    }

    return `Today at ${ hh }:${ mm }${ period } ${ tz }`.trim();
  },

  /**
  * Formats a timestamp depending on how much time has passed.
  *   If timestamp is more than two hours old: "Today at 1:23pm EST"
  *   Otherwise: "23 minutes ago"
  * @param {Number|Date|String} timestamp past time to relativize.
  * @param {Number|Date|String} [current] time to relativize from.
  * @returns {String|null} relative time string relative or null if date isn't relative to today.
  */
  combinedRelative: function(timestamp, now) {
    now = new Date(formatTime(now || Date.now()));
    var ageInMinutes = Math.floor(((now - timestamp)/1000)/60);

    if (ageInMinutes <= 120) {
      return this.relative(timestamp, now);
    } else {
      return this.relativeToToday(timestamp, now);
    }
  }

};
