import Backbone from 'exoskeleton';

let ShowcaseView = Backbone.View.extend({
  initialize: function() {
    this.index = 0;
  },

  toggleMedia: function(evt) {
    this.$(evt.currentTarget).addClass('active').siblings().removeClass('active');
    this.index = this.$(evt.currentTarget).data('index');
    this.$(`[data-media="${this.index}"]`).addClass('visible').siblings().removeClass('visible');
  },

  events: {
    'click [data-ui="toggle_media"]': 'toggleMedia'
  }

});

export default ShowcaseView;
