import './index.scss';
import 'promise-polyfill';
import Initializer from 'lib/initializer';

var authAppLoaded = false;

// Lazy module load:
// the auth module is chunked out to load on-demand via code splitting
function load(callback) {
  import(/* webpackChunkName: "app-auth" */ './scripts/main')
    .then(module => callback(module.default));
}


// Static DOM (for standalone signup and login pages)
Initializer.registerComponent('apps/auth', (element, data) => {
  load(function(AuthApp) {
    if (!authAppLoaded) {
      authAppLoaded = true;
      new AuthApp(element, data);
    }
  });
}, {
  /**
  * Opens the auth widget in a modal dialog.
  * @param {Object} options data used to configure the Auth window. Accepts:
  * <ul><li>`action`: the auth screen to start on. Values include "login", "signup", "logout".</li></ul>
  * @example
  * import Auth from 'apps/auth';
  * Auth.open({ action: 'login' });
  */
  open: function(opts) {
    load((AuthApp) => AuthApp.modal(opts));
  }
});
