import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';
import Analytics from 'lib/analytics';


// const MOBILE_BREAK = 600;
const FIXED_CLASS = 'sub-nav-fixed';

var SubnavView = Backbone.View.extend({
  $body : $('body'),

  // Initializes the model with events:
  initialize: function() {
    this.setNavItemsDisplay();
    this.nudgeSubmenus();
    this.attachListeners();
    // Wait for fonts to finish loading and re-calculate nav item widths
    // IE11 doesn't support promises, hence double call
    Initializer.get('site/font_loader').load().then(() => {
      this.setNavItemsDisplay();
      this.nudgeSubmenus();
    });
    $(window).on('resize', _.debounce(this.setNavItemsDisplay.bind(this), 200));
    $(window).on('resize', _.debounce(this.nudgeSubmenus.bind(this), 200));

    var $startFixedPositionElement = $('.main-social');

    var isFixed = false;
    var overrideDefaultBehavior = false;

    var $win = $(window).on('scroll', _.throttle(() => {
      var scrollTop = $win.scrollTop();
      var startingVisibility = isFixed;
      // let's not touch the dom if we're not going to update anything anyways
      if (overrideDefaultBehavior) {
        return true;
      }

      // The start/end positions are continually re-calculated (instead of only once) because
      // new asynchronously loaded elements, such as ads, may change the offets.
      var startFixedPosition = $startFixedPositionElement.length ? $startFixedPositionElement.offset().top : 500;

      // Tab bar appears when the reader scrolls between the social buttons (near byline)
      // and bottom of the article body.
      var isAfterStartPosition = scrollTop >= startFixedPosition;
      isFixed = isAfterStartPosition;

      // Only touch the dom and fire an event if the state has changed
      if (startingVisibility !== isFixed) {
        this.toggleBarVisibility(isFixed);
      }
    }, 400));

  },

  events: {
    'click .c-subnav__link' : 'onLinkClick',
    'click .c-subnav__link-more' : 'onLinkClick'
  },

  toggleBarVisibility: function(isFixed) {
    this.$el.toggleClass(FIXED_CLASS, isFixed);
    document.dispatchEvent(new Event('unisonTabBarVisibility', {
      bubbles: true,
      detail: { 'visible': isFixed }
    }));
  },

  setNavItemsDisplay : function() {
    var $moreLink = this.$('.c-subnav__link-more');
    var viewableWidth = 0;
    var navItemsWidth = 0;
    var navItems = [];

    // this looks werid, but keeps the height from bouncing around when the sticky subnav is applied.
    var subnavHeight = $('.c-subnav').height();
    $('.c-subnav').height(subnavHeight);

    viewableWidth += $('.c-subnav').width();
    viewableWidth -= $('.c-subnav__parent').width();

    this.$('.c-subnav__link').each(function () {
      navItems.push(this);
    });

    navItemsWidth += $moreLink.width();

    navItems.forEach(function(item) {
      var $item = $(item);
      navItemsWidth += $item.width();
      var itemId = $item.attr('data-nav-item-id');
      var navListElement = $('.c-subnav__more-list').find('[data-nav-item-id=' + itemId + ']');

      if (navItemsWidth > viewableWidth) {
        $item.addClass('hidden');
        navListElement.removeClass('hidden');
      } else {
        $item.removeClass('hidden');
        navListElement.addClass('hidden');
      }
    });
    $moreLink.toggleClass('hidden', navItemsWidth <= viewableWidth);
    this.$el.addClass('ready');
  },

  onLinkClick : function(evt) {
    Analytics.nav('click', evt.currentTarget.innerText.trim());
  },

  nudgeSubmenus : function() {
    var submenus = this.$el.find('ul.c-subnav__child');
    var viewableWidth = $('.c-subnav').width();
    submenus.each((id, item) => {
      var width = $(item).width();
      var parent = $(item).parent();
      if (viewableWidth < parent.position().left + width) {
        $(item).addClass('right-align');
      } else {
        $(item).removeClass('right-align');
      }
    });
  },

  attachListeners : function() {
    $('button.c-subnav__label').on('mouseenter', $.proxy(this.handleTrigger, this));
    $('button.c-subnav__label').on('click', $.proxy(this.handleTrigger, this));
    $('body').on('mouseleave', 'li.is-attached', $.proxy(this.closeMenuHandler, this));
  },

  closeMenus : function() {
    this.$body.off('click', this.closeMenuHandler);
    this.$body.off('keyup', this.keyMenuHandler);
    $('.c-subnav__inner > ul > li.is-attached').removeClass('is-attached');
  },

  openMenu : function(elem) {
    this.$body.on('click', $.proxy(this.closeMenuHandler, this));
    this.$body.on('keyup', $.proxy(this.keyMenuHandler, this));
    $(elem).addClass('is-attached');
  },

  keyMenuHandler : function(event) {
    switch (event.which) {
    // ESCAPE
    case 27 :
      $('li.is-attached button').focus();
      this.closeMenus();
      break;
    // UP ARROW
    case 38:
      event.preventDefault();
      if ($('.c-subnav li a:focus').parent('li').prev().length) {
        $('.c-subnav li a:focus').parent('li').prev().find('a').focus();
      } else {
        $('li.is-attached button').focus();
      }
      break;
    // DOWN ARROW
    case 40:
      event.preventDefault();
      if ($(document.activeElement).hasClass('c-subnav__label')) {
        $('li.is-attached li:visible:first a').focus();
      } else {
        $('.c-subnav li a:focus').parent('li').next().find('a').focus();
      }
      break;
    default:
        // ¯\_(ツ)_/¯
    }
  },

  closeMenuHandler : function() {
    this.closeMenus();
  },

  handleTrigger : function(event) {
    event.stopPropagation();
    var parent = event.currentTarget.parentElement;
    var menuOpen = $(parent).hasClass('is-attached');
    this.closeMenus();
    if (!menuOpen) {
      this.openMenu(parent);
    }
  }

});

export default SubnavView;
