import Backbone from 'exoskeleton';

let NextclickLink = Backbone.Model.extend({
  defaults: {
    title : null,
    url: null
  }
});

let NextclickLinks = Backbone.Collection.extend({
  model : NextclickLink,

  initialize: function (data, options={}) {
    this.head = options.head || null;
    if (data && data.links) {
      this.reset(data.links);
    }
  }

});


export default NextclickLinks;
