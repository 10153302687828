import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import Time from 'lib/time';

Initializer.registerComponent('site/byline', function(element, data) {
  if (data.timestamp) {
    var dateFormat = Time.combinedRelative(data.timestamp * 1000);

    if (dateFormat) {
      $(element).find('[data-relative-timestamp]').text(dateFormat);
    }
  }
});
