import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import _ from 'lib/utils';

Initializer.registerComponent('site/group_subnav', function(element, data) {

  // $$ VARS

  var $mainLinks = $('.c-group-subnav__main .c-group-subnav__item');
  var $collapsedLinks = $('.c-group-subnav__collapsed .c-group-subnav__item');
  var $collapsedMenu = $('.c-group-subnav__collapsed');
  var $showAllButton = $('.c-group-subnav__see-all');
  var $showAllButtonText = $('.c-group-subnav__see-all span');
  var $collapsedH2 = $('#group-subnav--label-more');
  var totalNumberOfLinks = $mainLinks.length;

  // at each breakpoint, we want to show a certain number of links in the main nav

  setBreakpoints();

  $(window).resize(function() {
    _.debounce(setBreakpoints(), 200);
  });

  function setBreakpoints() {
    if ($(window).width() >= 1024) {
      setLargeScreenState();
    }
    if ($(window).width() <= 1024) {
      collapseLinks(12);
      showShowAllButton(12);
    }
    if ($(window).width() <= 728) {
      collapseLinks(9);
      showShowAllButton(9);
    }
    if ($(window).width() <= 600) {
      collapseLinks(6);
      showShowAllButton(6);
    }
  }

  // the show all button should only show if we have more links than the amount of links we collapse at
  function showShowAllButton(numberOfLinksToShow) {
    if (totalNumberOfLinks > numberOfLinksToShow) {
      $showAllButton.removeClass('hidden');
    }
  }

  function setLargeScreenState() {

    // on big screens, with infinite links,
    // we want to show everything in the main nav and no see all button

    $mainLinks.removeClass('hidden');
    $collapsedLinks.addClass('hidden');
    $showAllButton.addClass('hidden');
  }

  function collapseLinks(numberOfLinksToShow) {

    //  we're starting with all the links in both the main and collapsed navs
    //  in the main nav, hide the links if there's more than we want to show
    //  in the collapsed nav, hide the links we want to show in the main nav

    if (totalNumberOfLinks > numberOfLinksToShow) {
      for (var i = 0; i < totalNumberOfLinks; i++) {
        if(i >= numberOfLinksToShow) {
          $mainLinks.eq(i).addClass('hidden');
          $collapsedLinks.eq(i).removeClass('hidden');
        } else {
          $mainLinks.eq(i).removeClass('hidden');
          $collapsedLinks.eq(i).addClass('hidden');
        }
      }
    }
  }

  // the stuff that happens when you click the see all button
  function toggleCollapsedMenu() {
    $collapsedMenu.toggleClass('hidden');
    // toggles hidden just on the screenreader only H2 above the collapsed menu, so it's hidden from screenreaders when the menu is closed
    $collapsedH2.toggleClass('hidden');
    // makes down arrow point up on the button
    $showAllButton.toggleClass('arrow-up');
    // changes the text on the "see all" button when you're seeing all
    $showAllButtonText.text( $('.c-group-subnav__collapsed').hasClass('hidden') ? 'Show All' : 'Show Less' );
    toggleAriaAndFocus();
  }

  function toggleAriaAndFocus() {

    // toggle acessibility attribute & focus
    if ($collapsedMenu.attr('aria-expanded') === 'false') {
      $collapsedMenu.attr('aria-expanded', 'true');
      // focus on first visible link in menu if we open the collapser menu
      $('.c-group-subnav__collapsed[aria-expanded="true"] > li:not(.hidden) a').eq(0).focus();
    } else {
      $collapsedMenu.attr('aria-expanded', 'false');
    }
  }

  $showAllButton.click(toggleCollapsedMenu);

}, { priority: Initializer.HIGH });


