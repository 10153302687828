import Backbone from 'exoskeleton';
import $ from 'jquery';

var SearchTriggerView = Backbone.View.extend({

  initialize: function() {
  },

  $body : $('body'),

  events: {
    'click .c-global-header__search-trigger' : 'onSearchToggleClick',
    'click .c-global-header__search' : 'onSearchAreaClick'
  },

  onSearchAreaClick: function(evt) {
    evt.stopPropagation();
  },

  onSearchToggleClick: function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const CLICK_OFF_NS = 'click.search-bar';
    const SEARCH_ACTIVE_CLASS = 'is-search-active';
    var searchActive = this.$el.hasClass(SEARCH_ACTIVE_CLASS);

    if (!searchActive) {
      var $searchbar = this.$('.c-global-header__search-menu');
      this.$el.find('input[name="q"]').focus();
      this.$body.on(CLICK_OFF_NS, (evt) => {
        if (!$(evt.target).closest($searchbar).length) {
          this.$el.removeClass(SEARCH_ACTIVE_CLASS);
          this.$body.off(CLICK_OFF_NS);
        }
      });
    } else {
      this.$body.off(CLICK_OFF_NS);
    }

    this.$el.toggleClass(SEARCH_ACTIVE_CLASS, !searchActive);
  }
});

export default SearchTriggerView;
