import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';

Initializer.registerComponent('site/user_membership_list', function(el) {
  $(el).on('click', '[data-leave]', function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    var $bttn = $(evt.currentTarget);
    if (window.confirm('Are you sure you want to leave '+ $bttn.attr('data-leave') +'?')) {
      $bttn.closest('form').submit();
    }
  });
});
