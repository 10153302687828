import './index.scss';
import 'promise-polyfill';
import $ from 'jquery';
import _ from 'lib/utils';
import Initializer from 'lib/initializer';
import UserAgent from 'lib/user_agent';

const VISIBILITY_THRESHOLD = 0.25; // element must be > or < 25% visible to triggle toggle
const CHECK_INTERVAL = 200;
const iOS = UserAgent.iOSversion().iOS;
const supportsObjectFit = ('objectFit' in document.documentElement.style);
const supportsPicture = UserAgent.supportsPictureElement();
const supportsIO = !!window.IntersectionObserver;
var supportsPromises;

var videoIO;
var allVideos = [];
var videoEntriesToPlay = [];
var videosToPlay = [];
var videosToPause = [];


Initializer.registerComponent('site/gifv', function(element, data) {

  // referencing "element" all over, feels like using variable "x"
  var video = element;

  // needed high up the scope chain, debounce this to prevent thrashing
  var debounceSetVideoURL;

  // As of Dec 5, 2016 all browsers that support
  // .play() promises also support inline video playback.
  // Browsers that lack this support will get GIFs.
  // Previous API's also lacked any way to verify a video
  // actually played after calling .play(), which unacceptable
  // for emulating a GIF-like experience
  // Chrome and Safaris > v10, have support (~ 70% our of users)
  // And Firefox is en route: https://bugzilla.mozilla.org/show_bug.cgi?id=1244768
  if (supportsPromises === undefined) {
    var maybePromise = video.play();
    if (maybePromise && maybePromise.then) {
      supportsPromises = true;
    } else {
      supportsPromises = false;
    }
  }


  if (!supportsPromises) {
    // ALL OPTIONS MUST swap with picture and `return`, we can't do GIFv here

    if (!supportsPicture && !supportsObjectFit) {
      // MUST polyfill picture
      // MUST polyfill object fit
      // swap with picture
      // Example: IE's don't support pictures or object fit, needs kitchen sink
      return Promise.all([
        import('objectFitPolyfill'),
        import('picturefill')
      ]).then(([objectFit, pictureFill]) => {
        var replacement = swapWithFallback(video);
        objectFit(replacement.img);
        pictureFill({ elements: [replacement.img] });
      });
    }

    if (!supportsObjectFit) {
      // MUST polyfill object fit
      // swap with picture
      // Example: Edge doesn't doesn't object fit but some versions do support the picture element
      return import('objectFitPolyfill').then((objectFit) => {
        var replacement = swapWithFallback(video);
        objectFit(replacement.img);
      });
    }

    if (!supportsPicture) {
      // MUST polyfill picture
      // swap with picture
      // Example: doesn't support picture but does support object fit (very rare, Opera Mobile and Opera Mini only AFAIK)
      return import('picturefill').then((pictureFill) => {
        var replacement = swapWithFallback(video);
        pictureFill({ elements: [replacement.img] });
      });
    }

    // swap with picture
    // Example: FF supports pictures and object fit, doesn't need polyfill
    swapWithFallback(video);
    return;

  } else {
    // GIFv OK, but...

    if (!supportsIO && !supportsObjectFit) {
      // MUST load IO polyfill
      // MUST load object polyfill
      // Example: If Edge added .play promises before object fit & intersection oberser for instance

      Promise.all([
        import('objectFitPolyfill'),
        import('intersection-observer')
      ]).then(([objectFit]) => {
        init();
        objectFit(video);
      });

    } else if (!supportsObjectFit) {
      // GIFv OK, but...
      // MUST load object polyfill
      // Example [very unlikely]:
      // Example: If Edge added .play promises before object fit

      import('objectFitPolyfill').then((objectFit) => {
        init();
        objectFit(video);
      });

    } else if (!supportsIO) {
      // MUST load IO polyfill
      // Example: many browsers, all Safari, all FF, all Edge (if we made it this far)

      import('intersection-observer').then(init);

    } else {
      // but nothing, just init

      init();
    }

  }


  // Rock it out
  function init() {
    // Track all videos for calculating 'visible' deltas later
    allVideos.push(video);

    // upgrade video to device appropriate resolution
    setVideoURL(video, data);

    // Setup a single IntersectionObserver for all gifv components
    if (!videoIO) {
      videoIO = new IntersectionObserver(toggleVideos, {
        threshold: [0, 0.25, 0.5, 0.75, 1]
      });
      videoIO.POLL_INTERVAL = CHECK_INTERVAL;
    }

    // Track via IntersectionObserver
    videoIO.observe(video);

    // Track resize/orientation
    debounceSetVideoURL = _.debounce(function() {
      setVideoURL(video, data);
    }, CHECK_INTERVAL);
    $(window).on('resize orientationchange', debounceSetVideoURL);
  }


  function swapAndCleanup(video) {
    // detach listeners
    videoIO.unobserve(video);
    $(window).off('resize orientationchange', debounceSetVideoURL);

    // remove refs from all caches
    allVideos = allVideos.filter(function(cv) { return cv !== video; });
    videosToPlay = videosToPlay.filter(function(cv) { return cv !== video; });
    videosToPause = videosToPause.filter(function(cv) { return cv !== video; });
    videoEntriesToPlay = videoEntriesToPlay.filter(function(entry) {
      return entry.target !== video;
    });

    // swap el
    swapWithFallback(video);
  }

  function toggleVideos(newEntries) {
    newEntries.forEach(function(newEntry) {
      // remove any entries have the same target/node
      videoEntriesToPlay = videoEntriesToPlay.filter(function(entry) {
        return entry.target !== newEntry.target;
      });

      // now push the entry with the freshest data, knowing we aren't creating dups
      if (newEntry.intersectionRatio >= VISIBILITY_THRESHOLD) {
        videoEntriesToPlay.push(newEntry);
      }
    });

    // We still need to store all videos which qualify for playing
    // because if two videos are in the viewport at a ratio of 1 ...
    // then the top videos goes out of viewport triggering it's pause
    // but the lower video is still at a ratio of 1, IO will not log it
    // as a new entry as it's ratio has not actually changes but our priority has
    // So reselelect the most visible but track all that qualify
    // * Once iOS allows multiple videos to be play at the same time
    // * we can nuke this if (iOS) block
    if (iOS) {
      var mostVisible = [selectMostVisible(videoEntriesToPlay)].filter(function(e) { return e; });
      videosToPlay = ioEntriesToNodes(mostVisible);
    } else {
      videosToPlay = ioEntriesToNodes(videoEntriesToPlay);
    }

    videosToPause = allVideos.filter(function(avideo) { return videosToPlay.indexOf(avideo) < 0; });

    playOrPause(videosToPause, videosToPlay);
  }

  // Select the "most visible" from an array of IO entries
  function selectMostVisible(entries) {
    // Sort in ascending order by intersectionRatio
    var sortedEntries = entries.sort(function(a, b) {
      return b.intersectionRatio - a.intersectionRatio;
    });

    // See if there is more than one entry with intersectionRatio equal to the first in sortedEntries
    var equalRatioEntries = sortedEntries.filter(function(entry) {
      return entry.intersectionRatio === sortedEntries[0].intersectionRatio;
    });

    // If multiple entries have equal intersectionRatios
    // refine to only those with equal intersectionRatios
    // sort by boundingClientRect.top
    if (equalRatioEntries.length > 1) {
      sortedEntries = equalRatioEntries.sort(function(a, b) {
        return a.boundingClientRect.top - b.boundingClientRect.top;
      });
    }

    return sortedEntries.length ? sortedEntries[0] : null;
  }

  function playOrPause(videosToPause, videosToPlay) {
    videosToPause.forEach(function(toPause) {
      if (!toPause.paused) {
        toPause.pause();
      }
    });

    videosToPlay.forEach(function(toPlay) {
      if (toPlay.paused) {
        var playPromise = toPlay.play();
        playPromise.then(function() {
          // all is well
        }).catch(function() {
          // everything is terrible, replace with GIF
          swapAndCleanup(toPlay);
        });
      }
    });
  }

  // Utility: map IO entries to nodes
  function ioEntriesToNodes(entries) {
    return entries.map(function(entry) {
      return entry.target;
    });
  }

  function setVideoURL(video, data) {
    var wasPlaying = !video.paused;
    var videoURL = getVideoURL(video, data);
    var currentVideoURL = video.getAttribute('src');

    if (currentVideoURL !== videoURL) {
      // the old src will continue to download
      // unless we remove src and load first
      video.removeAttribute('src');
      video.load();
      // set new src and initiate load
      video.setAttribute('src', videoURL);
      video.load();
    }

    if (wasPlaying) {
      video.play();
    }
  }

  // Assumes video element has fallback wrapped in script tag
  // replace the video with the markup inside the script tag
  function swapWithFallback(video) {
    var videoParent = video.parentNode;
    var scriptEl = video.querySelector('script');
    var fallbackMarkup = scriptEl.innerHTML;
    video.outerHTML = fallbackMarkup;
    return { picture: videoParent.querySelector('picture'), img: videoParent.querySelector('img') };
  }

  // Get the best src url for the element size * dpr
  function getVideoURL(video, data) {
    var sizes = {};

    // Check art directed variants first
    // NOTE: This assumes art directed elements are ordered largest-to-smallest
    if (data.art_directed.length) {
      for (var i = 0; i < data.art_directed.length; i++) {
        var variant = data.art_directed[i];
        if (window.matchMedia(variant.media).matches) {
          sizes = variant.urls;
          break;
        }
      }
    }

    // Fall back to default list
    if (!Object.keys(sizes).length) {
      sizes = data.default;
    }

    var elWidth = video.offsetWidth * (window.devicePixelRatio||1);
    var size = getClosestSize(elWidth, Object.keys(sizes));

    return sizes[parseInt(size)];
  }

  // Utility: width is Int, sizes is array of Int ...
  // return the closed Int value to width from sizes
  function getClosestSize(width, sizes) {
    var curr = sizes[0];
    var diff = Math.abs(width - curr);
    for (var val = 0; val < sizes.length; val++) {
      var newdiff = Math.abs(width - sizes[val]);
      if (newdiff < diff) {
        diff = newdiff;
        curr = sizes[val];
      }
    }
    return curr;
  }

}, {
  priority: Initializer.HIGH
});
