import Backbone from 'exoskeleton';

var ImageGalleryRouter = Backbone.Router.extend(
/** @lends ImageGalleryRouter.prototype */
  {
  /**
  * Gallery router manages the URL integration of the component.
  * This is an isolated behavior within the image gallery component:
  * nothing else relies on the router, by design.
  * The router simply pulls updates from the URL into the model,
  * and pushes updates from the model into the URL.
  * @constructs
  */

    initialize: function(opts) {
      this.app = opts.app;
      this.listenTo(this.app.images, 'selection', this.push);

      // Auto-push initial state after a brief setup window...
      // It helps to not be changing the URL in addition to everything else
      // happening during page initialization.
      setTimeout(() => { this.push(); }, 1000);
      // Make sure that the window is the top window to prevent iframe
      // funkiness when viewing a preview in Anthem
      // Make sure history isn't reinitialized if there are two
      // galleries on the page
      if (window.top === window && !Backbone.History.started) {
        Backbone.history.start();
      }
    },

    routes: {
      ':slug': 'pull'
    },

    /**
  * Pulls an update from the URL into the model.
  * @param {String} slug getting pulled into the model.
  * @returns {undefined}
  */
    pull: function(slug) {
      this.app.images.selectBySlug(slug);
    },

    /**
  * Pushes an update from the model into the URL.
  * @returns {undefined}
  */
    push: function() {
      var model = this.app.images.selectedImage();
      this.navigate(model.get('slug'));
    }
  });

export default ImageGalleryRouter;
