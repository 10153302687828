import Backbone from 'exoskeleton';
import $ from 'jquery';

const LOGO_TARGETS = ['.c-read-more__intro'];

let DisclaimerHeaderView = Backbone.View.extend({
  initialize(opts) {
    let data = opts.data;

    if ( ! $( LOGO_TARGETS ).length ) {
      return;
    }

    if ( ! data.url ) {
      return;
    }

    $( LOGO_TARGETS ).each( function() {
      $(this).html('Read more from').append(`<img src="${data.url}" alt="${data.alt}" />`);
    });
  }
});

export default DisclaimerHeaderView;
