import Backbone from 'exoskeleton';
import $ from 'jquery';

let NewsletterSignupFormView = Backbone.View.extend({

  events: {
    'submit': 'handleSubmit'
  },

  handleSubmit: function(evt) {
    if (this.simpleValidate(evt)) {
      window.localStorage.setItem('hide_toaster', Date.now());
      if ($(evt.target).attr('data-submit-ajax-redirect').length > 0) {
        evt.preventDefault();
        var self = this;
        var dataRandomId = $(evt.target).attr('data-random-id');
        window.grecaptcha.ready(
          function() {
            window.grecaptcha.execute(window.recaptcha_v3_site_key, { action: 'newsletter' })
              .then(function(token) {
                const hiddenInput = document.getElementById(`g-recaptcha-response-data-newsletter-${dataRandomId}`);
                hiddenInput.value = token;
              })
              .then(function() {
                self.ajaxSubmit(evt);
              });
          });
      }
    }
  },

  simpleValidate: function(evt) {
    if (!this.$('input[type="email"]').val()) {
      alert('Please provide an email address to subscribe');
      evt.preventDefault();
      return false;
    }
    return true;
  },

  ajaxSubmit: function(event) {
    var target = event.target;
    var data = $(target).serialize();
    const formBody = $(target).children('div[class*="form__body"]');
    const formOnSuccess = function(target) {
      const successMessage = $(target).children('div[class*="form__success"]');

      formBody.hide();
      successMessage.show();

      return false;
    };

    $.ajax({
      url: target.action,
      method: target.method,
      data: data
    })
      .done(function() {
        formOnSuccess(target);
        return false;
      }).fail(function(errorObj, errorText) {
        const errorMessage = formBody.children('div[class*="form__error"]');

        errorMessage.show();
        console.error({
          errorText,
          errorObj
        });
      });
  }
});

export default NewsletterSignupFormView;
