import Backbone from 'exoskeleton';
import $ from 'jquery';
import PianoId from 'lib/piano-id';

var PianoLoginView = Backbone.View.extend({
  manageAccountUrl: null,
  initialize: function (opts) {
    this.manageAccountUrl = opts.data.manageAccountUrl;
    PianoId.pullAccount().then((data)=>{
      if (data.token) {
        this.pianoLogIn(data);
      } else {
        this.pianoLogOut();
      }
    });
    PianoId.on('change', (evt) => {
      if (evt.data.token) {
        this.pianoLogIn(evt.data);
      } else {
        this.pianoLogOut();
      }
    });
  },

  events: {
    'click [data-ui="piano_subscribe"]': 'onPianoAction',
    'click [data-ui="piano_login"]': 'onPianoAction',
    'click [data-ui="piano_logout"]': 'onPianoAction',
    'click [data-ui="piano_manage_account"]': 'onPianoAction'
  },

  pianoLogIn: function(data) {
    var user = data.user || {};
    this.$el.addClass('logged-in');
    this.$('.c-piano__login-controls_logged-status').html(`
      ${user.firstName || 'Subscriber'} ${user.lastName || ''} | <a href="#" data-ui="piano_logout">Log Out</a> |  <a href="${this.manageAccountUrl}">Manage Account</a>
    `);
  },

  pianoLogOut: function() {
    this.$el.removeClass('logged-in');
    this.$('.c-piano__login-controls_logged-status').empty();
  },

  onPianoAction: function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    var action = $(evt.currentTarget).attr('data-ui');
    var tp = window.tp || [];
    if (tp.pianoId) {
      switch (action) {
      case 'piano_subscribe':
        PianoId.showRegister();
        break;
      case 'piano_login':
        PianoId.showLogin();
        break;
      case 'piano_logout':
        PianoId.logout();
        break;
      }
    }
  }

});

export default PianoLoginView;


