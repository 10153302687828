import './index.scss';
import 'promise-polyfill';
import $ from 'jquery';
import _ from 'lib/utils';
import UserAgent from 'lib/user_agent';
import Initializer from 'lib/initializer';

const supportsPicture = UserAgent.supportsPictureElement();
const supportsObjectFit = UserAgent.supportsObjectFit();
const supportsIO = UserAgent.supportsIntersectionObserver();

var allPictures = [];

// Singletons
var listenerAttached;
var pictureIO;
var polyfillFunc;

Initializer.registerComponent('site/picture_element', function(element, data) {

  // Set polyfillFunc singleton
  if (!supportsPicture && !supportsObjectFit) {
    polyfillFunc = polyfillOjectFitAndPicture;
  } else if (!supportsObjectFit) {
    polyfillFunc = polyfillOjectFit;
  }


  // Polyfill normal pictures immediately,
  // but lazy variants can't be polyfilled
  // until they are unwrapped/rendered.
  if (polyfillFunc && !data.lazy) {
    allPictures.push(element);
    polyfillFunc(element);
  }


  // Setup resize listener singleton
  // to reapply any polyfills
  if (!listenerAttached && polyfillFunc) {
    listenerAttached = true;
    var polyfillAllPictures = () => allPictures.forEach(polyfillFunc);
    $(window).on('resize orientationchange', _.debounce(polyfillAllPictures, 200));

    // The IE layout engine finishes sizing boxes after this polyfill runs,
    // so we're going to forcibly correct that by updating every few seconds.
    // Sorry IE, not sorry.
    if (UserAgent.browser().msie) {
      decayRecurse(3000, polyfillAllPictures);
    }
  }


  // Polyfill IntersectionObserver if unsupported
  // and at least one Picture is of the lazy type
  // then proceed to observe Pictures.
  if (data.lazy) {
    if (!supportsIO) {
      import('intersection-observer').then(observePictures);
    } else {
      observePictures();
    }
  }


  // Setup a single IntersectionObserver for all picture components
  function observePictures() {
    if (!pictureIO) {
      pictureIO = new IntersectionObserver(lazyLoadAllVisiblePictures, {
        threshold: [0.25, 0.5, 0.75, 1],
        rootMargin: '0px'
      });
      pictureIO.POLL_INTERVAL = 200;
    }

    pictureIO.observe(element);
  }


  // If a Picture intersects the desired area, load it.
  function lazyLoadAllVisiblePictures(entries) {
    entries.forEach(function(entry) {
      // this if statement is needed to check whether the element actually became visible
      // since Chrome 58.x+ calls this callback function immediately when observer.observe(target)
      // is called even if the element isn't visible yet
      if (entry.intersectionRatio >= 0.25) {
        pictureIO.unobserve(entry.target);
        var loadedPicture = lazyLoadPicture(entry.target);
        if (polyfillFunc) {
          allPictures.push(loadedPicture);
          polyfillFunc(loadedPicture);
        }
      }
    });
  }


  // Render picture via hidden markup
  function lazyLoadPicture(target) {
    var $markupWrapper = $(target).find("script[type='text/template-picture']");
    var html = $markupWrapper.html();
    var $parent = $markupWrapper.parent();
    $markupWrapper.replaceWith(html);
    return $parent[0];
  }


  function polyfillOjectFit(pictureElement) {
    import('objectFitPolyfill').then((objectFit) => {
      objectFit(pictureElement.getElementsByTagName('img')[0]);
    });
  }


  function polyfillOjectFitAndPicture(pictureElement) {
    Promise.all([
      import('picturefill'),
      import('objectFitPolyfill')
    ]).then(([pictureFill, objectFit]) => {
      pictureFill({ elements: [pictureElement] });
      objectFit(pictureElement.getElementsByTagName('img')[0]);
    });
  }


  // Utility function that will recursively execute a function
  // immediately once, then subsequent executions will call
  // with a delay, where the delay starts at N and doubles itself
  // based on the previous delay value. 1, 2, 4, 8, etc
  function decayRecurse(delay, toRecurse) {
    var recurse = function() {
      setTimeout(function() {
        toRecurse();
        delay += (delay||1000);
        recurse(delay);
      }, delay);
    };
    toRecurse();
    recurse(delay);
  }

}, {
  priority: Initializer.HIGH
});
