import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';

Initializer.registerComponent('site/lifetime_view', function(element, data) {
  function tooltipToggle() {
    var icon = $('.c-lifetime-view__info-icon');
    var tooltip = $('.c-lifetime-view__tooltip--wrap');

    icon.click(function() {
      tooltip.toggle();
    });
  }
  tooltipToggle();
});
