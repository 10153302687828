import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';
import { TRANSITION_END } from 'lib/effects';
import viewport from 'lib/viewport';
import Modal from 'lib/modal';
import GridView from './grid';

var GalleryView = Backbone.View.extend(
/** @lends module:apps/image_gallery.GalleryView.prototype */
  {
  /**
  * Gallery view manages the display of the main gallery interface,
  * including the main image viewer region and the list of thumbnails.
  * <ul>
  *  <li>Requests all dynamically-sized images.</li>
  *  <li>Configures lazy viewport tracking for loading thumbnails.</li>
  *  <li>Configures events for re-rendering upon selection change.</li>
  * </ul>
  * @constructs
  */
    initialize: function(opts) {
      this.app = opts.app;
      this.images = opts.app.images;
      this.requestSizedImages();

      // Cache common elements, and disable script-free accessibility features:
      this.$scroller = this.$('[data-ui="scroller"]').removeClass('noscript-accessible');
      this.$thumbs = this.$('[data-ui="thumb"]');
      this.$thumb = this.$thumbs.eq(0).closest('li');
      this.$reel = this.$thumb.closest('ul');

      // Track the gallery element within the viewport:
      viewport.trackElement(this.el, () => {
        this.loadThumbs();
        this.render();
      }, -0.5);

      this.listenTo(this.images, 'selection', this.render);
    },

    /**
  * Measures the main viewer display,
  * and then requests dynamic-size assets for all models.
  */
    requestSizedImages: function() {
      var $viewer = this.$('[data-ui="viewer"]');
      var width = $viewer.width();
      var height = $viewer.height();

      this.images.models.forEach((model) => {
        model.requestSize(width, height);
      });
    },

    /**
  * Loads all thumbnail images.
  * This is invoked by an enter-viewport callback,
  * so thumbnails will be lazily-loaded upon coming into view.
  */
    loadThumbs: function() {
      this.$thumbs.each((index, el) => {
        el.style.backgroundImage = `url(${ this.images.at(index).get('thumb_url') })`;
      });
    },

    /**
  * Toggles the wait-spinner animation:
  */
    wait: function(enable) {
      if (enable && !this.$loader) {
        this.$loader = $('<div class="p-loader"></div>');
        this.$('[data-ui="viewer"]').append(this.$loader);
      } else if (!enable && this.$loader) {
        this.$loader.remove();
        this.$loader = null;
      }
    },

    /**
  * Renders the view.
  * This updates the main viewer area, meta information, and the thumbnails list state.
  * Method is debounced to defer repeated invocations
  * (which are problematic during application startup and router initialization).
  */
    render: _.debounce(function() {
      var image = this.images.selectedImage();
      if (!image) return;

      var $main = this.$('[data-ui="viewer"] img');
      var completed = 0;
      var self = this;

      function next() {
        completed++;
        if (completed < 2) return;

        // Swap in the new image for the old one:
        var $image = $(img);
        $main.replaceWith($image);

        // Reveal the image on the next event loop:
        setTimeout(function() {
          $image.addClass('present');
          self.wait(false);
        }, 0);
      }

      // Start loading new a image in parallel to the old image transitioning out:
      var img = new Image();
      img.onload = next;
      img.src = image.fullsizeURL();

      // Transition out old main graphic, if effects are supported:
      if (TRANSITION_END && $main.hasClass('present')) {
        $main.one(TRANSITION_END, next).removeClass('present');
      } else {
        next();
      }

      // Init loading.
      this.wait(true);
      this.renderMeta();
      this.renderThumbs();
      return this.$el;
    }, 25),

    /**
  * Render meta-information.
  * @private
  */
    renderMeta: function() {
    // Generate cached references to commonly-accessed elements:
      this.$i = this.$i || this.$('[data-ui="index"]');
      this.$c = this.$c || this.$('[data-ui="caption"]');

      // Populate "N of X" counter:
      var at = this.images.selectedIndex() + 1;
      var total = this.images.length;
      this.$i.html(`${ at } of ${ total }`);

      // Populate caption:
      var image = this.images.selectedImage();
      var html = '';

      if (image) {
        var title = image.get('title');
        var caption = image.get('caption');

        if (title && caption) html += `<span class="c-image-gallery__caption--title c-image-gallery__caption--title-pipe">${ title }</span> `;
        if (title && !caption) html += `<span class="c-image-gallery__caption--title">${ title }</span> `;
        if (caption) html += caption;
      }
      this.$c.html(html);
    },

    /**
  * Render thumbs region.
  * @private
  */
    renderThumbs: function() {
      var selectedIndex = this.images.selectedIndex();
      var thumbM = parseInt(this.$thumb.css('marginRight'));
      var thumbW = this.$thumb.width() + thumbM;
      var extent = this.$thumbs.length * thumbW - thumbM;
      var range = this.$scroller.width();
      var limit = extent - range;
      var scrollTo;

      // Select active thumbnail:
      this.$thumbs.each(function() {
        var $el = $(this);
        $el.toggleClass('active', $el.data('index') == selectedIndex);
      });

      if (extent < range) {
      // Scenario: thumbs do NOT fill up the scroll range...
      // Center the row of thumbnails.
        scrollTo = (range - extent) / 2;
      } else {
      // Scenario: thumbs fill the scroll range...
      // Attept to center the currently selected thumb within the viewport.
        scrollTo = (selectedIndex * thumbW + (thumbW / 2)) - (range / 2);
        scrollTo = -Math.round(Math.max(0, Math.min(scrollTo, limit)));
      }

      // Assign total extent of thumbnails list,
      // and then set left position to scroll to.
      this.$reel.width(extent).css('transform', 'translateX('+ scrollTo +'px)');
      this._x = scrollTo; // << "translateX" is tricky to DOM-parse, so just memo it.
      this.setThumbPage({ extent: extent, range: range });
    },

    /**
  * Advances the current "page" of thumbnails.
  * Page size is calculated as half the width of the scroll range.
  * This is a dynamic method, re-implemented each time thumb scroll is rendered.
  * @param {Number} [direction] indicated as +1 or -1 for forward or backward.
  * Calling this without a direction value will simply set navigation controls.
  * @returns {undefined}
  */
    setThumbPage: function(opts) {
      var extent = opts.extent || this.$reel.width();
      var range = opts.range || this.$scroller.width();
      var limit = extent - range;
      var scroll = Math.abs(this._x || 0);
      var $prev = this.$('[data-ui="prev-page"]');
      var $next = this.$('[data-ui="next-page"]');
      $prev.css('display', 'block');
      $next.css('display', 'block');

      if (extent < range) {
      // Scenario: nowhere to scroll, so disable both arrows.
        $prev.css('display', 'none');
        $next.css('display', 'none');
        return;
      } else if (opts.page) {
      // Scenario: we can scroll, and have a direction to scroll in.
        scroll = Math.max(0, Math.min(scroll + (range / 1.25 * opts.page), limit));
        var thumbW = this.$thumb.width();

        // Nudge the scroll page size out when nearing the range limits:
        // This prevents leaving small fractions of a thumbnail as a "page".
        // Direction is checked to make sure we only nudge in the direction of travel.
        if (opts.page < 0 && scroll < thumbW) scroll = 0;
        else if (opts.page > 0 && limit - scroll < thumbW) scroll = limit;

        this.$reel.css('transform', 'translateX(-'+ scroll +'px)');
        this._x = -scroll; // << "translateX" is tricky to DOM-parse, so just memo it.
      }
      // Toggle prev/next disabled state:
      $prev.prop('disabled', scroll === 0);
      $next.prop('disabled', scroll === limit);
    },

    events: {
      'click [data-ui="prev-image"]': 'prevImg',
      'click [data-ui="next-image"]': 'nextImg',
      'click [data-ui="prev-page"]': 'prevPage',
      'click [data-ui="next-page"]': 'nextPage',
      'click [data-ui="expand"]': 'toggleFullScreen',
      'click [data-ui="thumb"]': 'thumb',
      'click [data-ui="grid"]': 'grid'
    },

    prevImg: function(evt) {
      evt.preventDefault();
      this.images.previous();
    },

    nextImg: function(evt) {
      evt.preventDefault();
      this.images.next();
    },

    prevPage: function(evt) {
      evt.preventDefault();
      this.setThumbPage({ page: -1 });
    },

    nextPage: function(evt) {
      evt.preventDefault();
      this.setThumbPage({ page: 1 });
    },

    thumb: function(evt) {
      evt.preventDefault();
      var index = $(evt.currentTarget).data('index');
      this.images.selectByIndex(index);
    },

    grid: function(evt) {
      evt.preventDefault();
      var grid = new GridView({ collection: this.images });
      Modal.dialog(grid.render());
    },

    toggleFullScreen: function(evt) {
      evt.preventDefault();
      var goBig = !this.$el.hasClass('full-screen');
      this.$('.c-image-gallery__viewer-expand svg use').attr('xlink:href', (goBig) ? '#icon-full-screen-exit' : '#icon-full-screen' );
      this.$('.c-image-gallery__viewer-expand-text').html( (goBig) ? 'collapse' : 'expand');
      this.$el.toggleClass('full-screen expanded', goBig);
      $('body').toggleClass('gallery-full-screen', goBig);
    }
  });

export default GalleryView;
