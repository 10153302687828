import './index.scss';
import './assets/polygon_badge.svg';
import './assets/polygon_badge_hover.svg';
import './assets/polygon_badge_review.svg';

import Initializer from 'lib/initializer';
import currentContext from 'models/context';
import { runStartup, runInitQueue } from 'lib/launchers';
import addSubtags from 'lib/subtags';

window.Subtags = addSubtags;

Initializer.readyTask(runStartup, { priority: Initializer.HIGH });
Initializer.readyTask(runInitQueue, { priority: Initializer.LOW });

// Start fetching current context...
// makes this start happening in parallel with DOMReady.
currentContext.fetch();
