import Backbone from 'exoskeleton';
import AdBlockDetector from 'lib/ad_block_detector';
import Storage from 'lib/localstorage';
import parseTemplate from 'lib/template';
import viewHTML from '../tmpl/survey_form.html';

let SurveyFormView = Backbone.View.extend({
  template: parseTemplate(viewHTML),
  html: '',
  events: {
    'click input[type="submit"]': 'submit'
  },
  storageKey: 'ad-survey-taken',

  initialize: function(opts) {
    this.model = opts.model;

    // Show survey if:
    //  - user has not already taken survey
    //  - ad blocking software is detected
    if (Storage.get(this.storageKey) !== 'yes' && AdBlockDetector.isAdBlockEnabled()) {
      this.render();
    }
  },

  render: function() {
    this.$el.html(this.template({ responses: this.model.responses }));

    return this.$el;
  },

  submit: function(evt) {
    // Remember that user has take survey
    Storage.set(this.storageKey, 'yes');

    // Remove survey
    this.$el.remove();
  }

});

export default SurveyFormView;
