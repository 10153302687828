import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';

let GroupView = Backbone.View.extend({

  events: {
    'click [data-ui="next-entry"]': 'onNextClick',
    'click [data-ui="prev-entry"]': 'onPrevClick',
    'click [data-ui="pager-button"]': 'onPagerClick'
  },

  initialize: function() {
    this.$entries = this.$el.find('.c-entry-box-group__entries');
    this.entryNumber = this.$entries.children().length;
    this.i = 0;
    this.applyMobileStyles();
    // Adds a class so we know the js ran and can have a proper fallback if not.
    this.$el.addClass('js-active');
    $(window).on('resize', _.throttle(() => {
      this.applyMobileStyles();
    }, 150));
  },

  applyMobileStyles: function() {
    if ($(window).width() > 600) {
      this.$entries.removeAttr('style');
      this.$entries.find('li').removeAttr('style');
    } else {
      this.$entries.width(this.entryNumber * 100 + '%');
      this.$entries.find('li').width(100 / this.entryNumber + '%');
    }
  },

  positionEntry: function(index) {
    let position = (index * -100) + '%';
    this.$(`[data-ui="pager-button"][data-index="${index}"]`)
      .addClass('active')
      .siblings()
      .removeClass('active');
    this.$entries.css('left', position);
  },

  onNextClick: function() {
    if(this.i < this.entryNumber - 1) {
      this.i += 1;
      this.positionEntry(this.i);
    }
  },

  onPrevClick: function() {
    if(this.i > 0) {
      this.i -= 1;
      this.positionEntry(this.i);
    }
  },

  onPagerClick: function(evt) {
    this.i = this.$(evt.currentTarget).data('index');
    this.positionEntry(this.i);
  }
});


export default GroupView;

