/**
  * Vendor-specific transition end event.
  * @example
  * import { TRANSITION_END } from 'lib/effects';
  *
  * if (effects.TRANSITION_END) {
  *   $(myElement).one(effects.TRANSITION_END, done).addClass('fx');
  * } else {
  *   done();
  * }
  */
export const TRANSITION_END = domEventType({
  'transition': 'transitionend',
  'WebkitTransition': 'webkitTransitionEnd',
  'MozTransition': 'transitionend',
  'OTransition': 'otransitionend'
});

/**
  * Vendor-specific animation end event.
  * @example
  * import { ANIMATION_END } from 'lib/effects';
  *
  * if (effects.ANIMATION_END) {
  *   $(myElement).one(effects.ANIMATION_END, done).addClass('fx');
  * } else {
  *   done();
  * }
  */
export const ANIMATION_END = domEventType({
  'animation': 'animationend',
  'WebkitAnimation': 'webkitAnimationEnd',
  'msAnimation': 'MSAnimationEnd',
  'OAnimation': 'oAnimationEnd'
});

/**
* style event name helper
*/
function domEventType(events) {
  for (var prop in events) {
    // Check for document.body inside Capybara tests
    if (document.body && typeof document.body.style[prop] != 'undefined') return events[prop];
  }
}
