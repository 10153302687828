import './index.scss';
import Initializer from 'lib/initializer';
import currentContext from 'models/context';
import { currentUser } from 'models/context';
import lclStorage from 'lib/localstorage';
import TOSNotificationView from './scripts/view';

const CONFIRM_ACCEPT_VALUE = '1';
const DEFAULT_TEXT = 'By clicking, you agree to the Vox Media  <a href="https://www.voxmedia.com/legal/privacy-notice">Privacy Notice</a> and <a href="https://www.voxmedia.com/legal/terms-of-use">Terms of Use</a>.';

Initializer.readyTask(function() {
  currentContext.fetch().then(function() {

    var tosNotifications = currentContext.get('tos_notifications');
    tosNotifications.forEach((tos_notification = {}) => {

      var tosUpdateId = 'tos-accepted-' + tos_notification.id;
      function applyNotice() {
        // if the user not logged in, and it's only to be shown to logged-in users
        if (!currentUser.get('id') && tos_notification.applies_only_to_registered_users) {
          return false;
        }

        // user hasn't accepted this update.
        if (lclStorage.get(tosUpdateId) != CONFIRM_ACCEPT_VALUE) {
          return true;
        }

        return false;
      }
      if (applyNotice()) {
        tos_notification.tos_update_id = tosUpdateId;
        if (!tos_notification.text) { tos_notification.text = DEFAULT_TEXT; }

        new TOSNotificationView(tos_notification);
      }
    });
  });
}, { priority: Initializer.LOW });
