import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';

const FIXED_CLASS = 'tab-bar-fixed';
const BREAK_MED_SMALL_WIDTH = 600;

let TabBarIndexView = Backbone.View.extend({
  events: {
    'site_sponsorship_logo_color_rendered' : 'onSiteSponsorshipLogoRender',
    'site_sponsorship_logo_mobile_color_rendered': 'onSiteSponsorshipLogoRender'
  },

  initialize: function(opts) {
    var data = opts.data;
    var isPackageLandingPage = $('.c-package-cover').length > 0;
    var showTabBar = $('.c-subnav-default').length < 1;

    if (!showTabBar) {
      return;
    }

    if (data.minimal) {
      if (isPackageLandingPage) {
        $('.c-package-cover').addClass('has-minimal-nav');
      } else {
        // this is heinous but we can't let the nav obscure ads
        // check if we need to accommodate full-width preludes first
        if ($('.m-ad__prelude').length > 0) {
          $('.m-ad__prelude').addClass('has-minimal-nav');
        }
        $('.l-header').addClass('has-minimal-nav');
      }
    }

    var $startFixedPositionElement;
    if (data.minimal && isPackageLandingPage) {
      $startFixedPositionElement = $('.c-package-cover');
    } else if (data.minimal) {
      $startFixedPositionElement = $('.c-entry-hero');
    } else {
      $startFixedPositionElement = $('.main-social');
    }

    var isGroupPage = $('.l-hub-wrapper').length > 0;
    var isStream = $('.c-stream-list').length > 0;
    var $endFixedPositionElement;
    if (isGroupPage) {
      $endFixedPositionElement = $('.l-main-content');
    } else if (isStream) {
      $endFixedPositionElement = $('.c-stream-list');
    } else {
      $endFixedPositionElement = $('.c-entry-content');
    }
    var isFixed = false;
    var overrideDefaultBehavior = false;

    // A custom event that can be fired (mostly for ads purposes)
    // to override the scroll-based checking
    // if no details are provided in the event it's a noop
    // detail keys are:
    // visibility: boolean; true shows the bar, false hides it
    // reset: removes the override and reverts to scroll based behavior
    document.addEventListener('unisonTabBarOverride', (ev) => {
      if (ev.detail.hasOwnProperty('visibility')) {
        overrideDefaultBehavior = true;
        isFixed = ev.detail.visibility;
        this.toggleBarVisibility(isFixed);
      } else if (ev.detail.reset) {
        // will take effect when the user scrolls again
        overrideDefaultBehavior = false;
      }
    });


    var $win = $(window).on('scroll', _.throttle(() => {
      var scrollTop = $win.scrollTop();
      var startingVisibility = isFixed;
      // let's not touch the dom if we're not going to update anything anyways
      if (overrideDefaultBehavior) {
        return true;
      }

      // The start/end positions are continually re-calculated (instead of only once) because
      // new asynchronously loaded elements, such as ads, may change the offets.
      var startFixedPosition = $startFixedPositionElement.length ? $startFixedPositionElement.offset().top : 500;
      var endFixedPosition = $endFixedPositionElement.offset().top + $endFixedPositionElement.height() - $win.height();

      // Tab bar appears when the reader scrolls between the social buttons (near byline)
      // and bottom of the article body.
      var isAfterStartPosition = scrollTop >= startFixedPosition;
      var isBeforeEndPosition = scrollTop <= endFixedPosition;
      isFixed = isAfterStartPosition && isBeforeEndPosition;

      // Only touch the dom and fire an event if the state has changed
      if (startingVisibility !== isFixed) {
        this.toggleBarVisibility(isFixed);
        // Toggle behavior for minimal view, which has slightly different positioning/scroll behavior
        if (data.minimal && isBeforeEndPosition) {
          this.toggleMinimalBarVisibility(isFixed, isAfterStartPosition, isBeforeEndPosition);
        }
      }
    }, 400));
  },
  toggleBarVisibility: function(isFixed) {
    this.$el.toggleClass(FIXED_CLASS, isFixed);
    document.dispatchEvent(new Event('unisonTabBarVisibility', {
      bubbles: true,
      detail: { 'visible': isFixed }
    }));
  },
  toggleMinimalBarVisibility: function(isFixed, isAfterStartPosition, isBeforeEndPosition) {
    if (window.innerWidth < BREAK_MED_SMALL_WIDTH) {
      if (!isBeforeEndPosition) {
        // affix tab bar to bottom of screen if user is scrolling within the article
        this.$el.toggleClass('is-minimal', !isFixed);
        this.$el.toggleClass('is-minimal-fixed', isFixed);
      }
    }
    if (!isAfterStartPosition) {
      // position tab bar at top of the page if user has scrolled down, then back up to top
      this.$el.addClass('is-minimal', !isFixed);
    } else {
      // hide tab bar if user has scrolled below article body
      this.$el.removeClass('is-minimal', 'is-minimal-fixed');
    }
  },
  onSiteSponsorshipLogoRender: function() {
    this.$el.addClass('has-site-sponsorship-logo');
  }
});

export default TabBarIndexView;
