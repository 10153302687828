import './index.scss';

import $ from 'jquery';
import _ from 'lib/utils';
import Modal from 'lib/modal';
import Initializer from 'lib/initializer';
import EntryFormatter from '../entry_formatter';
import OnDemandPlayer from './script/on_demand';

Initializer.registerComponent('site/video_embed', function() {}, {
  modal: function(opts) {
    try {
      var odp = new OnDemandPlayer(_.extend(opts, {
        include_cta: true,
        volume_autoplay: true,
        volume_autoplay_with_sound: true
      }));
      Modal.overlay(odp.el, { className: 'video-embed-modal' });
      setTimeout(() => odp.embed(), 1000); // << allow time for modal to come into viewport.
    } catch (e) {
      // if the odp errors out, pass the play thru to the entry
      if (opts.url) {
        window.location = opts.url;
      }
    }
  },
  embed : function(element, opts) {
    // this has a tendacy to error out when there's not a volume video,
    // so try to maintain some semblance of order
    try {
      var odp = new OnDemandPlayer(_.extend(opts, { 'volume_autoplay' : false }));
      $(element).append(odp.$el);
      $(element).on('click', (evt)=>{
        evt.preventDefault();
        evt.stopPropagation();
        odp.play();
      });
      requestAnimationFrame(() => odp.embed());
    } catch (e) { }
  }
});

Initializer.registerComponent('site/video_embed/sanitize', function(element) {
  var $el = $(element);
  EntryFormatter.formatVideoEmbeds($el);

  // listen to additions below, ins case this gets altered after the fact, due to some slow embed js or something
  new MutationObserver((mutations) => {
    mutations.forEach(() => EntryFormatter.formatVideoEmbeds($el));
  }).observe(element, { childList: true, subtree: true });

});
