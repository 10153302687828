import LocalStorage from 'lib/localstorage';

let pageLoad = new Date();
let sessionPageviewsKey = 'allowlist_session_pvs_1';
let secondsOnPageKey = 'allowlist_pageview_lengths_1';
let allowlistPageviewsKey = 'allowlist_shown_pvs';
let conversionKey = 'allowlist_adblock_conversion';
let lostConversionKey = 'allowlist_lost_conversion';
let dfpVariablesKey = 'dfp_variables';
let dfpVariables = {};

let TrackAndRecord = {

  allowlistView: function() {
    LocalStorage.set(allowlistPageviewsKey, this.getAllowlistViews() + 1);
  },

  getAllowlistViews: function() {
    return Number(LocalStorage.get(allowlistPageviewsKey)) || 0;
  },

  sessionPageview: function() {
    sessionStorage.setItem(sessionPageviewsKey, this.getSessionPageviews() + 1);
  },

  getSessionPageviews: function() {
    return Number(sessionStorage.getItem(sessionPageviewsKey)) || 0;
  },

  conversion: function() {
    if (!this.isConverted()) {
      dfpVariables[conversionKey] = 'true';
      LocalStorage.set(dfpVariablesKey, dfpVariables);
      LocalStorage.set(conversionKey, true);
    }
  },

  lostConversion: function() {
    if (!this.isLostConversion()) {
      this.clearDfpVariables();
      LocalStorage.set(lostConversionKey, true);
    }
  },

  isConverted: function() {
    return LocalStorage.get(conversionKey) || false;
  },

  isLostConversion: function() {
    return LocalStorage.get(lostConversionKey) || false;
  },

  clearDfpVariables: function() {
    LocalStorage.set(dfpVariablesKey, {});
  },

  setupPageviewLengthTracking: function() {
    window.addEventListener('beforeunload', () => {
      this.setPageviewLength();
    });
  },

  setPageviewLength: function() {
    let secondsOnPage = Math.round((new Date() - pageLoad) / 1000);
    let pageviewLengths = this.getPageviewLengths();

    LocalStorage.set(secondsOnPageKey, [...pageviewLengths, secondsOnPage]);
  },

  getPageviewLengths: function() {
    return LocalStorage.get(secondsOnPageKey) || [];
  },

  clearPageviewArray: function() {
    LocalStorage.set(secondsOnPageKey, []);
  }

};

export default TrackAndRecord;
