import SocialButtonsView from './view';
import $ from 'jquery';

const CLICKOFF_NS = 'click.social-popover';

var PopoverView = SocialButtonsView.extend({

  close() {
    var $popover = this.$popover;
    this.toggleAriaAndFocus();
    if (!$popover) return;
    $(document).off(CLICKOFF_NS);
    $popover.hide();
  },

  events: {
    'click [data-analytics-social]': 'social',
    'click [data-ui="more"]': 'onMore'
  },

  onMore(evt) {
    evt.preventDefault();
    var $popover = this.$popover = this.$popover || this.$('[data-ui="popover"]');
    if ($popover.is(':visible')) return;

    requestAnimationFrame(() => {
      var $more = $(evt.currentTarget);
      var offset = $more.offset();
      var poffset = $more.parent().offset();
      var height = $popover.outerHeight();
      var width = $popover.outerWidth();

      const INSET = 20;

      var below = (height > offset.top - INSET - document.documentElement.scrollTop);
      var top = below ? 'calc(100% + 5px)' : $more.position().top - (height + 5);
      var left = Math.min(offset.left + width - INSET, window.innerWidth - INSET) - width - poffset.left;
      var moreLocalX = offset.left - poffset.left;

      $popover
        .toggleClass('below', below)
        .find('[data-ui="caret"]')
        .css({ left: Math.round(moreLocalX - left + 10) });
      $popover.css({ top: top, left: left }).show();

      this.toggleAriaAndFocus();

      requestAnimationFrame(() => {
        $(document)
          .off(CLICKOFF_NS)
          .on(CLICKOFF_NS, (evt) => {
            if (!$(evt.target).closest($popover).length > 0) this.close();
          });
      });
    });
  },

  social(evt) {
    this.onService(evt);
  },

  toggleAriaAndFocus() {
    var $collapsedMenu = $('[data-ui="more"] a');
    // toggle acessibility attribute & focus
    if ($collapsedMenu.attr('aria-expanded') === 'false') {
      $collapsedMenu.attr('aria-expanded', 'true');
      // focus on heading in popover
      $('.c-social-buttons__popover-header h3').focus();
    } else {
      $collapsedMenu.attr('aria-expanded', 'false');
    }
  }
});

export default PopoverView;
