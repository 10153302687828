import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import currentContext from 'models/context';
import { currentUser } from 'models/context';
import $ from 'jquery';
import _ from 'lib/utils';
import Analytics from 'lib/analytics';
import UserAgent from 'lib/user_agent';

var HeaderNav = Backbone.View.extend({
  // Initializes the model with events:
  initialize: function(opts) {
    this.listenTo(currentUser, 'change', this.render);

    if (opts.data && opts.data.nav_items_limit) {
      this.nav_items_limit= opts.data.nav_items_limit;
    }
    this.render();
    // Wait for fonts to finish loading before calculating nav item widths
    Initializer.get('site/font_loader').load().then(() => {
      this.setNavItemsDisplay();
    });
    this.identifySubmenus();
    $(window).on('resize', _.debounce(this.setNavItemsDisplay.bind(this), 50));
    if(UserAgent.browser().safari === true) {
      this.$body.addClass('safari');
    }
  },

  $body : $('body'), // deal with it.
  nav_items_limit:100,

  // Renders the nav with the current user state:
  render: function() {
    this.setNavItemsDisplay();
  },

  events: {
    'click .c-global-header__link' : 'onLinkClick',
    'click .c-global-header__link-more' : 'onLinkClick',
    'click .c-global-header__link-communities' : 'onLinkClick',
  },

  // TOH: since user profiles are hidden, the cursor should change to default
  showMenuIcon: function(el, data) {
    const { currentNetwork: { attributes: { slug } } } = currentContext;
    const menuIcon = (
      (slug === 'thisoldhouse') ? el.css('cursor', 'default') : el.attr('href', currentUser.get('profile_url'))
    ).html(`<span class="sr-only">View profile</span><img src="${ data.profile_image_url }" alt="">`);
    return menuIcon;
  },

  setNavItemsDisplay : function() {
    var $moreLink = this.$('.c-global-header__link-more');
    var viewableNavItemsWidth = 0;
    var viewableWidth = 0;
    var navItemsWidth = 0;
    var dropdownInMore;
    var navItems = [];

    var useMore = false;

    viewableWidth += $('.c-global-header__links').width();

    this.$('.c-global-header__link.is-pinned').each(function () {
      navItems.push(this);
    });

    this.$('.c-global-header__link').not('.is-pinned').each(function () {
      navItems.push(this);
    });

    var maxItems = this.nav_items_limit;

    navItems.forEach(function(item, idx) {
      var $item = $(item);
      navItemsWidth += $item.width();
      var itemId = $item.attr('data-nav-item-id');
      var navListElement = $('.c-nav-list').find('[data-nav-item-id=' + itemId + ']');

      if ((navItemsWidth > viewableWidth) || (idx >= maxItems)) {
        // Hide element in global nav
        $item.addClass('hidden');
        // Show element in more list
        navListElement.removeClass('hidden');
        useMore = true;
      } else {
        // Show element in global nav
        $item.removeClass('hidden');
        viewableNavItemsWidth += $item.width();
        // Hide element in more list
        navListElement.addClass('hidden');
      }
    });

    // check to see if there's a dropdown in the more menu
    // because we need to display it differently
    dropdownInMore = $('.c-global-header__link.hidden[data-nav-list-trigger]');
    if(dropdownInMore.length > 0) {
      $('.c-nav-list').addClass('use-fixed-dropdown');
      $moreLink.addClass('use-fixed-dropdown');
    } else {
      $('.c-nav-list').removeClass('use-fixed-dropdown');
      $moreLink.removeClass('use-fixed-dropdown');
    }

    $moreLink.toggleClass('hidden', !useMore);
    if (useMore && viewableWidth < viewableNavItemsWidth + $moreLink.width()) {
      // grab the last visible item and hide it
      var $lastVisibleNavItem = this.$('.c-global-header__link').not('.hidden').last();
      $lastVisibleNavItem.addClass('hidden');
      var itemId = $lastVisibleNavItem.attr('data-nav-item-id');
      $('.c-nav-list').find('[data-nav-item-id=' + itemId + ']').removeClass('hidden');
    }
  },

  onLinkClick: function(evt) {
    Analytics.nav('click', evt.currentTarget.innerText.trim());
  },

  identifySubmenus : function() {
    var tabIndex = null;
    var anchors = this.$el.find('a, button');
    anchors.each((ixd, item) => {
      var anchor = $(item);
      var parent = anchor.parent();
      var triggerId = parent.attr('data-nav-list-trigger');
      anchor.attr('tabIndex', tabIndex);

      if (!triggerId) {
        parent.addClass('has-no-sublist');
      }
    });
  }
});

export default HeaderNav;
