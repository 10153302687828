import './index.scss';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import templateEngine from 'lib/template';

Initializer.registerComponent('snippets/jobs_list', function(element, data) {

  // Imports
  var templateHTML = require('./tmpl/jobs.html');

  // Schema data
  var filterDepts = data.filter || [];
  var promoteDepts = data.promote || [];

  const LOCATIONS = [
    { test: /New York|\bNYC?\b/, label: 'NY' },
    { test: /Washington|\bDC\b/, label: 'DC' },
    { test: /San Francisco|\bSF\b/, label: 'SF' },
    { test: /Los Angeles|\bLA\b/, label: 'LA' },
    { test: /Austin/, label: 'Austin' },
    { test: /Chicago/, label: 'Chicago' },
    { test: /Remote/i, label: 'Remote' }
  ];

  /**
  * Formats a normalized locations label.
  * Accepts raw location text, and then picks out location keywords.
  * All keywords are mapped into normalized abbreviations.
  * @param {String} name of listing location. Ex: "New York or Remote"
  * @returns {String} normalized listing of abbreviated locations. Ex: "NY, Remote"
  */
  function formatLocation(name) {
    var labels = [];
    for (var i = 0; i < LOCATIONS.length; i++) {
      if (LOCATIONS[i].test.test(name)) {
        labels.push(LOCATIONS[i].label);
      }
    }
    return labels.length ? labels.join(', ') : name.replace(/\sOffice/ig, '');
  }

  /**
  * Formats a job posting URL.
  * Query params in the current page URL are tacked onto the job link.
  * @param {String} url to format.
  * @returns {String} formatted url.
  */
  function formatURL(url) {
    var search = document.location.search;
    if (search.length) {
      url = url + (url.indexOf('?') > -1 ? '&' : '?') + search.slice(1);
    }
    return url;
  }

  /**
  * Renders the list of available jobs.
  * Jobs may be filtered to only display specific departments,
  * or departments may be promote to display at the top of the list.
  * @param {Object} Greenhouse API JSON response. See URL below for raw return.
  */
  function render(data) {
    var depts = [];

    function findParentDept(dept, departments) {
      if (dept.parent_id == null) { return dept; }

      var parentDept = data.departments.find((d) => dept.parent_id == d.id);

      // find parent recursively if necessary
      return (parentDept && parentDept.parent_id == null) ? parentDept : findParentDept(parentDept, departments);
    }

    // Build a listing for every department:
    data.departments.forEach(function(dept) {
      // Show the parent department name unless it's parent is "Editorial"
      var parentDept = findParentDept(dept, data.departments);
      var deptName = (parentDept ? parentDept.name : dept.name).trim();
      if (deptName == 'Editorial') {
        deptName = dept.name.trim();
      }

      // Check if department matches filter constraints.
      var selected = (!filterDepts.length || filterDepts.indexOf(deptName) > -1);

      // Only keep data for filtered departments that have jobs.
      if (selected && dept.jobs.length) {
        var jobs = dept.jobs.map(function(job) {
          return {
            title: job.title,
            location: formatLocation(job.location.name),
            url: formatURL(job.absolute_url)
          };
        });

        // Check if department already exists
        var matchIdx = depts.map((d) => d.name ).indexOf(deptName);

        if (matchIdx >= 0) {
          // Append to existing department jobs
          depts[matchIdx].jobs = depts[matchIdx].jobs.concat(jobs);
        } else {
          // Add new department and its jobs
          depts.push({
            id: deptName.replace('.com', 'dot com').split(/\W+/).join('-').toLowerCase(),
            name: deptName,
            promote: (promoteDepts.indexOf(deptName) > -1),
            jobs: jobs
          });
        }
      }
    });

    // Sort departments alphabetically with promoted departments first:
    depts.sort(function(a, b) {
      if (a.promote && !b.promote) return -1;
      else if (!a.promote && b.promote) return 1;
      return a.name.localeCompare(b.name);
    });

    // Populate the HTML content for the sorted listing groups:
    $(element).find('#jobs-list').html(templateEngine(templateHTML)({ departments: depts }));

    requestAnimationFrame(() => {
      var $target = $(location.hash);
      if ($target.length) {
        $('html,body').animate({ scrollTop: $target.offset().top }, 1000);
      }
    });
  }

  // Fetch, then render:
  $.getJSON('https://api.greenhouse.io/v1/boards/voxmedia/embed/departments?callback=?', render);
});
