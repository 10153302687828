import '../../site/dynamic_size_image';
import './index.scss';

import Initializer from 'lib/initializer';
import UserAgent from 'lib/user_agent';
import $ from 'jquery';

Initializer.registerComponent('sidebar_rocks/link_list/video_entry', function(element, data) {
  if (UserAgent.isMobile()) {
    var imageWrapper = $(element).find('.p-badge__overlay');
    imageWrapper.removeAttr('src');
    Initializer.get('site/video_embed').embed(imageWrapper[0], data);
  } else {
    $(element).on('click', '.p-badge__overlay', (evt) => {
      evt.preventDefault();
      Initializer.get('site/video_embed').modal(data);
    });
  }

// videos may have targeted advertising, so hold off.
}, { waitForConsent: true });
