/**
* @module apps/ad_survey
*/
import SurveyFormView from './views/survey_form';
import AdSurveyModel from './models/ad_survey';

/**
 * AdSurvey app bootstrapper.
 * @constructor
 */
function AdSurvey(el, data) {
  this.model = new AdSurveyModel(data);
  this.surveyFormView = new SurveyFormView({
    el: el,
    model: this.model
  });
}

export default AdSurvey;
