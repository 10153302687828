import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import $ from 'jquery';
import { currentUser } from 'models/context';
import { currentMember } from 'models/context';
import { currentCommunity } from 'models/context';
import EntryStats from 'models/entry_stats';
import SocialCounts from 'models/social_counts';
import Sharing from 'lib/sharing';
import Notice from 'lib/notification';
import UserAgent from 'lib/user_agent';

let SocialButtonsView = Backbone.View.extend({
  initialize: function(opts) {
    this.url = opts.base_url;

    if (opts.entry_id) {
      this.entry = EntryStats.add({ id: opts.entry_id });
      this.listenTo(this.entry, 'change', this.renderRecs);
      this.renderRecs();
    }

    // Delay for a few seconds before fetching social counts...
    setTimeout(() => {
      SocialCounts.fetch(opts.services).always(() => { this.render(); });
    }, 1500);
  },

  renderRecs: function() {
    var recd = this.entry.isRecommendedByUser();
    var totalRecs = this.entry.get('recommended_count');
    if (this.entry) {
      this.$('[data-analytics-social="rec"]')
        .toggleClass('is-recced', recd)
        .toggleClass('has-content', !!totalRecs && !recd)
        .find('[data-ui="count"]')
        .text(totalRecs ? ` (${ totalRecs })` : '');
    }
  },

  render: function() {
    this.$('[data-analytics-social]').each(function() {
      var $el = $(this);
      var service = $el.data('analytics-social');
      if (SocialCounts.hasCount(service) && SocialCounts.get(service) > 0) {
        $el.find('[data-ui="count"]').text(` (${ SocialCounts.get(service) })`);
      }

      // [AUD-1734] Since we’re opening social links in new windows, we should add something
      // to the link that signals that. (For example, `Share on Twitter <span class="sr-only"> (opens in new window)</span>)
      //
      // NOTE: this approach currently assumes there’s only one `sr-text` in the label,
      // and assumes that it’ll be at the end of the parent node. This fits with our current
      // usage in this component, but this might need to be rethought in the future
      var $accessibleText = $el.find('.sr-only');
      var labelNewWindow = ' (opens in new window)';
      if ($accessibleText.length > 0) {
        $accessibleText.append(labelNewWindow);
      } else {
        $el.append(`<span class="sr-only">${labelNewWindow}</span>`);
      }
    });
  },

  events: {
    'click [data-analytics-social]': 'onService'
  },

  onService: function(evt) {
    var service = this.$(evt.currentTarget).data('analytics-social');
    if (service === 'email') {
      if (UserAgent.browser().safari) {
        window.location.href = this.$(evt.currentTarget).attr('href');
        evt.preventDefault();
      }
      return;
    }

    if (service !== 'republish_off_platform') {
      evt.preventDefault();
    }

    var url = evt.currentTarget.href || this.url;
    if (service === 'rec') {
      this.onRec();
    } else {
      Sharing.share(url, service);
    }
  },

  onRec: function() {
    currentUser.fetch().then(() => {
      if (currentUser.isLoggedIn()) {
        if (currentMember.get('id')) {
          if (!this.entry) return;
          var rec = !this.entry.isRecommendedByUser();
          this.entry.recommend(rec)
            .done(() => { if (rec) Notice.success('Recommended.'); })
            .fail((mssg) => { Notice.error(mssg); });
        } else {
          Notice.error('You must be a member of ' + currentCommunity.get('name') + ' to recommend.');
        }
      } else {
        Initializer.get('apps/auth').open();
      }
    });
  }
});

export default SocialButtonsView;
