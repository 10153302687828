/**
* Detects whether user is using an ad blocker or not.
* @module lib/ad_block_detector
*/
export default {
  isAdBlockEnabled: function() {
    var fakeAd = document.createElement('div');
    fakeAd.innerHTML = '&nbsp;';
    fakeAd.className = 'adsbox';
    document.body.appendChild(fakeAd);

    // a height of zero indicates element has been blocked
    this._isAdBlockEnabled = fakeAd.offsetHeight === 0;
    fakeAd.parentNode.removeChild(fakeAd);

    return this._isAdBlockEnabled;
  }
};
