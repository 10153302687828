import './styles/index.scss';

import Initializer from 'lib/initializer';
import $ from 'jquery';
import _ from 'lib/utils';

Initializer.registerComponent('site/package_cover', function(element, data) {
  if ($(element).hasClass('c-package-cover--overlay-nav')) {
    $('.c-overlay-nav').addClass('package-content-layout-overlay');
  }


  var applySingleBlockLayoutRules = function($el) {
    var $singleItems = $el.find('.c-package-cover__horizontal .c-package-cover__entry');
    $singleItems.removeClass('c-package-cover__entry--space-left c-package-cover__entry--space-right');

    var shouldRulesBeApplied = function(set, isFirstOrLastRow) {
      var len = set.length;

      if (len == 2) {
        // if it's got two items, and only one of them is a singe, then we need to apply the rules
        if (set.filter((item)=> { return item.hasClass('c-package-cover__entry--single'); }).length === 1 ) {
          return true;
        }
      }
      if (len == 3) {
        // catches pullquotes and videos trapped in the middle
        // but not if it's the last line - more important to not push an orphan down
        if (!isFirstOrLastRow && (set[1].hasClass('c-package-cover__entry--pullquote') || set[1].hasClass('c-package-cover__entry--embedded-video') || set[1].hasClass('c-package-cover__entry--featured') )) {
          return true;
        }

        // if it's 3 singles alone, with no left or right sizing yet applied.
        // no need to check for last-line status here
        if (
          set.filter((item)=> { return item.hasClass('c-package-cover__entry--single'); }).length === 3 &&
          set.filter((item)=> { return (item.hasClass('c-package-cover__entry--space-left') || item.hasClass('c-package-cover__entry--space-right')); }).length == 0
        ) {
          return true;
        }
      }
      // if there are 4 in a row, we know they are all singles, so we should be applying the rules
      // but not if it's the last line, since we don't push any orphans down
      if (!isFirstOrLastRow && len == 4 ) {
        return true;
      }
      return false;
    };

    function buildPositionHash(items, currentItem) {
      var sortedByPosition = {};
      for (var i = 0; i < items.length; i+=1 ) {
        var $item = $(items[i]);
        // we sort all of these items by their top position, so that we can inspect the rows
        // since these rows shift, we need to rerun this every time.
        // This is rounded to ten to jsut keep odd positions from maeeing anythign up
        var positionKey = 'top_position_' + Math.ceil(($item.position().top + 1) / 10) * 10;

        if (sortedByPosition[positionKey]) {
          sortedByPosition[positionKey].push($item);
        } else {
          sortedByPosition[positionKey] = [$item];
        }

        if (!sortedByPosition.firstRow) {
          sortedByPosition.firstRow = sortedByPosition[positionKey];
        }

        // need to be able to determine which items is the current one we're dealing with
        if (items[i] === currentItem) {
          sortedByPosition.active = sortedByPosition[positionKey];
        }
        sortedByPosition.lastRow = sortedByPosition[positionKey];
      }
      return sortedByPosition;
    }

    for (var i = 0; i< $singleItems.length; i+=1 ) {
      var positionHash = buildPositionHash($singleItems, $singleItems[i]);
      var activeSet = positionHash.active;
      // Remove this class, which might have been added to a previous pass thru
      // we never want the first item to have right padding
      activeSet[0].removeClass('c-package-cover__entry--space-right');
      if ( shouldRulesBeApplied(activeSet, positionHash.lastRow === activeSet || positionHash.firstRow === activeSet ) ) {

        // if the first one's previous item has a pad- right,
        // then we need to apply a pad left to the first item
        var prev = activeSet[0].prev();
        if (prev && prev.hasClass('c-package-cover__entry--space-right')) {
          activeSet[0].addClass('c-package-cover__entry--space-left');
        } else {
          // 4 across means that they are all single, and the second to last one need the spacing
          if (activeSet.length === 4 ) {
            activeSet[activeSet.length - 2].addClass('c-package-cover__entry--space-right');
          } else {
            // otherwose, the lasst item needs to get updated
            activeSet[activeSet.length - 1].addClass('c-package-cover__entry--space-right');
          }
        }
      }
    }
  };

  var alignQuotes = function($el) {
    var $pullquoteItems = $el.find('.c-package-cover__entry--pullquote');
    var halfBoxWidth = $el.find('.c-package-cover__horizontal').width() * .5 || $el.width();
    $pullquoteItems.each( (idx) => {
      var $item = $($pullquoteItems[idx]);
      // position may have changed, so don't use the cached value
      setTimeout(()=>{
        $item.toggleClass('align-right', $item.position().left < halfBoxWidth );
      }, 1000 );
    });
  };

  var applyLayoutFilters = function () {
    applySingleBlockLayoutRules($(element));
    alignQuotes($(element));
  };

  $(window).on('resize orientationchange', _.debounce(applyLayoutFilters, 500));
  applyLayoutFilters();
}, {
  priority: Initializer.HIGH
});

