import $ from 'jquery';

/**
* Gallery keyboard controller.
* Responds to keyboard input,
* then passes relevant signals along to the model.
* @constructor
*/
function ImageGalleryKeyboard(opts) {
  this.app = opts.app;
  this.enable();
}

ImageGalleryKeyboard.prototype = {
  ns: 'keydown.gallery',

  /**
  * Enables keyboard bindings for the component.
  */
  enable: function() {
    this.disable().on(this.ns, (evt) => {
      switch(evt.keyCode) {
      case 37: return this.app.images.previous();
      case 39: return this.app.images.next();
      }
    });
  },

  /**
  * Disables keyboard bindings for the component.
  */
  disable: function() {
    return $(document).off(this.ns);
  }
};

export default ImageGalleryKeyboard;
