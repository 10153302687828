import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';
import color from 'lib/color';

/**
 * Insert a light or dark disclaimer image based on a background_selector
 * Example background_selector values:
 * '.c-global-header::before'
 * '.c-app-nav'
 * 'body'
 */
let DisclaimerImageView = Backbone.View.extend({

  initialize: function(opts) {
    this.data = opts.data || {};

    // Cache the DOM node responsible for retrieving background color
    this.backgroundEl = this.getBackgroundElement();

    this.displayImageType();

    $(window).on('resize', _.throttle(() => this.displayImageType(), 150));
  },

  /**
   * Sets the image src based on the brightness of the background_selector
   * @return {undefined}
   */
  displayImageType: function() {
    var imageType = color.hasLightBackground( this.backgroundEl, this.getPseudoSelector() ) ? 'dark' : 'light';
    var url = this.data[`${imageType}_url`];
    if ( !url || this.$el.attr('src') === url ) {
      return;
    }

    // Load image asychronously and trigger a resize to update the global_header nav
    var image = new Image();
    var self = this;
    image.onload = function() {
      self.$el.attr('src', url);
      if ( self.data.is_header ) {
        $(window).trigger('resize');
      }
    };
    image.src = url;
  },

  /**
   * Get the background element. If one is passed as opts.data.background_selector,
   * find and use that node. Otherwise, use this element's parentNode.
   * @return {node}   Element from which to pull background color
   */
  getBackgroundElement: function() {
    if ( this.parseBackgroundSelector() ) {
      return document.querySelector( this.parseBackgroundSelector()[0] );
    } else {
      return this.$el[0].parentNode;
    }
  },

  /**
   * Get the pseudo selector specified in opts.data.background_selector, if available
   * @return {string} Pseudo selector (sans ::)
   */
  getPseudoSelector: function() {
    var pseudoSelector;

    if ( this.parseBackgroundSelector() ) {
      pseudoSelector = this.parseBackgroundSelector()[1];
    }

    return pseudoSelector;
  },

  /**
   * Parses the background_selector passed to the view
   * @param  {string} [selector=''] Selector. Defaults to this.data.background_selector.
   * @return {array}                Selector split by any pseudo selectors.
   */
  parseBackgroundSelector: function() {
    var selector;

    if ( this.data.background_selector ) {
      selector = this.data.background_selector.replace('::', ':').split(':');
    }

    return selector;
  }

});


export default DisclaimerImageView;
