import currentContext from 'models/context';
import { currentNetwork } from 'models/context';
import $ from 'jquery';

var pending;

/*
* import foursquareWidgetLoader from 'lib/foursquare_widget';
* foursquareWidgetLoader().then(() => {
*    // init new widget
* }
*/

export default function() {
  if (!pending) {
    pending = $.Deferred();

    currentContext.fetch().then(() => {
      var networkFoursquareUserID = currentNetwork.get('foursquare_user_id');

      // i think that fourSq requires that this be done this way?
      window.___fourSq = {
        'uid': networkFoursquareUserID,
        'explicit': false,
        'secure': true,
        'onReady': function() {
          pending.resolve();
        }
      };

      // not sure if we've got a better way to do this.
      $.getScript('https://platform-s.foursquare.com/js/widgets.js');
    });
  }
  return pending.promise();
}
