import Backbone from 'exoskeleton';
import $ from 'jquery';

let DonationFormView = Backbone.View.extend({

  initialize: function() {
    this.setDefault();
  },

  events: {
    'click input[name="installmentPeriod"]': 'termsClickHandler',
    'change .c-donation-form__other-fields input[type="number"]': 'otherAmountTextChangeHandler',
    'click .c-donation-form__other-fields input[type="number"]': 'otherAmountTextClickHandler',
    'change input[name="amount"]': 'amountChangeHandler'
  },

  /**
  * When switching payment installment periods, reset the checked value
  * to the second option within that period
  * @param {jQuery Object} $termTab div of the installment period radios for active tab,
  * aka .c-donation-form__installment
  */
  setDefault: function($termTab) {
    if (!$termTab) {
      $termTab = this.$el.find('#donation-form_monthly');
      this.$el.find('[data-tab="#donation-form_monthly"]').prop('checked', true);
    }
    $termTab.find('label:nth-child(2) input').prop('checked', true);
    this.setRequiredFields(false);
  },

  /**
  * When changing installment periods, disable all inputs, then only un-disable
  * the active ones (fixes Firefox focus bug). reset the default, set active classes
  * @param {string} value id selector of the installment terms div, incl #
  */
  changeTerms: function(value) {
    this.$el.find('.c-donation-form__installment').hide()
      .removeClass('active')
      .find('input[type="radio"]').prop('disabled', 'disabled');
    this.$el.find('input[type="number"]').prop('value', '');
    let $termTab = this.$el.find(value);
    $termTab.find('input[type="radio"]').prop('disabled', null);
    this.setDefault($termTab);
    $termTab.addClass('active').show();
  },

  /**
  * Ensure all text inputs are not required, unless "other amount" is checked, then set required
  * @param {boolean} isChecked
  */
  setRequiredFields: function(isChecked) {
    this.$el.find('input[type="number"]').prop('required', false);
    if (isChecked) {
      this.$el.find('.c-donation-form__installment.active input[type="number"]').prop('required', true);
    }
  },

  /**
  * Click handler on installment terms tabs
  * @param {Event} event
  */
  termsClickHandler: function(event) {
    this.changeTerms($(event.target).data('tab'));
  },

  /**
  * Change handler on "other amount" text inputs;
  * assign typed value to corresponding radio value
  * @param {Event} event
  */
  otherAmountTextChangeHandler: function(event) {
    let radio = $(event.target).data('radio');
    $(radio).prop('value', event.target.value).prop('checked', true);
  },

  /**
  * Change handler on "amount" radio inputs
  * put focus on text input if other amount is checked, check required fields
  */
  amountChangeHandler: function() {
    let $otherAmountRadio = this.$el.find('.c-donation-form__installment.active .c-donation-form__other-fields input[type="radio"]');
    if ($otherAmountRadio.prop('checked')) {
      this.setRequiredFields(true);
      this.$el.find(`${ $otherAmountRadio.data('text')}`).focus();
    } else {
      this.setRequiredFields(false);
    }
  },

  /**
  * Click handler on other amount text input
  * only on click or change, check that radio input
  * @param {Event} event
  */
  otherAmountTextClickHandler: function(event) {
    const radioId = $(event.target).data('radio');
    $(radioId).prop('checked', true);
  }

});

export default DonationFormView;
