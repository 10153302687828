import Backbone from 'exoskeleton';
import _ from 'lib/utils';
import $ from 'jquery';
import { currentUser } from 'models/context';
import viewport from 'lib/viewport';
import Modal from 'lib/modal';

var BuyingGuideView = Backbone.View.extend({
  initialize: function() {
    currentUser.fetch().then(() => {
      if ( currentUser.get('can_admin') ) {
        $('.c-buying-guide__item_title').each(function(index) {
          $(this).append('<a data-entry-admin="' + index + '" href=""><svg class="is-active"><use xlink:href="#icon-settings"></use></svg></a>');
        });
      }
    });
    this.pullUrl();
    this.applyFilters();
    this.showModal();
  },

  /**
   * Shows a modal with a single item if an item param is present in the URL.
   */
  showModal: function() {
    var params = _.deparamitize(location.search);
    if ('item' in params && params.item != null) {
      Modal.dialog($('#buying_guide_item_' + params.item));
    }
  },

  /**
  * Pulls filter settings from the URL into current state.
  */
  pullUrl: function() {
    this.filters = {};
    var params = _.deparamitize(location.search);

    Object.keys(params).forEach((key) => {
      var values = params[key].split('+').map(decodeURIComponent);
      if (/^filter/.test(key) && values.length) {
        this.filters[key] = values;
      }
    });
  },

  /**
  * Pushes filter settings from current state into the URL.
  */
  pushUrl: function() {
    var query = Object.keys(this.filters)
      .map((key) => this.filters[key].length ? (key +'='+ this.filters[key].map(encodeURIComponent).join('+')) : null)
      .filter((param) => !!param);

    query = query.length ? '?'+query.join('&') : '';
    history.replaceState(this.filters, document.title, location.href.split('?')[0] + query);
  },

  /**
  * Applies current filters to visible grid items and selected toggles
  */
  applyFilters: _.debounce(function() {
    var filters = this.filters;
    var $items = this.$('.c-buying-guide__items');
    var filteredIds = $items.children().get().map(item => item.id);
    var filterCount = 0;

    Object.keys(filters).forEach((key) => {
      filterCount += filters[key].length;
      if (!filters[key].length) return;
      var selector = filters[key].map(value => `[data-${key}~="${value}"]`).join(',');
      var matchedIds = $items.find(selector).get().map(item => item.id);
      filteredIds = filteredIds.filter(id => matchedIds.indexOf(id) > -1);
    });

    // Toggle visible grid items
    if (filterCount) {
      $items.children().hide().filter(filteredIds.map(id => `#${id}`).join(',')).show();
    } else {
      $items.children().show();
    }

    // Highlight selected filter boxes
    this.$('[data-filter-id]').each(function() {
      var $el = $(this);
      var id = $el.data('filter-id');
      var value = $el.data('filter-value');
      var selected = filters[id] && filters[id].indexOf(value) > -1;
      $el.toggleClass('selected', !!selected);
    });

    requestAnimationFrame(() => viewport.callElementsInViewport());
    this.pushUrl();
  }, 50),

  events: {
    'click [data-filter-id]': 'onFilter',
    'click [data-entry-admin]': 'shareLink'
  },

  onFilter: function(evt) {
    var el = evt.currentTarget;
    var id = el.getAttribute('data-filter-id');
    var filters = this.filters[id] = this.filters[id] || [];
    var value = el.getAttribute('data-filter-value');
    var index = filters.indexOf(value);

    if (index > -1) {
      filters.splice(index, 1);
    } else {
      filters.push(value);
    }

    this.applyFilters();
  },

  shareLink: function(evt) {
    evt.preventDefault();
    Modal.dialog('<h4>Sharing URL</h4><input disabled="disabled" readonly type="text" size="75" value="' + window.location.href.split('?')[0] + '?item=' + evt.currentTarget.getAttribute('data-entry-admin') + '"/>');
  }
});

export default BuyingGuideView;
