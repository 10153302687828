import Backbone from 'exoskeleton';
import $ from 'jquery';

const CAROUSEL_INIT_MIN = 3;
let EmbedCarousel = Backbone.View.extend({
  initialize: function() {
    // we ditch if this has a preceding sibling that will invalidate the need to do further checking.
    if (this.$el.prev('.c-place-embed').length > 0) { return; }
    var siblings = this.adjacentSiblings();
    if (siblings.length < CAROUSEL_INIT_MIN) { return; }
    var parent = $('<div class="c-place-embed__carousel"><div class="c-place-embed__carousel-next"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-right"></use></svg></div><div class="c-place-embed__carousel-prev"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-left"></use></svg></div></div>');
    parent.on('click', '.c-place-embed__carousel-next', () => { this.moveCarousel(parent, 1); });
    parent.on('click', '.c-place-embed__carousel-prev', () => { this.moveCarousel(parent, -1); });
    this.$el.before(parent);
    parent.append(siblings);
    parent.data({ 'carousel-panes': siblings.length, 'carousel-current': 1 });
  },

  moveCarousel : function(parent, dir) {

    var panes = parent.data('carousel-panes');
    var current = parseInt(parent.data('carousel-current'));
    var target = current + dir;
    if (target < 1) { target = panes; }
    if (target > panes) { target = 1; }
    parent.data('carousel-current', target);
    parent.find('.c-place-embed').css({ 'transform': 'translateX(-' + ((target-1) * 100) + '%)' });

  },

  adjacentSiblings : function() {
    var siblings = $().add(this.$el);
    var testEl = this.$el.next('.c-place-embed');

    while(testEl.length > 0) {
      siblings = siblings.add(testEl);
      testEl = testEl.next('.c-place-embed');
    }
    return siblings;
  }

});

export default EmbedCarousel;
