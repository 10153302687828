import Backbone from 'exoskeleton';
import DynamicImages from 'models/dynamic_images';

var GalleryImage = Backbone.Model.extend(
/** @lends module:apps/image_gallery.GalleryImage.prototype */
  {
  /**
  * Gallery Image model manages data for individual gallery images items.
  * This model will handle fetching dynamic-sizes versions of itself,
  * and fulfilling requests for the "full-sized" image from whatever
  * large image source (full-size or dynamic-sized) that it has available.
  * @constructs
  */

    defaults: {
      slug: '',
      asset_id: null,
      image_id: null,
      image_url: '',
      thumb_url: ''
    },

    /**
  * Requests the image at a given size (width/height).
  * In the current implementation, an image may only have one
  * dynamic-size request associated with it at a time.
  * @param {Number} width of rectangle to request.
  * @param {Number} height of rectangle to request.
  * @returns {undefined}
  */
    requestSize: function(width, height) {
      this._dyn = DynamicImages.addRect(width, height, this.toJSON());
    },

    /**
  * Provides the fullsize URL for the image.
  * This is a dynamic image URL, if available,
  * or else the default fullsize image URL provided through component data.
  * @returns {String} the currently available fullsize URL.
  * This will either be the dynamic-sized image URL, or else the default fullsize image.
  */
    fullsizeURL: function() {
      return (this._dyn && this._dyn.get('url')) || this.get('image_url');
    }
  });

var GalleryImages = Backbone.Collection.extend(
/** @lends module:apps/image_gallery.GalleryImages.prototype */
  {
  /**
  * Gallery Images collection manages a set of gallery images,
  * as well as the selection state of the currently selected image.
  * All navigation actions (previous/next/goto) are performed through this model.
  * This view will update itself to reflect new selection state here within the model.
  * @constructs
  */

    model: GalleryImage,
    _selection: 0,

    /**
  * Specifies the currently selected image index.
  * @returns {Number} the current selection index.
  */
    selectedIndex: function() {
      if (this.length < 1) return -1;
      return Math.max(0, Math.min(this._selection, this.length - 1));
    },

    /**
  * Accesses the currently selected image model.
  * @returns {GalleryImage} the currently selected gallery image model.
  */
    selectedImage: function() {
      if (this.length < 1) return null;
      return this.at(this.selectedIndex());
    },

    /**
  * Accesses the currently selected slug name.
  * @returns {String} the currently selected gallery image slug.
  */
    selectedSlug: function() {
      var image = this.selectedImage();
      return image ? image.get('slug') : null;
    },

    /**
  * Selects the next model in the collection.
  * @returns {undefined}
  */
    next: function() {
      if (this.length < 1) return;
      var index = this._selection + 1;
      this.selectByIndex(index < this.length ? index : 0);
    },

    /**
  * Selects the previous model in the collection.
  * @returns {undefined}
  */
    previous: function() {
      if (this.length < 1) return;
      var index = this._selection - 1;
      this.selectByIndex(index >= 0 ? index : this.length - 1);
    },

    /**
  * Sets the current selection by index reference.
  * @param {Number} index of the gallery image to select.
  * @returns {Boolean} true if the selection was modified.
  */
    selectByIndex: function(index) {
      if (index >= 0 && index < this.length && index != this._selection) {
        this._selection = index;
        this.trigger('selection');
        return true;
      }
      return false;
    },

    /**
  * Sets the current selection by slug reference.
  * @param {String} slug of the gallery image to select.
  * @returns {Boolean} true if the selection was modified.
  */
    selectBySlug: function(slug) {
      for (var i = 0; i < this.length; i++) {
        if (this.at(i).get('slug') === slug) {
          this.selectByIndex(i);
          return true;
        }
      }
      return false;
    }
  });

export default GalleryImages;
