import Backbone from 'exoskeleton';
import currentContext from 'models/context';
import { currentNetwork } from 'models/context';
import $ from 'jquery';

let OpentableButton = Backbone.View.extend({
  initialize: function() {
    currentContext.fetch().then(() => {
      this.opentableRefID = currentNetwork.get('opentable_ref_id');
      this.$el.on('click', (evt)=> { this.handleClick(evt); });
    });

  },
  handleClick : function(e) {
    e.preventDefault();

    var params = {
      'rid': e.currentTarget.getAttribute('data-opentable-id'),
      'ref': this.opentableRefID
    };

    var url = 'http://www.opentable.com/single.aspx?' + $.param(params);
    window.open(url, '_blank');
  }
});
export default OpentableButton;
