/**
* Low-level API for opening and closing notification prompts.
* Please refrain from using this module directly within components.
* Instead, use the "lib/services" layer for a high-level service API.
* The higher-level API will streamline notifications among other services.
*
* @example
* import Notice from 'lib/notification';
*
* Notice.info('Hello World.');
* Notice.success('You win.');
* Notice.error('You lose.');
* Notice.close();
*
* @module lib/notification
*/
import $ from 'jquery';
import { TRANSITION_END } from 'lib/effects';
import Modal from 'lib/modal';

const NOTICE_ELEMENT = '<div class="p-notice" id="notice"></div>';
const NOTICE_DISMISS = '<button class="p-notice-dismiss" id="notice-dismiss">×</button>';

var timer;

let Notification = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  HAT: 'hat',

  $el: null,

  /**
  * Flashes a general-purpose notification at the top of the screen.
  * This implementation auto-detects message type based on HTML classes
  * within the notification content. If no meaningful classes are found,
  * the notification will assume the default "info" appearance.
  * @example
  * +-+---------+-+
  * |A|_________|A|
  * |BBBBBBBBBBBBB|
  * |CCCCCCCCCCCCC|
  * |             |
  * |             |
  * +-------------+
  *
  * Notice.notify('Hello world.');
  * @param {String|Element} content HTML or rendered markup to present within the notice.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  notify: function(message, autoHide, dismissable) {
    return this._open(message, null, autoHide, dismissable);
  },

  /**
  * Flashes a general-purpose notification at the top of the screen.
  * This implementation auto-detects message type based on HTML classes
  * within the notification content. If no meaningful classes are found,
  * the notification will assume the default "info" appearance.
  * @example
  * +-+---------+-+
  * |_____________|
  * |AAAAAAAAAAAAA|
  * |BBBBBBBBBBBBB|
  * |CCCCCCCCCCCCC|
  * |             |
  * +-------------+

  * @param {String} message text to notify.
  * @param {Boolean} autohide sets automatic dismissal timer.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  hat: function(message, autoHide, dismissable) {
    return this._open(message, this.HAT, autoHide, dismissable);
  },

  /**
  * Opens an info notification.
  * @param {String} message text to notify.
  * @param {Boolean} autohide sets automatic dismissal timer.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  info: function(message, autoHide, dismissable) {
    return this._open(message, this.INFO, autoHide, dismissable);
  },

  /**
  * Opens a success notification.
  * @param {String} message text to notify.
  * @param {Boolean} autohide sets automatic dismissal timer.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  success: function(message, autoHide, dismissable) {
    return this._open(message, this.SUCCESS, autoHide, dismissable);
  },

  /**
  * Opens a warning notification.
  * @param {String} message text to notify.
  * @param {Boolean} autohide sets automatic dismissal timer.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  warning: function(message, autoHide, dismissable) {
    return this._open(message, this.WARNING, autoHide, dismissable);
  },

  /**
  * Opens an error notification.
  * @param {String} message text to notify.
  * @param {Boolean} autohide sets automatic dismissal timer.
  * @param {Boolean} if the notice is dismissable with a close button.
  * @returns {this}
  */
  error: function(message, autoHide, dismissable) {
    return this._open(message, this.ERROR, autoHide, dismissable);
  },

  /**
  * Parses message content.
  * Message source and class names are extracted from any wrapper element.
  * @param {String} message raw text to parse.
  * @returns {Object} notice object with "message" and "classes" keys.
  */
  parse: function(message) {
    var wrapper = message.trim().match(/^<(?:div|span)[^>]*class=['"]([\s\w-]+)['"][^>]*>([\S\s]+)<\/(?:div|span)>$/);
    return {
      message: wrapper ? wrapper[2] : message,
      classes: wrapper ? wrapper[1] : ''
    };
  },

  /**
  * Low-level API for opening the notification message.
  * Use high-level methods for opening specific types of notifications.
  * @param {String} message to notify.
  * @param {String} type of notification.
  * @param {Boolean} auto-hide to disable the modal after a computed wait period.
  * @returns {this}
  * @private
  */
  _open: function(message, type, autoHide=true, dismissable=true) {
    // Resolve blank and existing notifications:
    if (!message) return;

    // Parse the message for a wrapper element:
    var notice = this.parse(message);

    // Test for back-end rendered message states:
    // "__modal" notifications are deferred to full modal view.

    if (!type) {
      if (/__modal/.test(notice.classes)) {
        return Modal.dialog(notice.message);
      } else if (/__error/.test(notice.classes)) {
        type = this.ERROR;
      } else if (/__hat/.test(notice.classes)) {
        type = this.HAT;
      } else {
        type = this.INFO;
      }
    }

    if (this.$el === null) {
      var insertLocation = $('body > *:first-child').first();
      // if the global header or masthead is in play, we want to place it above that
      ['.c-global-header', '.c-masthead'].forEach((selector) => {
        var node = $(selector);
        if (node.length > 0) {
          insertLocation = node.first();
        }
      });

      this.$el = $('<div id="chorus_notifiations"/>');
      this.$el.insertBefore(insertLocation);
    }

    // Populate the notification:
    var newNotice = $(NOTICE_ELEMENT)
      .html(notice.message)
      .append( (dismissable) ? NOTICE_DISMISS : '')
      .addClass('p-notice-'+ type)
      .on('click', '#notice-dismiss', () => { this.close(newNotice); })
      .appendTo(this.$el);

    // Set bottom style during the next event loop:
    setTimeout(() => { newNotice.css('top', '0'); }, 0);

    // Set auto-hide timeout:
    if (autoHide && dismissable) {
      clearTimeout(timer);
      timer = setTimeout(() => { this.close(newNotice); }, 2000 + message.length * 25);
    }

    return newNotice;
  },

  /**
  * Closes athe notification.
  * This may safely be called at any time without error,
  * even if there is no active notification on screen.
  * @returns {this}
  */
  close: function(notice) {
    if (notice) {
      if (TRANSITION_END) {
        notice.one(TRANSITION_END, () => { notice.remove(); }).css('top', '');
      } else {
        notice.remove();
      }
    } else if( this.$el ) {
      if (TRANSITION_END) {
        this.$el.children().one(TRANSITION_END, (evt) => { $(evt.target).remove(); }).css('top', '');
      } else {
        this.$el.empty();
      }
    }
    return this;
  }
};

export default Notification;
