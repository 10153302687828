import $ from 'jquery';

const escapeMap = {
  '&' : ['&amp;', '&AMP;', '&#x00026;', '&#x0026;', '&#x026;', '&#x26;', '&#38;'],
  '<' : ['&lt;', '&LT;', '&#x0003C;', '&#x003C;', '&#x03C;', '&#x3C;', '&#60;'],
  '>' : ['&gt;', '&GT;', '&#x0003E;', '&#x003E;', '&#x03E;', '&#x3E;', '&#62;'],
  '"' : ['&quot;', '&QUOT;', '&#x00022;', '&#x0022;', '&#x022;', '&#x22;', '&#34;'],
  // These last two can be a little sensetive when dealing with server-based tests.
  // which don't like &apos; or &grave;, as such we're using the unicode hexes as the first option
  "'" : ['&#x27;', '&apos;', '&#x00027;', '&#x0027;', '&#x027;', '&#39;'],
  '`' : ['&#x60;', '&grave;', '&DiacriticalGrave;', '&#x00060;', '&#x0060;', '&#x060;', '&#96;']
};

/**
* Presto's replacement for Underscore.js, AKA: <b>The Presto Utility Belt</b>.
* <p><img src="http://www.thinkwaytoys.com/ProductImages/Hires/63475.jpg" style="width:100%;max-width:500px;" alt=""></p>
* This module is designed to provide <em>really</em> general purpose methodologies.
* Please keep this AS LIGHT AS POSSIBLE, lest we recreate Underscore.
* Before adding a method, consider:
* <ul>
* <li>Is there an ES5 method for this? (such as: `.map`, `.reduce`, `.forEach`). Please use those.</li>
* <li>Is this methodology truly <em>general purpose</em>. Do other modules need it?</li>
* </ul>
* @module lib/utils
*/
const Utilities = {
  /**
   * Extends a base object with properties from additional passed objects:
   * @param {Object} base object to extend.
   * @param {...Object} objects to extend properties from.
   * @returns {Object} the base object, extended by all other argument objects.
   */
  extend: function(base) {
    for (var i = 1; i < arguments.length; i++) {
      var ext = arguments[i];
      for (var key in ext) {
        if (ext.hasOwnProperty(key)) base[key] = ext[key];
      }
    }
    return base;
  },

  /**
  * Build a macro-promise for all given promises.
  * This is useful when waiting on multiple state resolutions,
  * allowing multiple promises to be tracked by an aggregate.
  * @param {...Promise|Array} promises to aggregate into a single transaction.
  * @return {Promise} will resolve or reject when all promises finish.
  */
  all: function(...promises) {
    if (promises[0] instanceof Array) {
      promises = promises[0];
    }
    var deferred = $.Deferred();
    var expected = promises.length;
    var successes = [];
    var failures = [];

    for (var i = 0; i < expected; i++) {
      if (promises[i]) {
        promises[i].then(function(res) {
          successes.push(res);
          done();
        }, function(mssg) {
          failures.push(mssg);
          done();
        });
      }
    }

    function done() {
      if (successes.length === expected) {
        deferred.resolve(successes);
      } else if (successes.length + failures.length === expected) {
        deferred.reject(failures, successes);
      }
    }

    return deferred.promise();
  },

  /**
  * Picks select attributes names from an object.
  * @param {Object} base object to pick fields from.
  * @param {...String} fields to pick from the model.
  * @returns {Object} a new object with the requested field names.
  */
  pick: function(base) {
    var data = {};
    for (var i=1; i < arguments.length; i++) {
      data[arguments[i]] = base[arguments[i]];
    }
    return data;
  },

  /**
   * Debounces a function to only run after repeated invocations have stopped.
   * @param {Function} function to debounce.
   * @param {Number} time in milliseconds to delay invocation.
   * @param {Object} binding context in which the function is invoked.
   * @returns {Function} the debounced function.
   */
  debounce: function(fn, delay, context) {
    var timer;
    return function() {
      var callTarget = context || this;
      var callArgs = arguments;
      clearTimeout(timer);
      timer = setTimeout(function() {
        fn.apply(callTarget, callArgs);
      }, delay);
    };
  },

  /**
   * Throttles a function to only run once ever N milliseconds:
   * @param {Function} function to invoke.
   * @param {Number} throttle time limit, in milliseconds.
   * @param {Object} binding context in which the function is invoked.
   * @returns {Function} the throttled function.
   */
  throttle: function(fn, limit, context) {
    var timer = null;
    var called = false;
    var limiter = function() {
      if (timer) {
        called = true;
        return;
      }

      context = context || this;
      fn.call(context);

      timer = setTimeout(function() {
        var recall = called;
        timer = null;
        called = false;
        if (recall) limiter.call(context);
      }, limit);
    };
    return limiter;
  },

  /**
    * Gets the value at path of object. If the resolved value is undefined, the
    * defaultValue is returned in its place.
    * @param {Object} the object to query
    * @param {String} the dot-separated or bracket-accessed path of the property to get, i.e. 'foo.bar[1].baz'
    * @param {Object} the alternative value returned when undefined is the resolved value
    * @returns {Object} the resolved value, or the default
    */
  get: function(object, path, defaultValue) {
    var index = 0;
    path = path.replace(/\]/g, '').split(/[\[\.]/);
    while (object != null && index < path.length) {
      object = object[path[index++]];
    }
    return (index && index === path.length && object !== undefined) ?
      object : defaultValue;
  },

  /**
  * Parses a params object into a query string.
  * This is similar to the jQuery `$.param()` method,
  * except that variable encoding is intentionally not modified
  * (this assures that pre-encoded variables are not RE-encoded).
  * <ul>
  *   <li>input: {url: "http%3A%2F%2Faweso.me", utm_source: "social"}</li>
  *   <li>output: "url=http%3A%2F%2Faweso.me&utm_source=social"</li>
  * </ul>
  * @param {Object} params object with key/value pairs to encode.
  * @returns {String} formatted query string.
  * @private
  */
  paramitize: function(params) {
    var list = [];
    for (var key in params) {
      if (params.hasOwnProperty(key)) list.push(key +'='+ params[key]);
    }
    return list.join('&');
  },

  /**
  * Parses a params string into an object.
  * <ul>
  *   <li>input: "?utm_campaign=vox&utm_source=social"</li>
  *   <li>output: {utm_campaign: "vox", utm_source: "social"}</li>
  * </ul>
  * @param {String} params url-encoded variables string to parse.
  * @returns {Object} an object with key/value pairs for all params.
  * @private
  */
  deparamitize: function(params) {
    var obj = {};
    params = (params || '').replace(/^(\?|\&)/, '').split('&');
    for (var i=0; i < params.length; i++) {
      // split on greedy regex because thumbor urls, which get passed to pinterest.
      // can contain '=' in them.
      var pair = params[i].split(/=(.+)?/);
      if (pair.length > 1) obj[pair[0]] = pair[1] || '';
    }
    return obj;
  },

  // Return the current url without query or anchor
  currentUrl: function() {
    return window.location.href.split(/\?|#/)[0];
  },

  /**
  * Loads a script file - This uses the shared "Chorus.AddScript" if available
  * but backs up to standard dom-based injection
  @param {String} Url of remore script to load
  */
  loadScript: function(scriptUrl, callback) {
    var script = document.createElement('script');
    if (typeof callback === 'function') { script.onload = callback; }
    script.async = true;
    script.src = scriptUrl;
    var node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(script, node);
    return script;
  },

  /**
  * Parses a string and escapes HTML to the proper entities
  * @param {String} params string to escapte
  * @returns {String} escapted String
  * e.g. _.escape('<b>Hello</b>');
  *         -> '&lt;b&gt;Hello&lt;/b&gt;'
  */
  escape : function(string = '') {
    var escaper = function(match) {
      // match will be replaced with the first item specified in the escapeMap at the top of the page
      return escapeMap[match][0];
    };
    var source = '(?:' + Object.keys(escapeMap).join('|') + ')';
    var replaceRegexp = RegExp(source, 'g');
    return string.replace(replaceRegexp, escaper);
  },

  /**
  * Parses a string and unescapes HTML to the proper entities
  * @param {String} params string to unescaptes
  * @returns {String} unescaptes String
  * e.g. _.escape('&lt;b&gt;Hello&lt;/b&gt;');
  *         -> '<b>Hello</b>'
  */
  unescape : function(string = '') {
    for (var key in escapeMap) {
      var source = '(?:' + escapeMap[key].join('|') + ')';
      var replaceRegexp = RegExp(source, 'g');
      string = string.replace(replaceRegexp, key);
    }
    return string;
  },

  urlParams : function() {
    return this.deparamitize(window.location.search);
  }
};

export default Utilities;
