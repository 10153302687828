import './index.scss';
import Initializer from 'lib/initializer';
import UserAgent from 'lib/user_agent';
import _ from 'lib/utils';
import $ from 'jquery';

Initializer.registerComponent('site/video_preview', function(element, data) {
  if (UserAgent.isMobile()) {
    var imageWrapper = $(element).find('.c-video-preview__image-wrapper');
    imageWrapper.removeAttr('src');
    Initializer.get('site/video_embed').embed(imageWrapper[0], data);
  } else {
    $(element).on('click', (evt) => {
      evt.preventDefault();
      Initializer.get('site/video_embed').modal(_.extend(data, { 'include_cta' : false }));
    });
  }
});


