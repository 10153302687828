import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import UserAgent from 'lib/user_agent';

Initializer.registerComponent('site/package_subnav', function(element) {
  var $el = $(element);

  $el.find('.collapsible').on('click', '*[data-ui-action="collapse-toggle"]', (evt) => {
    var $dt = $(evt.delegateTarget);
    if ($dt.hasClass('collapsible-mobile-only') && !UserAgent.isSmallWindow()) {
      return;
    }
    evt.preventDefault();
    $(evt.delegateTarget).toggleClass('collapsed');
  });

  $el.find('.expandable').on('click', '*[data-ui-action="expand"]', (evt) => {
    evt.preventDefault();
    $(evt.delegateTarget).removeClass('expandable');
    $(evt.currentTarget).remove();
  });

  $el.find('*[data-ui-action="collapse-all"]').on('click', () => {
    $el.find('.collapsible').addClass('collapsed');
  });

  $el.find('*[data-ui-action="uncollapse-all"]').on('click', () => {
    $el.find('.collapsible').removeClass('collapsed');
  });

  element.querySelectorAll('ul ul a').forEach(function(linkElement) {
    var rawText = linkElement.textContent;
    var matches = rawText.match(/(^\d{1,3}\.)/);
    if (matches && matches.length > 0) {
      var index = matches[0];
      linkElement.classList.add('list-indexed');
      linkElement.innerHTML = '<span class="list-index">' + index + '</span>' + rawText.replace(index, '');
    }
  });

});
