import '../auth';
import './index.scss';
import 'promise-polyfill';
import Initializer from 'lib/initializer';
import viewport from 'lib/viewport';
import { currentUser } from 'models/context';

Initializer.registerComponent('apps/comment_forum', function(element, data) {
  // Start method:
  // loads the comments script module and then launches the application.
  function start() {
    // only allow this method to execute once:
    if (!start.called) {
      start.called = true;
      window.removeEventListener('keydown', launchKey);

      currentUser.fetch().then(() => {
        import(/* webpackChunkName: "app-comments" */ './scripts/main')
          .then(module => new module.default(element, data));
      });
    }
  }

  // Start keyboard handler:
  // Listens for "C" or "Z" key presses to kick of application loading.
  function launchKey(evt) {
    var key;

    // Ignores key presses within input fields on the page, or when used with control keys.
    if (!/textarea|input/i.test(document.activeElement.tagName) && !evt.ctrlKey && !evt.metaKey) {
      /* eslint-disable indent */
      switch (evt.which) {
        case 67: key = 'c'; break;
        case 90: key = 'z'; break;
      }
      /* eslint-enable indent */
    }

    if (key) {
      document.body.scrollTop = document.getElementById('comments').offsetTop - (window.innerHeight * 0.3);
      data.launchKey = key;
      start();
    }
  }

  window.addEventListener('keydown', launchKey);
  var selection = location.hash.slice(1);

  if (selection == 'comments') {
    start();
  } else if (/^\d+$/.test(selection)) {
    // Start immediately when we have a hash that looks like a comment ID:
    data.selection = Number(selection);
    start();
  } else {
    // Otherwise, startup lazily:
    viewport.trackElement(element, start);
  }
}, { priority: Initializer.HIGH });
