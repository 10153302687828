import NextclickModel from './model';
import NextclickView from './view';
import loadData from './raw_data_source';

function NextclickApp(el, data = {}) {
  this.model = new NextclickModel();
  this.view = new NextclickView({ 'el': el, 'model': this.model });
  loadData(data, el).then((resolvedData) => {
    if (resolvedData && resolvedData.links && resolvedData.links.length > 0) {
      this.model.head = resolvedData.head;
      this.model.reset(resolvedData.links);
    } else if (resolvedData && resolvedData.length > 0) {
      this.model.reset(resolvedData);
    }
  });
}

export default NextclickApp;
