import Backbone from 'exoskeleton';
import parseTemplate from 'lib/template';
import viewHTML from './tmpl/index.html';
import analytics from 'lib/analytics';
import $ from 'jquery';

let NextclickView = Backbone.View.extend({

  template: parseTemplate(viewHTML),

  initialize: function(opts) {
    this.model = opts.model;
    this.listenTo(this.model, 'reset', () => { this.render(); } );
  },

  render : function() {
    this.$('.c-related-list ul .dynamically-inserted').remove();
    if (this.model.head) {
      this.$('h2.c-related-list__head').html(this.model.head);
    }
    this.$('.c-related-list ul').prepend(this.template(this.model.toJSON()));

    this.$('.c-related-list ul a[data-delayed-analytics-link], .c-related-list ul a[data-delayed-analytics-viewport], .c-related-list ul a[data-vars-analytics-link-title]').each((link) => {
      var $link = $(link);
      $link.attr('data-analytics-link', $link.attr('data-delayed-analytics-link'));
      $link.attr('data-analytics-viewport', $link.attr('data-delayed-analytics-viewport'));
      $link.attr('data-vars-analytics-link-title', $link.attr('data-delayed-vars-analytics-link-title'));
    });
    analytics.trackImpressions(this.el);
    return this.el;
  }

});

export default NextclickView;
