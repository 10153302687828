import Backbone from 'exoskeleton';
import Modal from 'lib/modal';
import templateEngine from 'lib/template';
import gridHTML from '../tmpl/grid.html';

var GalleryGridView = Backbone.View.extend(
/** @lends module:apps/image_gallery.GalleryGridView.prototype */
  {
  /**
  * Gallery grid view manages the display of the grid overlay interface.
  * @constructs
  */
    className: 'c-image-gallery__grid',

    render: function() {
    // Lazy-parse template upon first render:
      this.tmpl = this.tmpl || templateEngine(gridHTML);

      // Populate rendered HTML:
      this.$el.html(this.tmpl({
        images: this.collection.toJSON(),
        index: 0
      }));

      // Find and activate the selected image:
      this.$el.find('[data-index="'+ this.collection.selectedIndex() +'"]').addClass('active');
      return this.$el;
    },

    events: {
      'click [data-index]': 'thumb'
    },

    thumb: function(evt) {
      evt.preventDefault();
      var index = this.$(evt.currentTarget).data('index');
      this.collection.selectByIndex(index);
      Modal.close();
    }
  });

export default GalleryGridView;
