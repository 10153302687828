import $ from 'jquery';
import GalleryImages from './models/images';
import GalleryView from './views/gallery';
import ImageGalleryResizer from './hooks/resizer';
import ImageGalleryRouter from './hooks/router';
import ImageGalleryKeyboard from './hooks/keyboard';
import _ from 'lib/utils';

/**
* Main image gallery application bootstrapper.
* This is the main constructor for building image gallery application instances.
* @param {Element} element to attach gallery application to.
* @param {Object} data used to configure the component.
* @module apps/image_gallery
* @constructor
*/
function ImageGallery(element, data) {
  var opts = { app: this };

  /**
  * The images collection for managing all application image data and selection state.
  * @type {GalleryImages}
  */
  this.images = new GalleryImages(this.collectImageData(element));

  if (data.routing) {
    /**
    * The controller used to tether URL routing onto data actions.
    * @type {ImageGalleryRouter}
    */
    this.router = new ImageGalleryRouter(opts);
  }

  if (data.keyboard) {
    /**
    * The controller used to tether keyboard controls onto data actions.
    * @type {ImageGalleryKeyboard}
    */
    this.keyboard = new ImageGalleryKeyboard(opts);
  }

  /**
  * The controller used to tether screen resize to display rendering.
  * @type {ImageGalleryResizer}
  */
  this.resizer = new ImageGalleryResizer(opts);

  /**
  * The main gallery view used to control the viewer/thumb scroller interface.
  * @type {GalleryView}
  */
  this.galleryView = new GalleryView({
    el: element,
    app: this
  });
}

ImageGallery.prototype = {
  /**
  * Collect gallery content out of the thumbnail listing DOM.
  * This data is bulky enough that passing it through `cdata` is prone to error.
  */
  collectImageData: function(element) {
    var data = [];
    $(element).find('[data-ui="thumb"]').each(function() {
      var $thumb = $(this);
      var thumbnail = $thumb.find('noscript').text();
      data.push({
        asset_id: $thumb.data('asset'),
        slug: $thumb.attr('data-slug'), // << use .attr() for raw, uncoerced slug string.
        caption: (_.unescape($thumb.find('.c-image-gallery__thumb-desc').html())).replace(/^[\s]+|[\s]+$/g, ''),
        title: (_.unescape($thumb.find('.c-image-gallery__thumb-title').html())).replace(/^[\s]+|[\s]+$/g, ''),
        thumb_url: thumbnail.replace(/.*src="([^"]*)".*/, '$1'),
        image_url: $thumb.find('a').first().attr('href')
      });
    });
    return data;
  }
};

export default ImageGallery;
