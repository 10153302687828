import './index.scss';
import 'promise-polyfill';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import templateEngine from 'lib/template';

Initializer.registerComponent('snippets/tv_listing', function(element, data) {

  var $elem = $(element).find('#tv-listing');

  // Template Imports
  var scheduleHTML = require('./tmpl/schedule.html');
  var providersHTML = require('./tmpl/providers.html');

  // Global state :\
  var activeProviderId,
    activeProviderName,
    activeZipcode;

  // Schema data
  var API_KEY = data.api_key;
  var SERIES = data.series;
  var DAYS_TO_SEARCH = data.search_days || 14;

  // types of provider incoming from API
  var providers = [
    {
      lineupType: 'OTA',
      lineupTypeName: 'Over the Air',
      lineups: []
    },
    {
      lineupType: 'SAT',
      lineupTypeName: 'Satellite',
      lineups: []
    },
    {
      lineupType: 'CAB',
      lineupTypeName: 'Cable',
      lineups: []
    },
    {
      lineupType: 'VMVPD',
      lineupTypeName: 'Provider',
      lineups: []
    }
  ];

  /**
  * Get provider data, either from localstorage or an API call
  */
  function getProviders() {
    var localStorageProviders = getProviderStorage();
    if (localStorageProviders) {
      renderProviders(localStorageProviders);
    } else {
      $elem.html('Loading providers...');
      $.getJSON(`https://api.tvmedia.ca/tv/v4/lineups?api_key=${API_KEY}&postalCode=${activeZipcode}`, function(response) {
        if (response.length > 0) {
          renderProviders(response);
          setProviderStorage(activeZipcode, response);
        } else {
          $elem.html('<p>Sorry, there are no channels available for this zip code</p>');
        }
      })
        .fail(function() {
          $elem.html('<p>An error occurred. Please try again later.</p>');
        });
    }
  }

  /**
  * Format results of API response by lineup type and render template
  * @param {Array} data array of provider objects.
  */
  function renderProviders(data) {
    providers.forEach(function(provider) {
      provider.lineups = [];
    });
    data.forEach(function(value) {
      var matchIdx = providers.map((p) => p.lineupType).indexOf(value.lineupType);
      if (matchIdx > -1) {
        providers[matchIdx].lineups.push(value);
      } else {
        // catch all bucket
        providers[3].lineups.push(value);
      }
    });
    $elem.html(templateEngine(providersHTML)({ providers: providers, zipcode: activeZipcode }));
  }

  /**
  * Get schedule data, from session storage or API call
  * @param {String} providerId id of the provider
  */
  function getSchedule(providerId) {
    var localSchedule = getScheduleStorage();
    if (localSchedule && withinTheHour(localSchedule.date)) {
      renderSchedule(localSchedule.data);
    } else {
      $elem.html('Loading schedule...');
      var endDate = getFutureDate(DAYS_TO_SEARCH);
      var timezoneOffset = new Date().getTimezoneOffset() / 60 * -1;
      $.getJSON(`https://api.tvmedia.ca/tv/v4/lineups/${providerId}/listings?api_key=${API_KEY}&detail=brief&series=${SERIES}&end=${endDate}&timezone=${timezoneOffset}:00`, function(response) {
        if (response.length > 0) {
          renderSchedule(response);
          setScheduleStorage(activeProviderId, response);
        } else {
          $elem.html(`<p>There are no shows coming up in the next ${DAYS_TO_SEARCH} days. <a href="#" id="return-results" class="fancy-underline">Return to channel results.</a></p>`);
        }
      })
        .fail(function() {
          $elem.html('<p>An error occurred. Please try again later.</p>');
        });
    }
  }

  /**
  * Parse incoming data and render schedule template
  * @param {Array} data list of show times
  */
  function renderSchedule(data) {
    var results = [];

    data.forEach(function(value) {
      var dateString = getDateString(value.listDateTime);
      var episodeInfo = {
        time: getTimeString(value.listDateTime),
        show: htmlEntities(value.showName),
        episode: htmlEntities(value.episodeTitle),
        ep_number: htmlEntities(value.episodeNumber),
        channel: htmlEntities(`Channel ${value.channelNumber} (${value.network})`)
      };
      // Check if day already exists
      var matchIdx = results.map((d) => d.dateString).indexOf(dateString);
      if (matchIdx >= 0) {
        results[matchIdx].programs.push(episodeInfo);
      } else {
        results.push({
          dateString: dateString,
          programs: [episodeInfo]
        });
      }
    });
    $elem.html(templateEngine(scheduleHTML)({ results: results, zipcode: activeZipcode }));
    getProviderDetails(activeProviderName);
  }

  /**
  * Determine if we have provider name in state or storage
  * otherwise use API to go get it
  * @param {String} providerName if we have it in state, pass the name
  */
  function getProviderDetails(providerName) {
    if (providerName) {
      renderName(providerName);
      return;
    }
    var providerId = activeProviderId;
    var local = getProviderNameStorage();
    if (local) {
      renderName(local);
    } else {
      $.getJSON(`https://api.tvmedia.ca/tv/v4/lineups/${providerId}?api_key=${API_KEY}&detail=brief`, function(response) {
        renderName(response.lineupName);
        setProviderNameStorage(activeProviderId, response.lineupName);
      });
    }
  }

  /**
  * Render provider name in dom
  * @param {String} name the name to render
  */
  function renderName(name) {
    activeProviderName = htmlEntities(name);
    $elem.find('#provider_title').html(`Program results for <strong>${activeProviderName}:</strong>`);
  }

  /**
  * Attach click listeners and
  * state listener for changes to history (browser back button)
  */
  function attachListeners() {
    $elem.on('click', '.c-tv-listing__provider-list a', providerHandler);
    $elem.on('click', '#return-results', returnHandler);
    window.addEventListener('popstate', stateHandler);
  }

  function stateHandler() {
    routeURL(parseUrlParams());
  }

  /**
  * Handle click event from provider list
  * Put provider name in storage so we dont have to use API
  * @param {Event} event
  */
  function providerHandler(event) {
    event.preventDefault();
    var $target = $(event.currentTarget);
    activeProviderId = $target.data('lineupid');
    activeProviderName = $target.text();
    setProviderNameStorage(activeProviderId, activeProviderName);
    pushURL({ 'providerId': activeProviderId, 'zipcode': activeZipcode });
  }

  /**
  * Handle click event from "return to results" link
  * @param {Event} event
  */
  function returnHandler(event) {
    event.preventDefault();
    pushURL({ 'zipcode': activeZipcode });
  }

  /**
  * Use history API to push query params and then route the URL to change state
  * @param {Object} data zipcode, providerId
  */
  function pushURL(data) {
    var queryString = `?zipcode=${data.zipcode}`;
    if (data.providerId) {
      queryString += `&providerId=${data.providerId}`;
    }
    window.history.pushState(data, null, queryString);
    routeURL(data);
  }

  /**
  * From incoming data, determine which template to show
  * @param {Object} data zipcode, providerId
  */
  function routeURL(data) {
    if (data) {
      if (data.providerId && data.zipcode) {
        activeZipcode = data.zipcode;
        activeProviderId = data.providerId;
        getSchedule(data.providerId);
      } else if(data.zipcode) {
        activeZipcode = data.zipcode;
        getProviders();
      }
      $('#c-tv-listing-zipcode').attr('value', activeZipcode);
      return;
    }
  }

  /**
  * Parse URL params into usable object
  * TODO: lop off bottom half when IE11 support goes away
  * @returns {Object} key/value pairs
  */
  function parseUrlParams() {
    if (!window.location.search) return {};
    var returnObj = {};
    if (window.URLSearchParams) {
      var urlParams = new URLSearchParams(window.location.search);
      for(var entry of urlParams.entries()) {
        returnObj[entry[0]] = entry[1];
      }
    // ELSE IE11
    } else {
      var urlstring = window.location.search.replace('?', '');
      var params = urlstring.split('&');
      params.forEach(function(string) {
        var t = string.split('=');
        returnObj[t[0]] = t[1];
      });
    }
    return returnObj;
  }

  /**
  * Format a Date to a time, 9:00 am
  * @param {Date} timestamp a Date object or blank to use current time
  * @returns {String} formatted time
  */
  function getTimeString(timestamp) {
    timestamp = safariarizeDate(timestamp);
    var d = (timestamp instanceof Date) ? timestamp : new Date(timestamp);
    var h = (d.getHours() + 11) % 12 + 1;
    var hh = (d.getHours() < 12) ? 'AM' : 'PM';
    var m = ('00'+d.getMinutes()).slice(-2);
    return `${h}:${m} ${hh}`;
  }

  /**
  * Format a Date so that Safari will actually use timezone offset properly >(
  * @param {String} date a datetime string from the API, formatted as YYYY-MM-DD HH:MM:SS
  * @returns {String} formatted date as YYYY-MM-DDTHH:MM:SS-HH:MM
  */
  function safariarizeDate(date) {
    var timezoneOffset = new Date().getTimezoneOffset();
    var symbol = timezoneOffset > 0 ? '-' : '+';
    var hours = timezoneOffset / 60;
    var minutes = timezoneOffset % 60;
    var pad = function(num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    var tz = `${symbol}${pad(hours)}:${pad(minutes)}`;
    return date.replace(' ', 'T') + tz;
  }

  /**
  * Format a Date to words, Mon. May 22, 2020
  * @param {Date} timestamp a Date object or blank to use current time
  * @returns {String} formatted date
  */
  function getDateString(timestamp) {
    timestamp = safariarizeDate(timestamp);
    var DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = (timestamp instanceof Date) ? timestamp : new Date(timestamp);
    return `${DAY_NAMES[d.getDay()]}. ${MONTH_NAMES[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  }

  /**
  * Get x days from now in a nice format
  * @param {Number} days some number of days from now
  * @returns {String} formatted date, 2020/10/20 (no leading zeros)
  */
  function getFutureDate(days) {
    var d = new Date();
    d.setDate(d.getDate() + days);
    return d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate();
  }

  /**
  * Get provider data from localstorage if it exists
  * @returns {Array|Boolean} list of providers, or false
  */
  function getProviderStorage() {
    try {
      var storage = JSON.parse(window.localStorage.getItem('tv_providers'));
      if (storage.zipcode === activeZipcode && storage.data.length > 0) {
        return storage.data;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  /**
  * Set provider data in localstorage
  * @param {}
  * @param {String} zipcode zipcode that this list corresponds to
  * @param {Array} data list of providers
  */
  function setProviderStorage(zipcode, data) {
    var storageJson = {
      'zipcode': zipcode,
      'data': data
    };
    window.localStorage.setItem('tv_providers', JSON.stringify(storageJson));
  }

  /**
  * Get schedule data from sessionstorage if it exists,
  * and if it's less than an hour old
  * @returns {Array|Boolean} list of air times, or false
  */
  function getScheduleStorage() {
    try {
      var storage = JSON.parse(window.sessionStorage.getItem('tv_schedule'));
      if (storage.provider_id == activeProviderId && storage.data.length > 0) {
        return storage;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  /**
  * Set schedule data to sessionstorage with timestamp
  * @param {String} providerId provider id for this schedule
  * @param {Array} data list of air times for episodes
  */
  function setScheduleStorage(providerId, data) {
    var storageJson = {
      'provider_id': providerId,
      'data': data,
      'date': Date.now()
    };
    window.sessionStorage.setItem('tv_schedule', JSON.stringify(storageJson));
  }

  /**
  * Get provider name from localstorage if it exists
  * @returns {String|Boolean} provider name or false
  */
  function getProviderNameStorage() {
    try {
      var storage = JSON.parse(window.localStorage.getItem('tv_provider_name'));
      if (storage.provider_id == activeProviderId && storage.provider_name.length > 0) {
        return storage.provider_name;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  /**
  * Set provider name in localstorage
  * @param {String} id provider id
  * @param {String} name provider name
  */
  function setProviderNameStorage(id, name) {
    var storageJson = {
      'provider_id': id,
      'provider_name': name
    };
    window.localStorage.setItem('tv_provider_name', JSON.stringify(storageJson));
  }

  /**
  * Check if timestamp is within the current hour
  * @param {String} date timestamp from Date()
  * @returns {Boolean}
  */
  function withinTheHour(date) {
    return (Date.now() - date) < (1000 * 60 * 60);
  }

  /**
  * JS has no native method for encoding HTML tags
  * So here's one
  * @param {String} str untrusted string
  * @returns {String}
  */
  function htmlEntities(str) {
    if(!str) return '';
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }

  // INIT //
  routeURL(parseUrlParams());
  attachListeners();
});
