import Backbone from 'exoskeleton';
import { TRANSITION_END } from 'lib/effects';

let AccoladesView = Backbone.View.extend({
  index: 0,
  inTransition: false,
  timer: null,
  cycleInterval: 8000,

  initialize: function() {
    this.$items = this.$('li');
    this.$pager = this.$('[data-ui="pager"]');

    // Generate pager listings:
    var pages = '';
    this.$items.each(function(index) {
      pages += '<span data-to="'+ index +'"></span>';
    });

    this.$pager.html(pages);
    this.updatePage();
    var self = this;
    var to = setTimeout(function() {
      self.setMinHeight();
      clearTimeout(to);
    }, 500); // give it some time to render
    this.timer = setTimeout(function() {
      self.advance(1);
    }, this.cycleInterval);
    this.startTimer();
  },

  stopTimer : function () {
    clearTimeout(this.timer);
  },

  startTimer : function () {
    this.stopTimer();
    var self = this;
    this.timer = setTimeout(function() {
      self.advance(1);
    }, this.cycleInterval);
  },

  /**
  * Advance current state.
  * @param {Number} direction to advance slides (1 or -1)
  */
  advance: function(dir) {
    if (!dir || this.inTransition) return;
    this.stopTimer();
    var current = this.index;
    var next = current + dir;
    var maxIndex = this.$items.length - 1;
    var slideLeft = (dir < 0);
    var classes = ['offscreen-left', 'offscreen-right'];
    var enterClass = slideLeft ? classes[0] : classes[1];
    var exitClass = slideLeft ? classes[1] : classes[0];
    var self = this;

    // Loop next index:
    if (next > maxIndex) {
      next = 0;
    } else if (next < 0) {
      next = maxIndex;
    }

    // Configure transition start state:
    var $current = this.$items.eq(current);
    var $next = this.$items.eq(next).addClass(enterClass).css('display', 'block');

    // Reconfigure for transition on next event loop:
    setTimeout(function() {
      $next.removeClass(enterClass);

      $current.one(TRANSITION_END, function() {
        $current.hide().removeClass(exitClass);
        self.inTransition = false;
      }).addClass(exitClass);
      self.setMinHeight($next);
    }, 0);

    // Flag new state:
    this.inTransition = true;
    this.index = next;
    this.updatePage();
    this.startTimer();
  },

  /**
  * Sets the min height to be at least as tall as the provided slide
  * or the first one if empty
  * @param {$selectedItem} - item used to measure min-height
  */
  setMinHeight : function($selectedItem = null) {
    if (!$selectedItem) {
      $selectedItem = this.$el.find('.c-pullquotes-list__quote').first();
    }
    var minHeight = $selectedItem.height() + 100;
    if (this.$el.height() < minHeight) {
      this.$el.height(minHeight);
    }
  },

  /**
  * Advances straight to another index.
  * @param {Number} index to advance to.
  */
  to: function(index) {
    this.advance(index - this.index);
  },

  /**
  * Updates the current state of pagination.
  */
  updatePage: function() {
    this.$pager
      .children()
      .removeClass('active')
      .eq(this.index)
      .addClass('active');
  },

  events: {
    'click [data-advance]': 'onAdvance',
    'click [data-to]': 'onTo'
  },

  onAdvance: function(evt) {
    evt.preventDefault();
    this.advance(this.$(evt.currentTarget).data('advance'));
  },

  onTo: function(evt) {
    evt.preventDefault();
    this.to(this.$(evt.currentTarget).data('to'));
  }
});

export default AccoladesView;
