import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';

Initializer.registerComponent('site/masthead', function(element, data) {
  var $el = $(element);
  var searchIcon = $('.c-masthead-custom .c-global-header__search');

  $el.on('site_sponsorship_logo_rendered site_sponsorship_logo_mobile_rendered', (evt) => {
    $el.addClass('has-site-sponsorship-logo');
  });

  searchIcon.click(function() {
    $('.c-masthead-custom').toggleClass('is-search-active');
  });

}, {
  priority: Initializer.HIGH
});
