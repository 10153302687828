import Backbone from 'exoskeleton';

var AdSurveyModel = Backbone.Model.extend({
  responses: [],

  initialize: function(opts) {
    if (opts.ad_survey_running || false) {
      // parameterize responses
      var _responses = opts.responses || [];
      var response;
      for (var i=0; i < _responses.length; i++) {
        response = _responses[i];
        this.responses.push({
          text: response,
          value: this.parameterize(response)
        });
      }
    }
  },

  parameterize: function(string) {
    return string.trim().replace(/[^a-zA-Z0-9-\s]/g, '').replace(/[^a-zA-Z0-9-]/g, '-').toLowerCase();
  }

});

export default AdSurveyModel;
