/* eslint no-global-assign: 0, no-native-reassign: 0 */
/**
* <p>The master component initializer for setting up JavaScript-based component instances.</p>
* <p><b>Lifecycle:</b></p>
* <ol>
*  <li>An initialization handler function is registered for each component type.
*  These initializer functions are configured to setup discrete component instances.</li>
*  <li>Each component element is rendered into the DOM with a few initializer attributes.</li>
*  <li>On startup, the initializer queryies for all component elements with initializer attributes.</li>
*  <li>Each component element is handed off to its registered initialization handler for setup.</li>
* </ol>
* <p>This process avoids repetative DOM queries (by finding all component elements ONCE),
* and then only runs JavaScript for components that are actually present on the page.
*
* <p>While the initializer is mainly concerned with configuring component instances,
* it also handles registering buildable components and orchastrating one-off DOM ready tasks.
* As a general rule, NO scripts should latch onto DOM ready.
* Instead, everything should go through this initializer for proper sequencing.</p>
*
* @module lib/initializer
*/

import $ from 'jquery';
import _ from 'lib/utils';

var win = window;

win.requestIdleCallback = win.requestIdleCallback || function(cb) {
  function handler() {
    var start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: function() {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }

  if (win.requestAnimationFrame) {
    return win.requestAnimationFrame(handler);
  } else {
    return setTimeout(handler, 1);
  }
};

// Polyfill performance API with Date object (that also has a now() method)
if (typeof performance === 'undefined') {
  performance = Date;
}

var perfStats = {
  uri: {},
  com: {}
};

function elementInitializer(el, fn, cid, uri) {
  return function() {
    try {
      var startTime = performance.now();
      fn(el, JSON.parse(el.getAttribute('data-cdata') || '{}'));
      var delta = perfStats.com[cid] = performance.now() - startTime;
      perfStats.uri[uri] = (perfStats.uri[uri] || 0) + delta;
    } catch(e) {
      console.log('presto: error initializing '+cid, e);
    }
  };
}

/**
* @constructor
*/
function Initializer() {
  this._c = {}; // components
  this._cinit = {};
  this._buffer = [];
  this._queue = [];
  this._running = false;
  this.perf = perfStats;

  this._ready = $.Deferred();
  this._consent = $.Deferred();

  this.domReady(() => {
    this._ready.resolve();
    this.run();
  });
}

Initializer.prototype = {
  domReady: $, // allows for testing stubs

  LOW: 0,
  MEDIUM: 50,
  HIGH: 100,

  isReady: function() {
    return this._ready.state() === 'resolved';
  },

  hasConsent: function() {
    return this._consent.state() === 'resolved';
  },

  consent: function() {
    this._ready.then(() => this._consent.resolve());
  },

  /**
  * Registers a new component initialization handler.
  * This initializer will automatically run on all matching component elements found at page startup.
  * Use this method to setup JavaScript-based component instances that are rendered into a page.
  * @param {String} uri component reference, ex: "apps/image_gallery".
  * @param {String} function used to configure each component instance.
  * Handler function receives two arguments:<br>
  * <code>function(element, data) { ...</code>
  * <ul>
  *   <li>element: the DOM element to configure as a component instance.</li>
  *   <li>data: the complete data blob of the rendered component.</li>
  * </ul>
  * @param {Object} options used to configure the initializer. Accepts:
  * <ul>
  * <li>`priority`: the initialization priority for this class of component (higher number runs later). Uses 10 by default.</li>
  *</ul>
  * @example
  Initializer.registerComponent('site/kangaroo_boxing', function(element, data) {
    var app = new KangarooApp(element, data);
  }, { priority: 100 });
  */
  registerComponent: function(componentUri, handler, exports) {
    // Accept polymorphic input where the initialization handler is omitted:
    if (typeof handler !== 'function') {
      exports = handler;
      handler = function() {};
    }

    exports = exports || {};
    var waitForConsent = exports.waitForConsent || false;

    this._c[componentUri] = {
      fn: handler,
      ex: exports,
      priority: (typeof exports.priority === 'number') ? exports.priority : this.MEDIUM,
      consent: waitForConsent
    };

    if ((waitForConsent && this.hasConsent()) || (!waitForConsent && this.isReady())) {
      this.run();
    }
  },

  /**
  * Gets the exported object from a registered component.
  */
  get: function(componentUri) {
    if (!this._c.hasOwnProperty(componentUri)) {
      throw 'Component "'+ componentUri +'" is not defined. Add it as a dependency of the getter.';
    }
    return this._c[componentUri].ex;
  },

  /**
  * Runs the Initializer.
  * Scrapes all component instances from the DOM one time each,
  * and submits them each to their corresponding initialization handler.
  * @param {Element} scope element within which to run the initializer.
  * @private
  */
  run: _.debounce(function(scope) {
    var elements = (scope || document).querySelectorAll('[data-cid]');

    [].slice.call(elements).forEach((el) => {
      var cid = el.getAttribute('data-cid');
      var uri = cid.split('-')[0];
      var handler = this._c[uri];

      // Only queue uninitialized elements with a registered handler:
      if (!this._cinit[cid] && handler) {
        this._cinit[cid] = true;
        var opts = { priority: handler.priority };
        if (handler.consent) {
          this.consentTask(elementInitializer(el, handler.fn, cid, uri), opts);
        } else {
          this.readyTask(elementInitializer(el, handler.fn, cid, uri), opts);
        }
      }
    });

  }, 10),

  /**
  * Registers a task to run upon document ready.
  * Similar to `task`, except that no work is queued
  * until document-ready is called.
  * @param {Function} task function to execute.
  * @param {Object} options for setting task priority.
  */
  readyTask: function(taskFn, opts) {
    this._ready.then(() => this.task(taskFn, opts));
  },

  /**
  * Registers a task to run upon application consent.
  * Similar to `task`, except that no work is queued
  * until the document is ready and the application has
  * officially recieved consent to run everything.
  * @param {Function} task function to execute.
  * @param {Object} options for setting task priority.
  */
  consentTask: function(taskFn, opts) {
    this._consent.then(() => this.task(taskFn, opts));
  },

  /**
  * Registers a task to run.
  * Tasks are buffered into the idle callback queue,
  * and will be run as quickly as possible within framerate.
  * Use this method for queuing any arbitrary unit of work.
  * @param {Function} task function to execute.
  * @param {Object} options for setting task priority.
  * @example
  * import Initializer from 'lib/initializer';
  * Initializer.task(function() {
  *   // ... do heavy lifting ...
  * });
  */
  task: function(taskFn, opts) {
    var self = this;
    var task = {};
    for (var key in opts) task[key] = opts[key];
    task.fn = taskFn;

    if (task.priority === undefined) {
      task.priority = this.MEDIUM;
    }

    this._buffer.push(task);
    if (this._running) return;

    var exec = function() {
      self._running = true;
      win.requestIdleCallback(function(status) {
        // Merge buffer into queue, sorting highest priority to back (for popping):
        self._queue = self._queue.concat(self._buffer).sort(function(a, b) {
          return a.priority - b.priority;
        });

        // Empty the task buffer:
        self._buffer.length = 0;

        // Run the queue while there are tasks and time remaining:
        while (self._queue.length && status.timeRemaining() > 0) {
          self._queue.pop().fn();
        }

        // Keep running while there are remaining tasks in the queue or buffer:
        if (self._queue.length || self._buffer.length) {
          exec();
        } else {
          self._running = false;
        }
      }, { timeout: 35 }); // << allow two 60FPS cycles before forcing work.
    };

    exec();
  },

  report: function() {
    var componentCountsByUri = {};
    Object.keys(this.perf.com).forEach((cid) => {
      var uri = cid.split('-').shift();
      componentCountsByUri[uri] = (componentCountsByUri[uri] || 0) + 1;
    });

    var records = Object
      .keys(this.perf.uri)
      .map((key) => {
        var time = this.perf.uri[key];
        var count = componentCountsByUri[key];
        return { uri: key, total_time: time, average_time: time/count, count: count };
      })
      .sort((a, b) => b.total_time - a.total_time);

    if (console.table) {
      console.table(records.reduce((memo, r) => {
        memo[r.uri] = r;
        delete r.uri;
        return memo;
      }, {}));
      return 'Total milliseconds: '+ records.reduce((sum, r) => sum + r.total_time, 0);
    } else {
      return records;
    }
  }
};

var init = window.Initializer = new Initializer();
export default init;
export const constructor = Initializer;
