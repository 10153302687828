import $ from 'jquery';
import _ from 'lib/utils';

/**
* Screen resize manager object that handles
* updating the presentation when screen size changes.
*
* <p>This is a listen-only attachement:
* nothing in the app should call directly to this feature.
* This keeps all feature configuration contained in one place,
* and makes it easy to disable the feature as needed.</p>
* @param {Object} options used to pass "app" reference.
* @constructor
*/
function ImageGalleryResizer(opts) {
  this.app = opts.app;

  $(window)
    .on('resize', _.debounce(this.update.bind(this), 200))
    .on('orientationchange', this.update.bind(this));
}

ImageGalleryResizer.prototype = {
  /**
  * Updates the app based on current screen size.
  * Handles re-rendering thumbnails to fit the screen.
  */
  update: function() {
    this.app.galleryView.renderThumbs();
  }
};

export default ImageGalleryResizer;
