/**
* The Entries model manages the loading of entry stat data (comments, user recs, etc).
* This is a batch-fetching model, similar to DynamicImages.
*
* <p><b>Lifecycle:</b></p>
* <ol>
* <li>Entry ids are added into the Entries collection.</li>
* <li>Upon adding entries, a new debounced fetch cycle kicks off.</li>
* <li>Data is loaded by the collection for all pending entries at the end of each fetch cycle.</li>
* <li>Bulk-loaded data is parsed by the collection into individual entry models.</li>
* </ol>
*
* <p>To use this model, add an entry ID into the collection,
* and then bind "change" listeners onto the returned model.
* Configure your views to re-render entry model data whenever it changes.</p>
*
* <p>The Entries module exports a singleton instance, so just import and use.</p>
*
* @example
* import EntryStats from 'models/entry_stats';
*
* var entryStat = EntryStats.add({id: 12345678});
*
* entryStat.on('change', function() {
*    // ... do your thang ...
* });
*
* @module models/entry_stats
*/

import Backbone from 'exoskeleton';
import Ajax from 'lib/ajax';
import _ from 'lib/utils';


let EntryStat = Backbone.Model.extend(Ajax.extend(
/** @lends module:models/entry_stats.EntryStat.prototype */
  {
  /**
  * <p>The EntryStat model manages stats for a single entry.
  * These stats include comment counts, unread comments, and recommended state.
  * This model should <em>only</em> be created through the Entries collection;
  * it should never be instanced directly.</p>
  *
  * <p><b>Fetching</b><br>
  * This model does not fetch its own data.
  * Instead, data is loaded in bulk for all entries by the parent collection.
  * To create an entry and fetch its data, simply add an entry ID to the
  * parent collection and listen for events on the returned entry model.</p>
  *
  * <p><b>Recommending</b><br>
  * An Entry model manages the client-side recommended state for its entry,
  * and handles all Ajax transactions with the recommendation flags controller.
  * To present recommendation state, bind a "change:recommended_by_user" event
  * onto an entry model, and refresh the display whenever the entry model changes.</p>
  *
  * @example
  *
  * import EntryStats from 'models/entry_stats';
  *
  * // Create a new entry:
  * var entry = EntryStats.add({id: 12345678});
  *
  * // Listen to changes in the entry data:
  * entry.on('change', function() {
  *   // render presentation
  * });
  *
  * // Perform recommendation:
  * entry.recommend(true)
  *  .done(function() {
  *    // render success!
  *  })
  *  .fail(function(mssg) {
  *    // message failure
  *  });
  *
  * @augments Backbone.Model
  * @constructs
  */
    defaults: {
      comment_count: null,
      comment_unreads: 0,
      id: null,
      recommended_count: null,
      recommended_by_user: false,
      url: null
    },

    ajaxOptions: {
      method: 'post'
    },

    urls: {
      'rec': '/flags/recommend/:id.json',
      'unrec': '/flags/unflag/:id.json'
    },

    /**
  * Reloads model data from the server.
  * This flags the model as unrequested,
  * and then prompts the parent collection commence a new fetch cycle.
  */
    reload: function() {
      this._req = false;
      this.collection.load();
    },

    /**
  * Specifies if the model is recommended by the user.
  * @returns {Boolean}
  */
    isRecommendedByUser: function() {
      return !!this.get('recommended_by_user');
    },

    /**
  * Sets the user-recommended state of the entry.
  * Attempts Ajax transaction, and then restores last valid state upon failure.
  * @param {Boolean} toggle state to enable/disable recommendation.
  * Inverts current state by default.
  * @returns {Promise}
  */
    recommend: function(toggle) {
      var prevState = this.isRecommendedByUser();
      var prevCount = this.get('recommended_count');
      toggle = (toggle === undefined) ? !prevState : toggle;

      // Nothing to toggle:
      // return early with last Ajax request, or else a success stub.
      if (prevState === toggle) {
        return this._rec || this.ajaxStub({ success: true });
      }

      // Cancel any existing in-progress action:
      // (this will abort any pending XHR transaction)
      if (this._rec) this._rec.cancel();

      // Assign new data state:
      this.set({
        recommended_count: Math.max(0, prevCount + (toggle ? 1 : -1)),
        recommended_by_user: toggle
      });

      // Sync via Ajax, with error rollback:
      this._rec = this.ajax(toggle ? 'rec' : 'unrec', {
        error: () => {
          this.set({
            recommended_count: prevCount,
            recommended_by_user: prevState
          });
        }
      });

      return this._rec;
    }
  }));


let EntryStats = Backbone.Collection.extend(
/** @lends module:models/entry_stats.EntryStats.prototype */
  {
    model: EntryStat,

    // API endpoint: available for testing purposes.
    endpoint: '/services/entry_stats?id=',

    /**
  * Manages the set of all Entry instances, and handles batch-fetching entry metadata via ajax.
  * @augments Backbone.Collection
  * @constructs
  */

    initialize: function() {
      this.listenTo(this, 'add', this.load);
    },

    /**
  * Loads data for all pending entries awaiting content.
  * Invoking this method starts a new debounced fetch cycle for bulk-loading pending models.
  * This method will automatically fire whenever an entry is added to the collection,
  * therefore calling this method directly is discouraged.
  * To load a model, simply add its id to the collection and listen for its events.
  *
  * @example
  * // To load data, do this:
  * // the added entry ID will automatically bulk-load.
  * import EntryStats from 'models/entry_stats';
  *
  * var entry = EntryStats.add({id: 12345678});
  */
    load: _.debounce(function() {

      var keys = this.models
        .filter(function(model) {
          return !model._req;
        })
        .map(function(model) {
          model._req = true;
          return model.id;
        })
        .sort(function(a, b) {
        // Sort IDs to maximize the chances of browser caching.
        // Sort descending to put higher IDs (newer content) first
        // (the API has caps on the number of lookups allowed per request,
        // so we want to make sure our newest content is serviced first).
          return Number(b) - Number(a);
        });

      // De-duplicate the array of keys (only keeps unique and truthy entry IDs):
      for (var i = keys.length-1; i >= 0; i-=1) {
        if (keys[i] === keys[i-1] || !keys[i]) keys.splice(i, 1);
      }

      // Return early if we have no keys to request:
      if (!keys.length) return;

      // Set collection endpoint and then go fetch:
      this.url = this.endpoint + keys.join(',');
      this.fetch();

    }, 250),

    /**
  * Parse incoming data (invoked with newly-received ajax data).
  * We're not adding anything to the collection here,
  * just parsing the fetched data into their corresponding models.
  * @private
  */
    parse: function(data) {
    // Map data into a table of id keys:
      data = data.reduce((memo, entry) => {
        memo[entry.id] = entry;
        return memo;
      }, {});

      // Return existing content, extended with loaded content.
      return this.models.map(function(model) {
        return _.extend(model.toJSON(), data[model.id] || {});
      });
    }
  });

export default new EntryStats();
