import '../image_enlarge';
import '../snippet_animation';
import $ from 'jquery';
import _ from 'lib/utils';
import Initializer from 'lib/initializer';
import RuntimeFormatters from './scripts/runtime_formatters';
import LazyFormatters from './scripts/formatters';
import video from 'lib/video';

// Apply runtime formatters immediately (before DOM Ready)
var featureBody = document.querySelector('.l-feature .c-entry-content, .l-page .c-entry-content');
RuntimeFormatters.loadTwitter();

// needs to be run before doe ready so that the picture element/dynamic image picks up the right size
if (featureBody) {
  RuntimeFormatters.fullBleed(featureBody);
  $(window).on('resize', _.throttle(()=> { RuntimeFormatters.fullBleed(featureBody, 1000); }));
}

Initializer.registerComponent('site/entry_formatter', (element, data) => {
  function runFormatters() {
    var entryBodies = $('.c-mapstack__card, .c-entry-content, .c-entry-intro, .l-col__main > .c-video-embed--media, .presto-wrapper');

    if (entryBodies.length > 0) {
      var opts = { priority: Initializer.LOW };

      Initializer.task(function() { LazyFormatters.embedSanitizer(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.hrIllo(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.dropCap(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.imageEnlarge(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.dataTables(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.ratingsSnippet(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.shareQuote(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.snippetAnimations(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.captionSpacingNormalizer(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.listicleSharing(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.refreshTwitterEmbeds(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.refreshInstagramEmbeds(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.legacyVoxmediaToolsSupprt(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.pinterestEmbeds(entryBodies); }, opts);
      Initializer.task(function() { LazyFormatters.wideImageCompensation(entryBodies); }, opts);
      Initializer.task(function () { LazyFormatters.narrowImageWidth(entryBodies); }, opts);
      Initializer.task(function() { video.install(); }, opts);

      entryBodies.each(function() {
        Initializer.run(this);
      });

      // this needs to be rerun, be cause sometimes the width gets miscalculated
      // see COM-177
      if (featureBody) {
        RuntimeFormatters.fullBleed(featureBody);
      }
    }
  }

  document.addEventListener('anthem.preview-updated', runFormatters);
  runFormatters();
});

export default {
  /* Helper methods for external use */
  formatVideoEmbeds: function($scope) {
    LazyFormatters.embedSanitizer($scope);
  },

  formatGIFs: function($scope) {
    LazyFormatters.gifs($scope);
  }
};
