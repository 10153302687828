import IframeClient from './iframeclient';
import currentContext from 'models/context';
import Analytics from 'lib/analytics';
import Viewport from 'lib/viewport';
import _ from 'lib/utils';

var xframe;

/**
* Volume external API.
* Install this script on the HOST PAGE that will include embeds.
* Features:
* <ul>
*  <li>Installs iframe embeds into Volume placeholder elements.</li>
*  <li>Sends "view" messages to embeds when scrolled into viewport.</li>
*  <li>Provides proxy API for sending messages to iframe embeds.</li>
* </ul>
* @param {String|Element|Window} target selector string, iframe element, or target window.
* @returns {VolumeClient}
* @example
* import volume from 'lib/video';
* volume.install('http://staging.volume.voxmedia.com');
* volume('#my-embed').play();
* volume('#my-embed').pause();
*
* @module lib/video
*/
function volume(target) {
  return new VolumeClient(target);
}

/**
* Gets the DOM placement value for an element.
* This traverses up the DOM tree looking for placement attributions.
* @param {Element} an element to extract placement from.
* @private
*/
function getAnalyticsPlacement(el) {
  var placement;
  while (!placement && el && el.getAttribute) {
    placement = el.getAttribute('data-analytics-placement');
    if (placement) return placement;
    else el = el.parentNode;
  }
  return 'other';
}

/**
* Specifies the Volume embed host.
* Uses production host by default.
* This may be set to target staging or development servers.
* Alternatively, this may be set while calling `.install()`.
* @type {String}
*/
volume.embedHost = window.volume_embed_host || 'https://volume.vox-cdn.com';

/**
* Selects all Volume iframe embeds on the page.
* @returns {NodeList} result of `querySelectorAll` targeting iframe embeds.
*/
volume.selectAllEmbeds = function() {
  return document.querySelectorAll('iframe[src^="'+ this.embedHost +'/embed"]');
};

/**
* Installs all Volume video placeholders into the document,
* and configures viewport tracking for new and existing embeds.
* Call this after adding any new Volume embeds/placeholders into the document.
* (invoking this method multiple times per page is OKAY)
* NOTE: this is imported and called within the "foundation/base" component.
* @param {String} [host] optional URL to use as the embed host.
* @example
* volume.install();
* volume.install('http://staging.volume.voxmedia.com');
*/
volume.install = function(host) {
  if (host) this.embedHost = host;
  if (currentContext.get('privacy')) return this;
  this.log('install ' + this.embedHost);
  var embeds = this.selectAllEmbeds();
  var videos = document.querySelectorAll('.volume-video');
  var i;

  if (!xframe) {
    // Install cross-frame message bus during initial request:
    // this will run once during the first installation of Volume on the page.
    // This message bus handles all communication with Volume iframe instances.
    xframe = IframeClient.create('volume', this.embedHost)
      .on('analytics', function(e, data) { volume.reportEvent(data); })
      .on('pauseOthers', function(e, data) { volume.postToAll('pause', null, e.source); })
      .on('host', function(evt, data) {
        return {
          url: location.href,
          origin: location.origin,
          referrer: document.referrer,
          title: document.title,
          api: true
        };
      })
      .listen();
  } else {
    // If a cross-frame messaging bus already exists,
    // then just update its host protocol with the latest Volume host setting.
    // This host setting specifies what domain the messenger is allowed to talk to.
    xframe.host = this.embedHost;
  }

  // Register all existing iframe embeds with viewport tracking:
  for (i=0; i < embeds.length; i+=1) {
    this.trackView(embeds[i]);
  }

  // Install new embeds into all Volume video placeholders:
  for (i=0; i < videos.length; i+=1) {
    this.embedVideo(videos[i]);
  }

  return this;
};

/**
* Uninstalls the Volume client API.
* Cancels listeners and stops polling requests.
*/
volume.uninstall = function() {
  if (xframe) {
    xframe.dispose();
    xframe = null;
  }
};

/**
* Report event data to analytics tracking.
* @param {Object} event data object to report.
*/
volume.reportEvent = function(eventData) {
  Analytics.event(eventData);
  this.log(eventData);
};

/**
* Logs debugging information into the console (when enabled).
* @param {String} message to pass through to the console.
*/
volume.log = function(mssg) {
  if (window.volume_debug) console.log('volume: ', mssg);
};

/**
* Embeds a Volume iframe into a placeholder element.
* @param {Element} placeholder element with "data-volume-uuid" field, and other metadata.
*/
volume.embedVideo = function(el, opts) {
  // Pull Volume UUID from the placeholder element; abort if we don't have one.
  const UUID = el.getAttribute('data-volume-uuid');
  if (!UUID) return;

  // Collect placeholder configuration attributes.
  opts = _.extend({
    autoplay: el.getAttribute('data-volume-autoplay'),
    autoplay_with_sound: el.getAttribute('data-volume-autoplay-with-sound'),
    placement: el.getAttribute('data-volume-placement'),
    player_type: el.getAttribute('data-volume-player-choice'),
    start_time: el.getAttribute('data-volume-start-time'),
    mask_text: el.getAttribute('data-mask-text'),
    tracking: getAnalyticsPlacement(el)
  }, opts || {});

  // Only provide privacy_consent param if privacy banner site config is enabled
  if (el.getAttribute('data-volume-privacy') === 'true') {
    // Get user's current privacy setting to determine embed video url
    // (Privacy settings accepted by Volume are either "essential" or "all")
    const currentPrivacySetting = currentContext.get('preferences').privacy.cookies;
    const privacyConsent = currentPrivacySetting === 'none' ? 'essential' : currentPrivacySetting;
    opts.privacy_consent = privacyConsent;
  }

  // Get a valid trigger attribute for the element:
  // trigger indicates when the embed gets added to the document.
  var TRIGGER_ATTR = 'data-volume-trigger';
  var trigger = el.getAttribute(TRIGGER_ATTR) || '';
  var autoplay = /^(true|1)$/.test(opts.autoplay);
  el.setAttribute(TRIGGER_ATTR, 'none');

  if (!/^(lazy|click|embed|none)$/.test(trigger)) {
    trigger = 'lazy';
  }

  this.log('embedVideo ['+ trigger +'] '+ UUID +'-'+ opts.tracking);

  if (trigger === 'lazy') {
    // Adjust tolerance to account for embeds of autoplay videos
    var tolerance = autoplay ? 0 : -0.5;

    // Lazy embed:
    // delay embedding until element enters the viewport.
    Viewport.trackElement(el, () => {
      el.setAttribute(TRIGGER_ATTR, 'embed');
      this.embedVideo(el);
    }, tolerance);
  } else if (trigger === 'click') {
    // Click embed:
    // delay embedding until placeholder is clicked.
    el.addEventListener('click', function onclick(evt) {
      evt.preventDefault();
      el.removeEventListener('click', onclick);
      el.setAttribute(TRIGGER_ATTR, 'embed');
      volume.embedVideo(el, { autoplay: true });
    });

    // track placeholder element as the viewport proxy.
    this.trackView(el, opts.tracking);
  } else if (trigger === 'embed') {
    // Active embed:
    // actually creates a new Volume iframe.
    var params = Object.keys(opts)
      .filter(function(key) { return !!opts[key]; })
      .map(function(key) { return key +'='+ opts[key]; });

    // Adjust placeholder classname, and embed the iframe content.
    el.className = el.className.replace(/volume-video|p-scalable-video/g, '') + ' p-scalable-video';
    el.innerHTML = '<iframe src="'+ this.embedHost +'/embed/'+ UUID +'?'+ params.join('&') +'" frameborder="0" allow="autoplay" allowfullscreen></iframe>' + this.contentMask(opts.mask_text);
    this.trackView(el, opts.tracking);
  }
};

/**
* Viewport tracking for a single iframe embed element.
* Sends a "view" message/event when the target element enters the viewport.
* Elements must be a Volume iframe or placeholder div.
* All view requests are indexed by SKU (stock-keeping unit),
* so that unique iframe/placeholder pairings are only tracked once.
* @param {Element} element (volume iframe or placeholder div) to track within viewport.
* @param {String} placement code indicating the element's position on the page.
* @param {Number} [volumeId] optional Volume ID associated with the video.
*/
volume.trackView = function(el, placement) {
  var trackedSKUs = this._sku = this._sku || {};
  placement = placement || getAnalyticsPlacement(el);

  // Acquire Volume iframes from provided element:
  // The iframe could be the element itself, or the wrapper.
  var uuidForIframe = /embed\/([^\/\?]+)/;
  var getEmbedIframe = function() {
    var iframeEl = /iframe/i.test(el.tagName) ? el : el.querySelector('iframe');
    return (iframeEl && uuidForIframe.test(iframeEl.src)) ? iframeEl : null;
  };

  var embedEl = getEmbedIframe();
  var uuid = embedEl ? embedEl.src.match(uuidForIframe)[1] : el.getAttribute('data-volume-uuid');
  var sku = uuid +'-'+ placement;
  this.log('trackView '+sku);

  // Manage tracking for unique stock keeping units:
  // This makes sure the same UUID/placement combo is only tracked once.
  // Pileups can occur if we have a placeholder and its embed both submitted for tracking.
  if (uuid && !trackedSKUs[sku]) {
    trackedSKUs[sku] = true;
    this.log('[tracking] '+sku);

    // Submit element for viewport tracking:
    Viewport.trackElement(el, function() {
      // Re-attempt to acquire iframe element once we're ready to report:
      // This assumes that a placeholder may not yet have had an iframe in it before.
      embedEl = embedEl || getEmbedIframe();

      if (embedEl) {
        // Report view via the volume iframe, if available.
        // Basically, we send a message to the iframe
        // telling it to tell us that it was viewed.
        // Crazy, but the advantage here is that the video
        // can report using its full Volume meta data.
        volume.log('[view] '+sku);
        setTimeout(function() {
          volume(embedEl).request('view', document.title);
        }, 100);
      } else {
        // Otherwise, report a generic event...
        // do we still want to do this?
        volume.log('[view fallback] '+sku);
        volume.reportEvent({
          eventCategory: 'video',
          eventAction: ['volume', 'view', placement].join(':'),
          eventLabel: [document.title, el.getAttribute('data-volume-id') || uuid].join(' | '),
          nonInteraction: true
        });
      }
    }, 0.1);
  }
};

/**
* Posts a message to all Volume iframes.
* Optionally allows for one origin source to be excluded.
* @param {String|Object} message to send all Volume iframes.
* @param {String} [value] to send along with the message.
* @param {Window} exclude window source; useful for proxying out messages from a sender.
*/
volume.postToAll = function(message, value, excludeSrc) {
  var embeds = this.selectAllEmbeds();

  for (var i=0; i < embeds.length; i++) {
    var src = xframe.src(embeds[i]);
    if (src !== excludeSrc) {
      xframe.post(src, message, value);
    }
  }
};

/**
* Sends a pause message to all Volume iframes.
*/
volume.stopAll = function() {
  this.postToAll('pause');
};

/**
* Generates a content mask to place over sensitive videos.
*/
volume.contentMask = function(maskText) {
  return maskText ? '<div class="c-image-mask" onclick="this.parentNode.removeChild(this);return false;"><div>'+ maskText +'</div><b>Tap to display</b></div>' : '';
};

/**
* Volume Client Object
* Creates a wrapper around a embedded content frame,
* and handles all cross-frame communication with that embed.
* @param {String|Element|Window} target selector or element of the iframe/window to harness.
* @constructor
*/
function VolumeClient(target) {
  if (!xframe) throw 'volume is not installed';
  this.el = xframe.src(target);
}

VolumeClient.prototype = {
  /**
  * Sends a "play" message to the video client.
  * @param {Object} options; include "request: true" for message confirmation.
  * volume('#my-embed').play();
  * volume('#my-embed').play({ request: true });
  */
  play: function(opts) {
    const PLAY = 'play';
    return (opts && opts.request) ? this.request(PLAY) : this.post(PLAY);
  },

  /**
  * Sends a "pause" message to the video client.
  * @param {Object} options; include "request: true" for message confirmation.
  * @example
  * volume('#my-embed').pause();
  * volume('#my-embed').pause({ request: true });
  */
  pause: function(opts) {
    const PAUSE = 'pause';
    return (opts && opts.request) ? this.request(PAUSE) : this.post(PAUSE);
  },

  /**
  * Posts a message and value to the video client.
  * This is a blind post without guarentee that the message will go through
  * (messages may fail if the client frame is not yet fully initialized).
  * Use `request` to post a message with confirmation of receipt.
  * @param {String|Object} message string or formatted object to send.
  * Passing an object requires that a "message" key be provided.
  * @param {Any} [value] an optional value to send with the message.
  * @returns {VolumeClient} self reference.
  * @example
  * volume('#my-embed').post('hello');
  * volume('#my-embed').post('hello', 'world');
  * volume('#my-embed').post({ message: 'hello', data: true });
  */
  post: function(message, value) {
    if (xframe) xframe.post(this.el, message, value);
    return this;
  },

  /**
  * Posts a message to a video client with request for confirmation.
  * This implementation polls the client frame with the posted message
  * until the frame sends back a confirmation response.
  * Use this method to (better) guarentee delivery.
  * @param {String} message string to send.
  * @param {Any} [value] an optional value to send with the message.
  * @param {Function} callback function to handle response.
  * @returns {VolumeClient} self reference.
  */
  request: function(message, value, callback) {
    if (xframe) xframe.request(this.el, message, value, callback);
    return this;
  }
};

export default volume;
