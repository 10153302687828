/* global google:false */
import Backbone from 'exoskeleton';
import googleMapsLoader from 'lib/google_maps';
import googleMapsMarker from 'lib/google_maps_marker';
import _ from 'lib/utils';

let SimpleMap = Backbone.View.extend({
  initialize: function(opts) {
    var self = this;
    self.opts = opts;
    opts = _.extend({ lat: 0, lon: 0, slug: '', label: '', cssClass: 'c-simple-map__marker' }, opts);
    googleMapsLoader().then(() => {
      // Configure default starting center:
      // Create Google Map:
      this.map = new google.maps.Map(this.el, {
        center: new google.maps.LatLng(opts.lat, opts.lon),
        mapTypeControl: false,
        panControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoom: 14,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      });
      // Create MapMarkerView factory object:
      var marker = googleMapsMarker(opts);
      this.frame = marker;
      marker.setMap(this.map);
    });
  }
});
export default SimpleMap;
