import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import { currentUser } from 'models/context';
import Notice from 'lib/notification';

let EntryStatView = Backbone.View.extend({
  initialize: function() {
    this.listenTo(this.model, 'change', this.render);
  },

  render: function() {
    if (this.model.hasChanged('recommended_by_user') || this.model.hasChanged('recommended_count')) {
      this.renderRecs();
    }

    if (this.model.hasChanged('comment_unreads') || this.model.hasChanged('comment_count')) {
      this.renderComments();
    }
  },

  renderRecs: function() {
    var recd = this.model.isRecommendedByUser();
    var totalRecs = this.model.get('recommended_count');
    var $label = this.$('[data-ui="rec-data"]');

    $label.text(totalRecs);
    this.$('[data-ui="rec"]')
      .toggleClass('is-recced', recd)
      .toggleClass('has-content', !!totalRecs && !recd);
  },

  renderComments: function() {
    var unreadComments = this.model.get('comment_unreads');
    var commentCount = this.model.get('comment_count');
    var $label = this.$('[data-ui="comment-data"]');

    this.$('[data-ui="comment"]')
      .toggleClass('has-unread', !!unreadComments)
      .toggleClass('has-content', !!commentCount && !unreadComments);
    $label.text(commentCount);

    var $unread = this.$('[data-ui="unread"]');

    if ($unread) {
      $unread
        .toggle(unreadComments > 0)
        .find('[data-ui="unread-data"]')
        .text(unreadComments);
    }
  },

  events: {
    'click [data-ui="rec"]': 'onRec'
  },

  onRec: function() {
    currentUser.fetch().then(() => {
      if (currentUser.isLoggedIn()) {
        var rec = !this.model.isRecommendedByUser();
        this.model.recommend(rec)
          .done(() => { if (rec) Notice.success('Recommendation added.'); })
          .fail((mssg) => { Notice.error(mssg); });
      } else {
        Initializer.get('apps/auth').open();
      }
    });
  }
});

export default EntryStatView;
