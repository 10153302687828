var candidates = [];

// pass in a string of selectors to be balanced.
// if you didnt specify any, thats ok! We'll just
// balance anything with the balance-text class
var TextBalancer = function (selectors, fraction=1.0) {

  if (!selectors) {
    candidates = document.querySelectorAll('.balance-text');
  } else {
    createSelectors(selectors);
  }
  fraction = +fraction;
  if ((typeof fraction) != 'number' || isNaN(fraction)) {
    fraction = 1.0;
  } else {
    fraction = Math.max(0.0, Math.min(1.0, fraction));
    if (fraction == 0.0) return;
  }

  balanceText(fraction);

  var rebalanceText = debounce(function() {
    balanceText(fraction);
  }, 100);

  window.addEventListener('resize', rebalanceText);
};

// this populates our candidates array with dom objects
// that need to be balanced
var createSelectors = function(selectors) {
  var selectorArray = selectors.split(',');
  for (var i = 0; i < selectorArray.length; i += 1) {
    var currentSelectorElements = document.querySelectorAll(selectorArray[i].trim());

    for (var j = 0; j < currentSelectorElements.length; j += 1) {
      var currentSelectorElement = currentSelectorElements[j];
      candidates.push(currentSelectorElement);
    }
  }
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
var debounce = function (func, wait, immediate) {
  var timeout;
  return function() {
    var self = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(self, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(self, args);
  };
};


// HELPER FUNCTION -- initializes recursive binary search
var balanceText = function (fraction) {
  var element;
  var i;

  for (i = 0; i < candidates.length; i += 1) {
    element = candidates[i];

    // For now, this library assumes it is the keeper of all maxWidth
    // values in the Chorus DOM. If this element has a computed style
    // of 'center' (or anything besides 'start' aka left), that must
    // be because the window was shrunk and we responsively made it
    // left-justified and this library assigned it a maxWidth. If we
    // expand the window again, we need to reevaluate all maxWidths.
    // We start by erasing our previous work.
    element.style.maxWidth = '';

    // TODO figure out if there's a better cross-platform way to determine this
    var computedStyle = getComputedStyle(element, null);
    if (computedStyle.textAlign === 'start') {
      // In an n-line element, at most roughly 1/n'th of the horizontal width
      // can be "visually recoverable," so half is our starting minimum
      // (minus a fudge factor, with a minimum of 50).
      var bottomRange = Math.max(50, element.offsetWidth / 2 - 10);
      squeezeContainerLeft(element, element.clientHeight, element.offsetWidth, bottomRange, element.clientWidth, fraction);
    } else {
      // TODO write a squeezeContainerCenter
      // console.log("computed-textAlign=" + computedStyle.textAlign + " for: " + element.innerText);
    }
  }

};

// Make the element as narrow as possible while maintaining its current height (number of lines). Binary search.
var squeezeContainerLeft = function (element, originalHeight, originalWidth, bottomRange, topRange, fraction) {

  // Squeeze headlines that wrap left by leaving the left side where it is
  // and reducing maxWidth.

  // If we get within 5 pixels that's close enough, we don't need to
  // do the last 2 iterations. Call topRange the new minimum width.
  if (bottomRange + 5 >= topRange) {
    element.style.maxWidth = Math.round(originalWidth - fraction*(originalWidth-topRange)).toString() + 'px';
    return;
  }

  // Otherwise, pick the midpoint (maybe fractional) and squeeze to that size.

  var mid = (bottomRange + topRange) / 2;
  element.style.maxWidth = mid + 'px';

  if (element.clientHeight > originalHeight) {
    // we've squoze too far and element has spilled onto an additional line; recurse on wider range
    squeezeContainerLeft(element, originalHeight, originalWidth, mid+1, topRange, fraction);
  } else {
    // element has not wrapped to another line; keep squeezing!
    squeezeContainerLeft(element, originalHeight, originalWidth, bottomRange, mid, fraction);
  }

};

export default TextBalancer;
