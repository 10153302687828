import currentContext from 'models/context';
import Initializer from 'lib/initializer';
import notice from 'lib/notification';
import analytics from 'lib/analytics';
import video from 'lib/video';
import $ from 'jquery';

/**
* Post-consent / No-consent workflow
* This launches the main application services.
*/
export function runStartup() {
  if (!currentContext.get('privacy')) {
    // Resolve initializer consent hooks ––
    // this queues any tasks that were waiting on consent.
    Initializer.consent();

    // Configure messaging hooks. Changes to contexe message will notify.
    function notify() { notice.notify(currentContext.get('messaging')); }
    currentContext.on('change:messaging', notify);
    notify();

    // Fetch context, then install videos and run analytics:
    currentContext.fetch().then(function() {
      video.install(currentContext.get('volume').embed_host);
      analytics.sendContext();
    });
  }
}

/**
* Script initialization queue, ie: "chorusInitQueue"
* This runs an array of callbacks from inline scripts on the page.
* Allows editorial script tags to access our asynchronously-loaded
* script libraries, most notably jQuery.
*/
export function runInitQueue() {
  var chorusInitQueue = window.chorusInitQueue = window.chorusInitQueue || [];

  function runCallback(fn) {
    if (typeof fn === 'function') {
      try {
        fn.call(null, $);
      } catch (e) {
        console.error('chorusInitQueue: error processing command :' + e.message);
      }
    }
  }

  chorusInitQueue.forEach(runCallback);
  chorusInitQueue.push = runCallback;
}
