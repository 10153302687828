import '../../site/dynamic_size_image';
import '../../site/video_embed';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import UserAgent from 'lib/user_agent';

Initializer.registerComponent('entry_boxes/base/video_entry', function(element, data) {
  if (UserAgent.isMobile()) {
    var imageWrapper = $(element).find('.c-entry-box-base__image');
    imageWrapper.removeAttr('src');
    Initializer.get('site/video_embed').embed(imageWrapper[0], data);
  } else {
    $(element).on('click', '.c-entry-box-base__image', (evt) => {
      evt.preventDefault();
      Initializer.get('site/video_embed').modal(data);
    });
  }
}, { waitForConsent: true });

