import Backbone from 'exoskeleton';
import $ from 'jquery';

let SportsBlogDirectoryView = Backbone.View.extend({
  currentTopic: null,

  initialize: function() {
    this.$headers = this.$('.c-sports-blog-directory__main').find('h2, h3, h4');
    this.$entries = this.$('.c-sports-blog-directory__item');
    this.$filter = this.$('.c-filter-list');
    this.$textInput = this.$('.c-filter-list__search-input');
  },

  handleFilterChange : function(event, slug) {
    var val = slug.replace('#', '');
    this.$textInput.val('');
    if (val === 'all') {
      this.showAll();
    } else {
      this.filterByTopic(val);
    }
  },

  handleInputTextChange: function(evt) {
    var val = this.$textInput.val();
    if (val.length < 3) {
      if (this.currentTopic && this.currentTopic != 'all') {
        this.filterByTopic(this.currentTopic);
      } else {
        this.showAll();
      }
    } else {
      this.filterByText(val);
    }
  },

  showAll: function() {
    this.$filter.removeClass('disabled');
    this.$headers.show();
    this.$entries.show();
  },

  filterByTopic: function(topic) {
    this.$filter.removeClass('disabled');
    this.$headers.hide();
    this.$entries.hide();
    this.currentTopic = topic;
    this.$('#' + topic).show().nextUntil('h2').show();
  },

  filterByText: function(searchText) {
    this.$headers.hide();
    this.$filter.addClass('disabled');
    this.$entries.each(function(idx, el) {
      var isFeatured = el.hasAttribute('data-featured');
      var isTextMatch = el.innerText.toUpperCase().indexOf(searchText.toUpperCase()) >= 0;
      $(el).toggle(isTextMatch && !isFeatured);
    });
  },

  events: {
    'filter .c-filter-list': 'handleFilterChange',
    'keyup .c-filter-list__search-input' : 'handleInputTextChange'
  }

});
export default SportsBlogDirectoryView;


