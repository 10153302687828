import $ from 'jquery';

var recaptchaDeferred;

window.__handleRecaptchaLoad = function() {
  var nodeList = document.querySelectorAll('.g-recaptcha');
  for (var i = 0; i < nodeList.length; i++) {
    window.grecaptcha.render(nodeList[i], { sitekey : nodeList[i].getAttribute('data-sitekey') });
  }
  recaptchaDeferred.resolve();
};

export default function() {
  if (!recaptchaDeferred) {
    recaptchaDeferred = $.Deferred();
    $.getScript('https://www.google.com/recaptcha/api.js?onload=__handleRecaptchaLoad&render=explicit');
  }
  return recaptchaDeferred.promise();
}
