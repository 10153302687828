/* global fourSq: false */
import Backbone from 'exoskeleton';
import foursquareWidgetLoader from 'lib/foursquare_widget';

let FoursquareButton = Backbone.View.extend({
  initialize: function() {
    foursquareWidgetLoader().then(()=>{
      var widget = new fourSq.widget.SaveTo({ 'vid': this.$el.data('foursquare-id') });
      widget.attach(this.el);
      this.$el.on('click', function(e) {
        e.preventDefault();
      });
    });
  }
});
export default FoursquareButton;
