import Backbone from 'exoskeleton';
import $ from 'jquery';
import UserAgent from 'lib/user_agent';
import Analytics from 'lib/analytics';
import { currentUser } from 'models/context';

var sharedTO = null;
const MOBILE_BREAK = 600;

let NavListView = Backbone.View.extend({
  $body : $('body'),

  events: {
    'click [data-ui="close-nav"]' : 'closeLists',
    'click a': 'trackClick'
  },

  closeLists : function() {
    this.handleListLeave();
    this.detachLists();
  },

  handleTriggerClick : function(evt) {
    evt.preventDefault();
    var trigger = $(evt.currentTarget);
    if (trigger.hasClass('is-attached')) {
      this.closeLists();
    } else {
      this.handleTriggerEnter(evt, true);
    }
  },

  handleTriggerEnter : function(evt, viaClick = false) {
    evt.preventDefault();
    clearTimeout(sharedTO);
    var ct = $(evt.currentTarget);

    if (!viaClick && ct.data('nav-list-trigger') === 'more' && ct.hasClass('use-fixed-dropdown')) {
      return;
    }

    this.attachList(ct.data('nav-list-trigger'), ct, viaClick || UserAgent.isTouch());
  },

  /**
  When we have nested lists inside of the MORE menu
  We want to have them collapsed until they are clicked
  **/
  handleNavListClick : function(evt) {
    evt.preventDefault();
    var tabId = evt.data.tabId;
    var element = this.$body.find('[data-nav-list-id=' + tabId + ']');
    element.toggleClass('show-sub-items');
  },

  handleListEnter : function() {
    clearTimeout(sharedTO);
  },

  handleListLeave : function(evt) {
    if (evt) {
      var ct = $(evt.currentTarget);
      if (ct.data('nav-list-trigger') === 'more' && ct.hasClass('use-fixed-dropdown')) {
        return;
      }
    }

    if (!UserAgent.isSmallWindow(MOBILE_BREAK)) {
      // OI this is a tough one, sometimes we have nested
      // lists, so we need to make sure that there's only
      // one of these guys out there before we set another one
      clearTimeout(sharedTO);
      sharedTO = setTimeout($.proxy(this.detachLists, this), 200);
    }
  },

  handleStumpEnter: function() {
    clearTimeout(sharedTO);
    this.detachLists();
  },

  trackClick: function(evt) {
    Analytics.nav('click', evt.currentTarget.innerText.trim());
  },

  /**
  * Open the list by adding the class
  * @param the relevant listId as a string
  * @ param the relevant trigger element
  */
  attachList: function(listId, triggerElement, viaInteraction) {
    // make sure all lists are detached before we add another one
    this.detachLists();
    var os = triggerElement.offset();
    this.$el.addClass('is-active');
    triggerElement.addClass('is-attached');
    triggerElement.height();
    // check to see if list is the MORE menu
    if(listId === 'more') {
      this.$el.addClass('show-more-menu');
      var attached = this.$el.find('.c-nav-list__main');
    } else {
      this.$el.removeClass('show-more-menu');
      attached = this.$el.find('[data-nav-list-id=' + listId +']')
        .addBack().filter('[data-nav-list-id=' + listId +']')
        .find('> .c-nav-list__inner > .c-nav-list__sub-items, > .c-nav-list__sub-items').first();
    }

    attached.addClass('is-attached');
    var useFixedMoreList = $('.c-nav-list').hasClass('show-more-menu') && $('.c-nav-list').hasClass('use-fixed-dropdown');

    if(window.innerWidth >= MOBILE_BREAK && !useFixedMoreList) {
      this.$el.find('.c-nav-list__inner').css({ 'top' : (os.top  + triggerElement.height())+ 'px', 'left': 'auto', 'right' : 'auto' });
      this.$el.find('.c-nav-list__main').off('mouseleave');
      this.$el.find('.c-nav-list__main').on('mouseleave', $.proxy(this.handleListLeave, this));
      if (os.left + attached.width() >= window.innerWidth) {
        attached.css({ 'right': (this.$body.width() - (os.left + triggerElement.outerWidth())) + 'px', 'left' : 'auto' });
      } else {
        attached.css({ 'left': os.left + 'px', 'right' : 'auto' });
      }
    } else if (window.innerWidth >= MOBILE_BREAK && useFixedMoreList) {
      var mo = $('.c-masthead').offset();
      this.$el.find('.c-nav-list__main').off('mouseleave');
      this.$el.find('.c-nav-list__inner').css({ 'top' : ((mo ? mo.top : os.top) - $(window).scrollTop()) + 'px', 'right' : '0', 'left': 'auto' });
    } else {
      this.$el.find('.c-nav-list__inner').css({ 'top' : (os.top + triggerElement.height() - this.$body.scrollTop())+ 'px', 'left': 'auto', 'right' : 'auto' });
    }

    if(window.innerWidth <= MOBILE_BREAK || useFixedMoreList) {
      $('html').addClass('is-nav-list-active');
    }

    if(window.innerWidth >= MOBILE_BREAK) {
      this.$body.off('click.navList').on('click.navList', (evt) => {
        var $clicked = $(evt.target);
        if (!$clicked.closest('.c-nav-list__inner').length && !$clicked.is('.c-nav-list__inner')) {
          evt.preventDefault();
          this.closeLists();
        }
      });
    }

    // Trigger "expand" event...
    // Final argument is the event `nonInteraction` report,
    // which should be true when this event occurs due to mouse-hover
    // (we make this distinction because mouse-hover events are not considered deliberate).
    Analytics.nav('expand', triggerElement.text().trim(), !viaInteraction);
  },

  /**
  * Removes all attached menus
  */
  detachLists: function() {
    this.$body.find('[data-nav-list-trigger].is-attached, [data-nav-list-id].is-attached, [data-nav-list-id] .is-attached').removeClass('is-attached').removeAttr('style');
    this.$body.find('.c-nav-list').removeClass('is-active').removeClass('show-more-menu');
    this.$el.find('[data-nav-list-id] .is-active').removeClass('is-active');
    $('html').removeClass('is-nav-list-active');
    this.$body.off('click.navList');
  },

  listenFor : function(tabId) {
    this.$body.on('click', '*[data-nav-list-trigger=' + tabId + ']', $.proxy(this.handleTriggerClick, this));
    this.$body.on('click', '.c-nav-list__main [data-nav-list-id=' + tabId + '] .c-nav-list__label', { tabId : tabId }, $.proxy(this.handleNavListClick, this));
    if(!UserAgent.isTouch() && !(tabId === 'more' && $('.c-nav-list').hasClass('use-fixed-dropdown'))) {
      this.$body.on('mouseenter.navTrigger', '*[data-nav-list-trigger=' + tabId + ']', $.proxy(this.handleTriggerEnter, this));
      this.$body.on('mouseleave.navTrigger', '*[data-nav-list-trigger=' + tabId + ']', $.proxy(this.handleListLeave, this));
    }
  },

  updateLoginLinks : function() {
    var updateLinks = this.$('a[data-update-for-logged-in]');
    if (updateLinks.length > 0) {
      currentUser.fetch().then(() => {
        if (currentUser.isLoggedIn()) {
          updateLinks.each(function () {
            var link = $(this);
            link.attr('href', link.attr('data-update-for-logged-in'));
          });
        }
      });
    }
  },

  initialize: function(opts) {
    this.data = opts.data;
    // page-wide listeners.
    this.$el.find('*[data-nav-list-id]').each((idx, item) => {
      this.listenFor(item.getAttribute('data-nav-list-id'));
    });
    this.$body.find('.has-no-sublist a').on('mouseenter focus', $.proxy(this.handleStumpEnter, this));
    this.$el.find('.c-nav-list__main').on('mouseenter', $.proxy(this.handleListEnter, this));
    this.$el.find('.c-nav-list__main').on('mouseleave', $.proxy(this.handleListLeave, this));
    this.updateLoginLinks();
  }

});

export default NavListView;
