import $ from 'jquery';
import { currentUser } from 'models/context';
import _ from 'lib/utils';
import UserAgent from 'lib/user_agent';
import viewport from 'lib/viewport';
import time from 'lib/time';
import { onCLS, onFID, onLCP, onTTFB, onINP } from 'web-vitals';

// Ensure Chorus namespace is available
window.Chorus = window.Chorus || {};

/**
* <p>Analytics wrapper, designed to interface with the Google Tag Manager (GTM) DataLayer.
* This is the primary touchpoint of all analytics reporting within the Presto frontend.
* To contextualize this library, it helps to have some understanding of GTM and its DataLayer:</p>
* <ul>
*  <li>GTM provides a <code>dataLayer</code>, which is simply an array for dumping message objects.</li>
*  <li>On the FE, we're only concerned with getting messages INTO the <code>dataLayer</code> array.</li>
*  <li>In GTM, we configure client scripts that sift through the <code>dataLayer</code> and report relevant messages.</li>
* </ul>
* <p>So, GTM defers the process of reporting analytics to the backend.
* This library's goal is to simply push well-formed messaged onto the <code>dataLayer</code> for collection.</p>
* @example
* import Analytics from 'lib/analytics';
*
* Analytics.event('category', 'action', 'label', 23, {nonInteraction: true}, function() { ... });
* Analytics.eventAutoFormat('category', 'name', {nonInteraction: true});
* @module lib/analytics
*/

/**
* Analytics wrapper.
* @constructor
*/
function Analytics(dataLayerNS='dataLayer') {
  // Ensure analytics client is a singleton so it doesn't send duplicate events.
  if(window.Chorus._analyticsInstance) {
    return window.Chorus._analyticsInstance;
  }
  window.Chorus._analyticsInstance = this;

  this.dataLayer = window[dataLayerNS] || [];
  this.depths = [25, 50, 75, 90];

  this.onReady(() => {
    this.trackImpressions();
    this.trackScrollDepth(true);
    this.trackCWVStats();
  });
}

Analytics.prototype = {
  /**
  * Document ready handler (exposed for testing).
  * @private
  */
  onReady: $,

  /**
   * Pushes a new message onto the analytics dataLayer.
   * @param {Object} message object to push onto the data layer.
   * @returns {undefined}
   */
  push: function(mssg) {
    this.dataLayer.push(mssg);
  },

  /**
  * Analytics Event object formatting helper.
  * This helper transforms raw arguments into a normalized Event object schema.
  * The formatted event object is returned WITHOUT being reported.
  * @function
  * @param {...Any} parameters used to build an event object.
  * Positional arguments will automatically be mapped to field names.
  * @returns {Object} a formatted event object for use with GTM.
  */
  formatEvent: analyticsFormatter('event', 'analyticsEvent', [
    'eventCategory',
    'eventAction',
    'eventLabel',
    'eventValue',
    'eventNonInt'
  ], {
    'nonInteraction': 'eventNonInt',
    'hitCallback': 'eventCallback'
  }),

  /**
  * Analytics Social object formatting helper.
  * This helper transforms raw arguments into a normalized Social object schema.
  * The formatted social object is returned WITHOUT being reported.
  * @function
  * @param {...Any} parameters used to build a social object.
  * Positional arguments will automatically be mapped to field names.
  * @returns {Object} a formatted social object for use with GTM.
  */
  formatSocial: analyticsFormatter('social', 'social', [
    'socialNetwork',
    'socialAction',
    'socialTarget',
    'socialPagePath'
  ], {
    'page': 'socialPagePath',
    'hitCallback': 'eventCallback'
  }),

  /**
  * Analytics PageView object formatting helper.
  * This helper transforms raw arguments into a normalized PageView object schema.
  * The formatted pageview object is returned WITHOUT being reported.
  * @function
  * @param {...Any} parameters used to build a pageview object.
  * Positional arguments will automatically be mapped to field names.
  * @returns {Object} a formatted pageview object for use with GTM.
  */
  formatPageview: analyticsFormatter('pageview', 'virtualPageView', [
    'virtualPagePath',
    'virtualPageTitle'
  ], {
    'page': 'virtualPagePath',
    'title': 'virtualPageTitle',
    'hitCallback': 'eventCallback'
  }),

  /**
  * Analytics Event reporter.
  * Transforms raw arguments into a normalized message schema,
  * and then pushes the event message onto the data layer.
  * Accepts analytics arguments to be formatted and sent to GTM.
  * @param {...Any} parameters used to build a pageview object.
  */
  event: function() {
    this.push(this.formatEvent.apply(this, arguments));
  },

  /**
  * Analytics Social reporter.
  * Transforms raw arguments into a normalized message schema,
  * and then pushes the social message onto the data layer.
  * Accepts analytics arguments to be formatted and sent to GTM.
  * @param {...Any} parameters used to build a social object.
  */
  social: function() {
    this.push(this.formatSocial.apply(this, arguments));
  },

  /**
  * Analytics Pageview reporter.
  * Transforms raw arguments into a normalized message schema,
  * and then pushes the pageview message onto the data layer.
  * Accepts analytics arguments to be formatted and sent to GTM.
  * @param {...Any} parameters used to build a pageview object.
  */
  pageview: function() {
    this.push(this.formatPageview.apply(this, arguments));
  },

  /**
  * Primary site navigation reporter.
  * Use this for *sanctioned* primary site navigation behavior
  * (which gets tracked within a Unison site's "navigation" event category).
  * DO NOT USE THIS for one-off navigation schemes; those can just use `.event()`.
  * If you're not sure if your nav is a primary site navigation pattern,
  * then check with the analytics team before using this reporter.
  * @param {String} action keyword to report (only pass base actions such as "click" or "expand").
  * @param {String} label of the corresponding element (generally the triggering element's text).
  * @param {Boolean} [non-interaction] pass "true" when event is triggered by a non-touch mouseover.
  */
  nav: function(action, label, nonInteraction) {
    action = [action, this.contentType(), this.contentFormat()].join(':');
    this.push(this.formatEvent('navigation', action, label, nonInteraction));
  },

  /**
   * Shorthand for sending an event with only a "category" and an "action".
   * Will automatically format the event as:
   * <ul>
   *   <li>"eventCategory": "category"</li>
   *   <li>"eventAction": "category:name"</li>
   *   <li>"eventLabel": "category:content_type:name"</li>
   * </ul>
   * Use this method for simplicity when possible.
   * @param {String} category name for the event.
   * @param {String} name used to identify the event.
   * @param {Boolean} non-interaction pass true to mark the event as a non-user action.
   * @returns {Object} the formatted object sent to the dataLayer.
   */
  eventAutoFormat: function(category, name, nonInteraction) {
    var action = [category, name].join(':');
    var label = [category, this.contentType(), name].join(':');
    return this.event(category, action, label, nonInteraction);
  },

  /**
   * Configures an element to be tracked within the viewport.
   * The element is passed to GTM upon entering view.
   * @param {Element|String} element selector to track.
   */
  trackElementView: function(els, scope) {
    // Impression tracking to be disabled via query param for performance tests.
    if (/analytics-viewport=false/.test(location.search)) return;

    if (typeof els === 'string') {
      els = [].slice.call((scope || document).querySelectorAll(els));
    } else if (!Array.isArray(els)) {
      els = [els];
    }

    // Filter out "video" impression requests.
    // Unison's video library performs its own (more sophisticated) reporting,
    // and we don't want these elements to be tracked more than once.
    els
      .filter(el => el.getAttribute('data-analytics-viewport') !== 'video')
      .forEach(el => {
        if (!el.__analytics_vpt__) {
          el.__analytics_vpt__ = true;
          viewport.trackElement(el, el => this.push({ event: 'elementView', 'gtm.element': el }, 0));
        }
      });
  },

  /**
   * Configures impression tracking (`data-analytics-viewport` reports) for a scope.
   * Calling without a scope element will query the entire document for these attributes.
   */
  trackImpressions: function(scope) {
    this.trackElementView('[data-analytics-viewport]', scope, 0);
  },

  /**
  * Toggles scroll depth tracking.
  * @param {Object|Boolean} enabled status of scroll depth tracker.
  * Pass <code>{disable: true}</code> to fully disable for the page.
  * @example
  * analytics.trackScrollDepth(true);
  * analytics.trackScrollDepth({disable: true});
  */
  trackScrollDepth: function(toggle) {
    if (toggle && toggle.hasOwnProperty('disable')) {
      this._noSD = toggle.disable;
    }

    if (this._noSD) {
      toggle = false;
    }

    const EVENT_NS = 'scroll.scrolldepth';
    var $win = $(window).off(EVENT_NS);

    if (toggle) {
      $win.on(EVENT_NS, _.throttle(this._depth, 100, this));
      this._depth(true);
    }
  },

  /**
  * Sends CWV data to GA see https://www.npmjs.com/package/web-vitals
  * @private
  */
  trackCWVStats : function() {
    onCLS((opts) => {
      opts.delta = Math.round(opts.delta  * 1000);
      this.sendCWVData(opts);
    });

    onFID((opts) => {
      opts.delta = Math.round(opts.delta);
      this.sendCWVData(opts);
    });

    onLCP((opts) => {
      opts.delta = Math.round(opts.delta);
      this.sendCWVData(opts);
    });

    onTTFB((opts) => {
      opts.delta = Math.round(opts.delta);
      this.sendCWVData(opts);
    });

    onINP((opts) => {
      opts.delta = Math.round(opts.delta);
      this.sendCWVData(opts);
    });
  },

  sendCWVData: function(opts) {
    this.event({
      event: 'web-vitals',
      event_action: opts.name,      eventAction: opts.name,
      event_category: 'Web Vitals', eventCategory: 'Web Vitals',
      event_label: opts.id,         eventLabel: opts.id,
      event_value: opts.delta,      eventValue: opts.delta,
      nonInteraction: true,         transport: 'beacon'
    });
    if (_.urlParams().debugCWVReporting) {
      console.log(`${opts.name} matching ID ${opts.id} changed by ${opts.delta}`);
    }
  },
  /**
  * Reports on the current scroll depth.
  * @param {Boolean} [initialReport] reports only once on the maximum threshold passed.
  * @param {Number} [scrollY] optional argument for static testing.
  * @param {Number} [windowH] optional argument for static testing.
  * @param {Number} [pageH] optional argument for static testing.
  * @private
  */
  _depth: function(initialReport, windowH, pageH, scrollY) {
    var depths = this.depths;
    scrollY = scrollY || window.pageYOffset;
    windowH = windowH || window.innerHeight;
    pageH = pageH || document.body.scrollHeight;

    // Calculate the top and bottom percentages of the viewport:
    var top = (scrollY / pageH) * 100;
    var bottom = ((scrollY + windowH) / pageH) * 100;

    // Loop backward so that we can safely remove tracked values...
    for (var i = depths.length-1; i >= 0; i--) {
      var value = depths[i];

      // Track value thresholds when present within the viewport range:
      // During the initial report, just capture how deep we're starting.
      if ((value >= top && value <= bottom) || (initialReport && value <= bottom)) {
        this.eventAutoFormat('interaction', value, true);
        depths.splice(i, 1);

        // Stop after first reported depth during initial report:
        // we only want to know how deep the user started up front.
        if (initialReport) return;
      }
    }

    // Disable tracking when we've exhausted all depths to report at:
    if (!depths.length) {
      this.trackScrollDepth(false);
    }
  },

  /**
   * Gets a variable from the Data Layer.
   * Performs a reverse search, looking for the newest variable instance.
   * @param {String} attribute name to find.
   * @param {Object} [default] value to return if attribute is not found.
   */
  getVariable: function(attribute) {
    var dl = this.dataLayer;
    for (var i = dl.length-1; i >= 0; i--) {
      if (dl[i].hasOwnProperty(attribute)) return dl[i][attribute];
    }
    return undefined;
  },

  /**
  * Gets the "Content Type" variable.
  * Content Type is written into the page data layer by the Chorus backend.
  * @returns {String} content type data layer variable set by Chorus.
  */
  contentType: function() {
    return this.getVariable('Content Type') || 'other';
  },

  /**
   * Gets a content format assessment (mobile versus desktop).
   * NOTE: this implementation matches the Unison GTM Container implementation.
   * DO NOT CHANGE THIS without consulting with the analytics team.
   * @returns {String} a keywork string describing the (subjective) content display format.
   */
  contentFormat: function() {
    var breakpoint = UserAgent.breakpoint();
    var isSmall = /small|medium/.test(breakpoint) || UserAgent.isSmallWindow(600);
    return isSmall ? 'mobile' : 'desktop';
  },

  /**
   * Fetches and submits async context data.
   */
  sendContext: function() {
    currentUser.fetch().then(() => {
      var EST = time.server(time.EST);
      this.push({
        'Hour of Day': EST.getHours(),
        'Day of Week': time.nameOfDay(EST.getDay()).toLowerCase(),
        'Logged in Status': currentUser.get('id') || 'Logged Out',
        'Breakpoint': UserAgent.breakpoint()
      });
    });
  }
};

export default new Analytics();
export const constructor = Analytics;

/**
 * Creates a formatter function to build positional arguments into event objects.
 * This is a brute-force normalization of anything that you can throw at analytics.
 * @param {String} event name assigned to the formatted analytics data.
 * @param {String[]} fields names arranged by call position.
 * @param {Object} mapping hash defining fields to rename.
 * @return {Function} a GTM formatter function that accepts analytics arguments,
 * and returns a formatted analytics event object.
 * @private
 */
function analyticsFormatter(uaAction, eventName, fields, uaMapping={}) {
  return function(...args) {
    // Sanitize arguments array, removing all GA boilerplate from initial arguments:
    // this will take out "send", "event", etc, from the front of the array...
    if (args[0] instanceof Array) args = args[0];
    if (typeof args[0] === 'string' && args[0].match(/send|_track/)) args.shift();
    if (args[0] === uaAction.toLowerCase()) args.shift();

    var position = 0;
    var evt = {};

    // Map passed positional arguments into named event keys:
    // this loop runs while we have arguments OR named fields left to fill.
    for (var i=0; i < args.length || position < fields.length; i++) {
      var value = args[i];

      // Formatting rules:
      // - Any function param is assigned as the event callback.
      // - Any boolean param is assigned as the non-interaction flag.
      // - Any object params are extended onto the event object.
      // --> (a mapping table may specify the renaming of legacy field names).
      // - Otherwise, params are positionally mapped into named fields.
      if (typeof value === 'function') {
        // Set any passed function as the event callback:
        evt.eventCallback = value;
      } else if (typeof value === 'boolean') {
        // Set any boolean as a non-interaction flag:
        if (value) evt.eventNonInt = value;
      } else if (typeof value === 'object') {
        // Extend any passed objects onto the event:
        // Object keys are subject to field renaming.
        // Ex: "hitCallback" -> "eventCallback"
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            evt[uaMapping[key] || key] = value[key];
          }
        }
      } else if (fields[position]) {
        // Assign everything else to a positional label:
        var field = fields[position++];
        if (!evt.hasOwnProperty(field)) evt[field] = value;
      }
    }

    // Set the definitive event name:
    evt.event = eventName;
    return evt;
  };
}
