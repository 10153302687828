/**
* The DynamicImages model manages the loading of dynamically-sized image sources.
* These images select their source URL based on presentation size and device pixel ratio.
* This service accepts elements to load images for,
* OR rectangle dimensions to acquire image sources for.
*
* <p><b>Lifecycle:</b><br>
* <ol>
* <li>Elements are added to the DynamicImages collection, and their display size is calculated.</li>
* <li>Upon adding an image, a new debounced fetch cycle kicks off.</li>
* <li>At the end of each fetch cycle, sized URLs are fetched by the collection for all pending images.</li>
* <li>These bulk-loaded URLs are parsed into their individual image models.</li>
* <li>Image models automatically populate their bound element with image data, when appropriate.</li>
* </ol>
*
* <p>In addition to handling bulk-fetching of sized URLs,
* the DynamicImages service also hooks model elements into viewport tracking
* (ie: lazy loading) when requested. Simply pass a "lazy: true" option
* while adding an element to have it lazy-load into the viewport.</p>
*
* <p>The DynamicImages module exports a singleton instance, so just import and use.</p>
*
* @example
* import DynamicImages from 'models/dynamic_images';
*
* var image = DynamicImages.addEl(<element>, {
* //--- One of: ---
*  image_id: <chorus-image-id, preferred>,
*  asset_id: <chorus-asset-id>,
* //---
*  lazy: <optional>,
*  ratio: <optional>,
*  placement_id: <optional>
* });
*
* // -- OR --
*
* var image = DynamicImages.addRect(<width>, <height>, {
* //--- One of: ---
*  image_id: <chorus-image-id, preferred>,
*  asset_id: <chorus-asset-id>,
* //---
* });
*
* @module models/dynamic_images
*/

import Backbone from 'exoskeleton';
import UserAgent from 'lib/user_agent';
import viewport from 'lib/viewport';
import _ from 'lib/utils';

var __ID__ = 0;

let DynamicImage = Backbone.Model.extend(
/** @lends module:models/dynamic_images.DynamicImage.prototype */
  { el: null,

    defaults: {
      image_id: null,
      asset_id: null,
      lazy: true,
      lazy_ready: false,
      placement_id: 'default',
      ratio: '*',
      variation: 1,
      url: null,
      format: 'jpg',

      // Optional height/width attributes:
      // These may be set to override (or replace) element dimensions.
      height: null,
      width: null
    },

    /**
  * <p>The DynamicImage model manages the measuring and loading of a sized image asset.
  * This model is unique in that it allows a DOM element to be attached directly to it,
  * wherein that element becomes a source of data for determining target image size.
  * This model also handles setting a sized URL to the element once it becomes available.
  * This model should <em>only</em> be created through the DynamicImages collection;
  * it should never be instanced directly.</p>
  *
  * <p><b>Fetching</b><br>
  * This model does not fetch its own data.
  * Instead, sized URLs are loaded in bulk for all dynamic images by the parent collection.
  * To create a dynamic image and fetch its data, simply add an element (with Chorus image ID)
  * into the DynamicImages collection.</p>
  *
  * @example
  * import DynamicImages from 'models/dynamic_images';
  *
  * DynamicImages.addEl(<element>, {
  * //--- One of: ---
  *  image_id: <chorus-image-id, preferred>,
  *  asset_id: <chorus-asset-id>,
  * //---
  *  lazy: <optional>,
  *  ratio: <optional>,
  *  placement_id: <optional>
  * });
  *
  * @augments Backbone.Model
  * @constructs
  */

    initialize: function(data, opts) {
    // Attach view element:
      this.el = opts && opts.el;
      this.set('id', __ID__++, { silent: true });

      // Configure lazy-loading behavior, if enabled:
      if (this.el && this.isLazy()) {
        this.el.className += ' lazy-image';

        viewport.trackElement(this.el, () => {
          this.set('lazy_ready', true);
          this.render();
        }, -0.5);
      }

      // Render upon changes to the image URL:
      this.listenTo(this, 'change:url', this.render);
      this.listenTo(this, 'change:image_id change:asset_id', this.reload);
    },

    /*
  * Renders the element view with the current url.
  * @private
  */
    render: function() {
      var imageUrl = this.get('url');

      // Abort if:
      // - we have no element.
      // - we have no imageUrl.
      // - lazy-loading is yet unfulfilled.
      if (!this.el || !imageUrl || (this.isLazy() && !this.get('lazy_ready'))) {
        return;
      }

      // Note: This is a maybe temporary fix for very large images loading for invisible elements.
      // Don't load image for element if element has no effective width/height
      // this appears to be more accurate than viewport position tracking
      // because if *any* of the element parents are "display: none" then it
      // will return 0 for both width and height
      if (this.el.offsetWidth === 0 && this.el.offsetHeight === 0) {
        return;
      }

      // Apply image URL:
      if (this.isImage()) {
        this.el.src = imageUrl;
      } else {
      // IMPORTANT: wrap URL in quotes so that ".../filter(webp)/..." will work.
        this.el.style.backgroundImage = 'url("'+ imageUrl +'")';
      }

      if (this.isLazy()) {
        this.el.className += ' lazy-loaded';
      }
    },

    /**
  * Reloads the image source URL via Ajax:
  * Triggered when the image's image_id or asset_id changes.
  * (basically, this just blanks out request state and simulates a new model "add")
  */
    reload: function() {
      if (this.el) {
        this.el.className = this.el.className.replace(/\s?\blazy-loaded\b/, '');
      }
      this.requestKey = null;
      this.collection.load();
    },

    /**
  * Gets a numeric aspect ratio for the image:
  * @returns {Number} the aspect ratio as a fraction decimal.
  */
    aspectRatio: function() {
      var ratio = this.get('ratio');

      switch (ratio) {
      case 'cinema': return 16 / 9;
      case 'portrait': return 7.5 / 10;
      case 'standard': return 4.5 / 3;
      case '*': return 1;
      }

      // ok, none of this
      // should ever happen, per the schema,
      // but you never know what crap comes in

      if (typeof ratio === 'number') {
      // Scenario: 1.77
        return ratio;
      } else if (String(ratio).indexOf(':') > 0) {
      // Scenario: "16:9"
        ratio = ratio.split(':');
        return Number(ratio[0]) / Number(ratio[1]);
      }
      // Scenario: ¯\_(ツ)_/¯
      return parseFloat(ratio);
    },

    /**
  * Gets the element/rectangle width.
  * Enforces box ratio in the event that element has no width (0).
  */
    width: function() {
      var px = window.devicePixelRatio || 1;
      return (this.get('width') || this.el.offsetWidth || arguments[0] || this.height(440) * this.aspectRatio()) * px;
    },

    /**
  * Gets the element/rectangle height.
  * Enforces box ratio in the event that element has no height (0).
  */
    height: function() {
      var px = window.devicePixelRatio || 1;
      return (this.get('height') || this.el.offsetHeight || arguments[0] || this.width(780) / this.aspectRatio()) * px;
    },

    /**
  * Specifies if the element is a proper image tag.
  * @returns {Boolean} true if the element is an <img> tag.
  */
    isImage: function() {
      return !!(this.el && this.el.tagName.toLowerCase() === 'img');
    },

    /**
  * Specifies if the element is configured for lazy loading.
  * @returns {Boolean} true if the element is set to lazy load,
  */
    isLazy: function() {
      return !!this.get('lazy');
    },

    /**
  * Generates and then returns the key we'll use to request the image URL with.
  * We're potentially requesting from one of two APIs:
  * <ul>
  *   <li>imgkeys: the newer Chorus image API</li>
  *   <li>asset_keys: the older Chorus assets API</li>

  * </ul>
  * This method generates and then caches the key used to request this image.
  * Subsequent invocations will return the cached key,
  * which is used later to look up the image's URL in the ajax response.
  * Once generated, a request key remains immutable until `reload` is called.
  * @params: a custom image type to set as the request format.
  */
    setRequestKey: function(format) {
      if (!this.requestKey) {
        if (format) this.set({ format: format });
        this.requestKey = this._imageKey() || this._assetKey();
      }
      return this.requestKey;
    },

    // Key formatter for the "?imgkeys=..." API:
    _imageKey: function() {
      var imageId = this.get('image_id');
      return imageId && [imageId, this.get('ratio'), this.get('variation'), this.width() +'x'+ this.height(), this.get('format')].join(':');
    },

    // Key formatter for "?asset_keys=..." API:
    _assetKey: function() {
      var assetId = this.get('asset_id');
      return assetId && [assetId, this.width(), this.height()].join(':');
    }
  });


let DynamicImages = Backbone.Collection.extend(
/** @lends module:models/dynamic_images.DynamicImages.prototype */
  { model: DynamicImage,

  /**
  * Manages the set of all DynamicImage instances, and handles batch-fetching image data via ajax.
  * @augments Backbone.Collection
  * @constructs
  */
    initialize: function() {
      this.listenTo(this, 'add', this.load);
      UserAgent.imageFormats();
    },

    /**
  * Builds a URL for the API endpoint.
  * Only exposed for testing purposes.
  * @private
  */
    endpoint: function(imageKeys, assetKeys) {
      return '/services/optimally_sized_images?imgkeys='+ imageKeys.join(',') +'&asset_keys='+ assetKeys.join(',');
    },

    /**
  * Loads data for all pending dynamic images awaiting content.
  * Invoking this method starts a new debounced fetch cycle for bulk-loading pending models.
  * This method will automatically fire whenever an image is added to the collection,
  * therefore calling this method directly is discouraged (you probably don't need to).
  * To load an image, simply add its element to the collection.
  *
  * @example
  *
  * import DynamicImages from 'models/dynamic_images';
  *
  * DynamicImages.addEl(<element>, {
  * //--- One of: ---
  *   image_id: <chorus-image-id, preferred>,
  *   asset_id: <chorus-asset-id>,
  * //---
  *   ratio: <optional>,
  *   placement_id: <optional>
  * });
  */
    load: _.debounce(function() {
      UserAgent.imageFormats().then((formats) => {

        var keys = this.models
          .filter(function(model) {
            return !model.requestKey;
          })
          .map(function(model) {
            return model.setRequestKey(formats.preferred);
          })
          .sort();

        // De-duplicate the array of keys (only keeps unique and truthy asset keys):
        for (var i = keys.length-1; i >= 0; i-=1) {
          if (keys[i] === keys[i-1] || !keys[i]) keys.splice(i, 1);
        }

        // Return early if we have no keys to request:
        if (!keys.length) return;

        // Filter keys into two groups for "imgkeys" and "asset_keys"
        var images = keys.filter(function(key) {
          return key.indexOf('x') > 0;
        });
        var assets = keys.filter(function(key) {
          return key.indexOf('x') < 0;
        });

        // Construct collection URL and then fetch:
        // We include "preserve_asset_keys" so that the service sends back data using the keys we requested with.
        this.url = this.endpoint(images, assets);
        this.fetch();

      });
    }, 250),

    // Parse incoming data (invoked with newly-received ajax data):
    // We're not adding anything to the collection here,
    // but rather parsing the fetched URLs into their corresponding models.
    parse: function(data) {
      var urls = data.urls || {};
      return this.models.map(function(model) {
        return {
          id: model.id,
          url: urls[model.requestKey] || model.get('url')
        };
      });
    },

    /**
  * Add a dynamic image rectangle.
  * This rectangle will provide measurements for the requested image URL.
  * Requesting a rectangle will NOT auto-populate any display element.
  * @param {Number} width of the rectangle.
  * @param {Number} height of the rectangle.
  * @param {Object} data options used to configure the model.
  * @returns {undefined}
  * @example
  * import DynamicImages from 'models/dynamic_images';
  *
  * DynamicImages.addRect(<width>, <height>, {
  * //--- One of: ---
  *   image_id: <chorus-image-id, preferred>,
  *   asset_id: <chorus-asset-id>,
  * //---
  *   ratio: <optional>,
  *   placement_id: <optional>
  * });
  */
    addRect: function(width, height, data) {
      data.width = width;
      data.height = height;
      return this.add(data);
    },

    /**
  * Adds a dynamic image element.
  * Provided element will be measured for dimensions, and then populated.
  * @param {Element} element to measure and populate.
  * @param {Object} data options used to configure the model.
  * @returns {undefined}
  * @example
  *
  * import DynamicImages from 'models/dynamic_images';
  *
  * DynamicImages.addEl(<element>, {
  * //--- One of: ---
  *   image_id: <chorus-image-id, preferred>,
  *   asset_id: <chorus-asset-id, regrettable>,
  * //---
  *   lazy: <optional>,
  *   ratio: <optional>,
  *   placement_id: <optional>
  * });
  */
    addEl: function(element, data) {
    // Resolve element:
      if (element instanceof Backbone.$) element = element[0];
      if (!(element instanceof Element)) throw 'An element is required.';

      // Resolve existing records:
      var existing = this.findByElement(element);
      if (existing) existing.set(data);

      // Return existing, or new:
      return existing || this.add(data, { el: element });
    },

    /**
  * Finds a DynamicImage model for a provided element:
  * @param {Element} element to find the model for.
  * @returns {DynamicImage} model for the specified element.
  */
    findByElement: function(el) {
      for (var i=0; i < this.length; i+=1) {
        if (this.models[i].el === el) return this.models[i];
      }
      return null;
    }
  });

export default new DynamicImages();
