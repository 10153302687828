/**
* <p>A slim wrapper around the browser's native <code>localStorage</code> API.
* The module handles <code>localStorage</code> feature detection,
* and then provides a simple "get" and "set" API for reading and writing data.
* This wrapper handles all serialization and parsing of stored data structures.</p>
*
* <p>Type information is preserved when using this adapter.
* JS Objects and Arrays sent to set/get will be serialized and parsed to store in
* local storage as strings, but returned as their initial type.</p>
* <ul>
*   <li>{"my":"object"} will store as JSON.stringify({"my":"object"}) and be run thru JSON.parse() when returned.</li>
* </ul>
*
* <p>Booleans, numbers and strings will have some basic type coercion applied to them.
* This means that if you attempt to store a string that looks like a boolen, number or float,
* it might come back at you as a different type. Just don't store shitty values, or type
* check your stored values if it's important to you, and you won't have a problem.</p>
* <ul>
*  <li>setting true, false will store as 'true'/'false' and be returned from LocalStorage.get() as a boolean.</li>
*  <li>setting 'true', 'false/ will store as 'true'/'false' and be returned from LocalStorage.get() as a boolean.</li>
*  <li>setting 'TRUE, 'FALSE' will store as 'TRUE'/'FALSE' and be returned from LocalStorage.get() as a string.</li>
*  <li>setting '1.1, '1.2' will store as '1.1'/'1.2' and be returned from LocalStorage.get() as a float.</li>
*  <li>setting 1, 100, will store as '1'/'100' and be returned from LocalStorage.get() as a integers.</li>
* </ul>
*
* @example
* import storage from 'lib/localstorage';
*
* var data = storage.get('my-storage-key');
* storage.set('my-storage-key', data);
*
* @module lib/localstorage
*/

/**
* Local Storage wrapper.
* @constructor
*/
function LocalStorage() {}

LocalStorage.prototype = {
  _enabled: null,

  /**
  * Tests the availability of browser <code>localStorage</code>.
  * Based on Modernizr implementation.
  * @returns {Boolean} true is localStorage is enabled.
  * @example
  * import storage from 'lib/localstorage';
  *
  * if (storage.enabled()) { ...
  */
  enabled: function() {
    if (this._enabled === null) {
      var ls = 'vox';
      try {
        localStorage.setItem(ls, ls);
        localStorage.removeItem(ls);
        this._enabled = true;
      } catch(e) {
        this._enabled = false;
      }
    }
    return this._enabled;
  },

  /**
  * Gets a value from localStorage.
  * @param {String} key the storage key to access.
  * @returns {Object} the parsed cache of localStorage data for the specified key.
  * Serialized data will be returned as a parsed object.
  * basic type coercion will be applied to non-object-looking thinks
  */
  get: function(key) {
    if (this.enabled()) {
      var value = localStorage.getItem(key);

      // serialized objects get returned as JSON:
      if (value && (value[0] === '{' || value[0] === '[')) {
        return JSON.parse(value);
      }

      // non-objects get basic type coercion:
      if (typeof value === 'string') {
        if (/^(true|false)$/i.test(value)) return value.toLowerCase() === 'true';
        if (/^\d+$/.test(value)) return parseInt(value);
        if (/^\d+\.\d+$/.test(value)) return parseFloat(value);
      }
    }
    return value || null;
  },

  /**
  * Sets a value into localStorage.
  * @param {String} key the storage key to set.
  * @param {Object} value the storage value to assign.
  * @returns {undefined}
  */
  set: function(key, value) {
    if (this.enabled()) {
      if (typeof value === 'object') value = JSON.stringify(value);
      localStorage.setItem(key, value);
    }
  },

  /**
  * Removes a keyed item from localStorage.
  * @param {String} key to remove from storage.
  */
  remove: function(key) {
    if (this.enabled()) {
      localStorage.removeItem(key);
    }
  },

  /**
  * Clears localStorage.
  */
  clear: function() {
    if (this.enabled()) {
      localStorage.clear();
    }
  }
};

export default new LocalStorage();
export const constructor = LocalStorage;
