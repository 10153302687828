import _ from 'lib/utils';
import Backbone from 'exoskeleton';
import $ from 'jquery';
import Analytics from 'lib/analytics';

const PianoId = _.extend(Backbone.Events, {
  _pending: null,
  _data: {},
  _user_id: null,
  pullAccount: function () {
    if (!this._pending) {
      this._pending = $.Deferred();
      var tp = window.tp || [];
      tp.push(['setUsePianoIdUserProvider', true]);
      tp.push(['init', (data) => {
        window.tp.pianoId.init({
          loginFailed: () => {
            this._data = {};
            this._pending.resolve(this._data);
            this.trigger('change', { data: _.extend(this._data, {}) });
            $('body').removeClass('piano-logged-in');
            this._user_id = null;
          },
          loggedIn: (data) => {
            this._data = data;
            this._pending.resolve(this._data);
            this.trigger('change', { data: _.extend(this._data, {}) });
            $('body').addClass('piano-logged-in');
            if (data.user && data.user.uid && this._user_id != data.user.uid) {
              this._user_id = data.user.uid;
              Analytics.event('piano', 'piano-login', 'piano ID available', data.user.uid);
            }

          },
          loggedOut: () => {
            this._data = {};
            this._pending.resolve(this._data);
            this.trigger('change', { data: _.extend(this._data, {}) });
            $('body').removeClass('piano-logged-in');
            this._user_id = 'no ID';
          }
        });
      }]);
    }
    return this._pending.promise();
  },


  showRegister: function () {
    var tp = window.tp || [];
    if (tp.pianoId) {
      tp.pianoId.show({
        screen: 'register',
        loggedIn: (data) => {
          this._data = data;
          this.trigger('change', { data: _.extend(this._data, {}) });
          $('body').addClass('piano-logged-in');
        },
        loggedOut: () => {
          this._data = {};
          this.trigger('change', { data: _.extend(this._data, {}) });
          $('body').removeClass('piano-logged-in');
        }
      });
    }
  },

  showLogin: function () {
    var tp = window.tp || [];
    if (tp.pianoId) {
      tp.pianoId.show({
        screen: 'login',
        loggedIn: (data) => {
          this._data = data;
          this.trigger('change', { data: _.extend(this._data, {}) });
          $('body').addClass('piano-logged-in');
        },
        loggedOut: () => {
          this._data = {};
          this.trigger('change', { data: _.extend(this._data, {}) });
          $('body').removeClass('piano-logged-in');
        }
      });
    }
  },

  logout: function () {
    var tp = window.tp || [];
    if (tp.pianoId) {
      tp.pianoId.logout(() => {
        this._data = {};
        this.trigger('change', { data: _.extend(this._data, {}) });
        $('body').removeClass('piano-logged-in');
      });
    }
  }
});

export default PianoId;
