import 'promise-polyfill';
import Initializer from 'lib/initializer';
import { currentUser } from 'models/context';

Initializer.readyTask(function() {
  currentUser.fetch().then(() => {
    if (currentUser.get('can_edit') || currentUser.get('can_admin') ) {
      loadTools();
    }
  });
});

function loadTools() {
  // Load tools CSS:
  var meta = document.querySelector('meta[name="style-tools"]');
  var style = document.createElement('link');
  style.rel = 'stylesheet';
  style.media = 'all';
  style.href = meta ? meta.content : '/style/'+ Date.now() +'/tools.css';
  document.head.appendChild(style);

  // Load tools JavaScript module:
  import('./scripts/main').then(() => Initializer.run());
}
