import './index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import camelcaseKeys from 'camelcase-keys';

const deferred = $.Deferred();

Initializer.registerComponent('site/article_footer', function (
  element,
  data
) {
  const FIXED_PLAN_URL = 'https://vox.memberful.com/checkout?plan=';
  const CUSTOM_ANNUAL_PLAN = 74305;
  const CUSTOM_ONCE_PLAN = 51976;
  const CUSTOM_MONTHLY_PLAN = 51759;

  // Get all data in the CTA given in the site config and pass it to the component.
  // Grab the variables and reassign them as camelcase versions because we're getting
  // data from Ruby which prefers snakecase but we're executing in JavaScript, which
  // needs things to be in camelcase.
  const {
    articleFooterCtaButtonAnnualCopy,
    articleFooterCtaButtonMonthlyCopy,
    articleFooterCtaButtonCopy,
    articleFooterCtaOncePlans,
    articleFooterCtaMonthlyPlans,
    articleFooterCtaAnnualPlans,
    useArticleFooterCtaReadCounter,
  } = getConfigWithRemappedKeys(data);

  const articleFooterCtaPlans = {
    once: articleFooterCtaOncePlans,
    monthly: articleFooterCtaMonthlyPlans,
    annual: articleFooterCtaAnnualPlans,
  };

  const queryParams = document.location.search || '';
  const basedParams = new URLSearchParams(queryParams);
  const paypalUrl = document.querySelector('.paypal-url');
  const referrer = document.location.pathname || '';
  const ctaContainer = document.querySelector('.cta-container');
  const contributeFrequencyButtons = Array.from(document.querySelectorAll('.contribute--frequency-btn'));
  const planAmountRadios = Array.from(document.querySelectorAll('.plan--radio'));
  const contributeCustom = document.querySelector('.contribute--custom');
  const contributeCustomField = document.querySelector('#contribute--custom-input-1');
  let contributionAmount = '$120';
  let filteredParams = '';

  for (let pair of basedParams.entries()) {
    if (pair[0].includes('itm_') || pair[0].includes('utm_')) {
      filteredParams += `__${pair[0]}=${pair[1]}`;
    }
  }

  paypalUrl.href = `${paypalUrl.href}&custom=${filteredParams}__referrer=${referrer}`;

  // ====================== ALL THE EVENT LISTENERS ====================================

  // Attach event listeners to each of the radio buttons to fire
  // changes to the form on user selections.
  contributeFrequencyButtons.forEach(frequencyButton => frequencyButton.addEventListener('change', event => chooseFrequency(event.target)));
  planAmountRadios.forEach(radio => radio.addEventListener('change', event => choosePlan(event.target)));

  // Unhide the custom amount form.
  contributeCustom.addEventListener('click', event => choosePlan(event.target));

  // Sets the Memberful link and the submit button amount on change.
  contributeCustomField.addEventListener('change', event => customPlan(event.target));
  contributeCustomField.addEventListener('keyup', event => customPlan(event.target));

  // Set plan and frequency based on initial plan values.
  setInitialData();

  // Attach Memberful modal-generation script.
  attachMemberfulListener();

  // Update articles-read counter.
  // Only turn on if the setting is enabled. Else, there won't be
  // any elements rendererd for it to attach to.
  if (useArticleFooterCtaReadCounter) checkAndUpdateReadCount();

  // Submit form if user presses enter while focused on the form elements.
  // Make spacebar activate form controls.
  ctaContainer.addEventListener('keyup', event => {
    const submitButton = document.querySelector('.contribute--submit');
    const buttonIsHidden = submitButton.classList.value.includes('hidden');

    // Enter key pressed.
    if (event.keyCode === 13 && !buttonIsHidden) {
      event.preventDefault();
      submitButton.click();
    }

    // Spacebar pressed.
    if (event.keyCode === 32) {
      event.preventDefault();
      document.activeElement.click();
    }
  });

  // ============== FUNCTIONS FOR CTA PAYMENT SELECTIONS ========================

  // Memberful script that listens for user action to
  // generate a payment modal. A direct lift/copypasta from original code
  // we've been using in the vox.com/support page.
  function attachMemberfulListener() {
    const setup = () => {
      window.MemberfulEmbedded.setup();
    };
    let s = document.createElement('script');
    let head = document.querySelector('head');

    window.MemberfulOptions = { site: 'https://vox.memberful.com' };

    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.src = 'https://d35xxde4fgg0cx.cloudfront.net/assets/embedded.js';

    s.addEventListener('load', setup, false);
    head.appendChild(s);
  }

  // Turn ruby_style_variables into javascriptFriendlyOnes.
  function getConfigWithRemappedKeys(configObj) {
    return camelcaseKeys(configObj);
  }

  // Grab amounts passed down from component model.
  function getAmountsForFrequency(frequencyObj) {
    return frequencyObj.map(planObj => planObj.amount);
  }

  // Create amount text/copy for all set-amount items.
  function setAmounts(el) {
    const selectedFrequency = el.dataset.chosenFrequency;
    const amounts = Array.from(document.querySelectorAll('.fixed--plan-amount'));

    amounts.forEach((amt, index) => {
      amt.dataset.amount = `$${getAmountsForFrequency(JSON.parse(articleFooterCtaPlans[selectedFrequency]).plans)[index]}`;
      amt.nextElementSibling.firstElementChild.innerHTML = `$${getAmountsForFrequency(JSON.parse(articleFooterCtaPlans[selectedFrequency]).plans)[index]}`;
    });
  }

  // Checks for --selected classes to determine what plan IDs
  // to set on form submit.
  function setInitialData() {
    const defaultFrequency = document.querySelector('.contribute--selected-frequency');
    const defaultPlan = document.querySelector('.contribute--selected');

    setFrequency(defaultFrequency);
    setPlanLink(defaultPlan);
  }

  function setPlanLink(el) {
    if (!el.dataset.amount || el.dataset.amount === 'other') return setPlanLinkOther(); // Custom plan selected, break out.

    const disabledSubmitButton = document.querySelector('#contribute--submit-button-disabled');
    const selectedFrequency = el.dataset.chosenFrequency;
    const plansForFrequency = JSON.parse(articleFooterCtaPlans[selectedFrequency]).plans;
    const selectedPlanIndex = el.dataset.planIndex;
    const submitButton = document.querySelector('#contribute--submit');
    const customValueInput = document.querySelector('#contribute--custom-input-1');

    if (customValueInput.value !== '') customPlan(el); // If custom, use custom link.

    disabledSubmitButton.classList.add('hidden');
    submitButton.classList.remove('hidden');
    submitButton.href = `${FIXED_PLAN_URL}${plansForFrequency[selectedPlanIndex].plan_id}`;
  }

  function setPlanLinkOther() {
    const submitButton = document.querySelector('#contribute--submit');
    const disabledSubmitButton = document.querySelector('#contribute--submit-button-disabled');

    disabledSubmitButton.classList.remove('hidden');
    submitButton.classList.add('hidden');
  }

  // Applies the correct value using data attribute containing the
  // plan amounts and IDs based on frequency.
  function setFrequency(el) {
    const customInputField = document.querySelector('#contribute--custom-input-1');
    let amounts = Array.from(document.querySelectorAll('.fixed--plan-amount'));

    amounts.forEach(amount => {
      amount.dataset.chosenFrequency = el.dataset.frequency;
    });

    customInputField.dataset.chosenFrequency = el.dataset.frequency;

    // Set custom plan ID if there is a user-inputted value in the custom field.
    if (customInputField.value !== '') customPlan(el);
  }

  function chooseFrequency(el) {
    const chosenFrequency = el.dataset.chosenFrequency;
    const defaultPlanForFrequency = JSON.parse(articleFooterCtaPlans[chosenFrequency]).default_plan;
    const fixedPlanAmounts = Array.from(document.querySelectorAll('.fixed--plan-amount'));
    const defaultAmount = fixedPlanAmounts[defaultPlanForFrequency];
    let billingFrequencyTextButtons = Array.from(document.querySelectorAll('.display--monthly-text'));
    let currentSelectedPlanValue = document.querySelector('.contribute--selected');

    // Updates the amounts on the buttons.
    // Sets the recommended amount based on values set in
    // `defaultAmountForFrequency` above.
    billingFrequencyTextButtons.forEach(radio => {
      switch (chosenFrequency) {
      case 'once':
        radio.classList.add('hidden');
        currentSelectedPlanValue = radio;
        break;
      case 'monthly':
        radio.classList.remove('hidden');
        radio.innerText = `/${articleFooterCtaButtonMonthlyCopy}`;
        break;
      case 'annual':
        radio.classList.remove('hidden');
        radio.innerText = `/${articleFooterCtaButtonAnnualCopy}`;
        break;
      }
    });

    defaultAmount.checked = true;
    currentSelectedPlanValue = defaultAmount;

    setAmounts(el);
    setFrequency(el);
    choosePlan(currentSelectedPlanValue);
    updateButtonText(el);
  }

  // Blank out any inputted custom value if there is one.
  // Set plan links.
  function choosePlan(el) {
    const disabledSubmitButton = document.querySelector('#contribute--submit-button-disabled');
    const submitButton = document.querySelector('#contribute--submit');
    const customInputField = document.querySelector('#contribute--custom-input-1');

    contributionAmount = el.dataset.amount;
    updateButtonText(el);
    showHideCustomInput(el);

    // Remove any custom value.
    customInputField.value = '';

    // Disable submit button.
    if (el.classList.value.includes('contribute--custom')) {
      disabledSubmitButton.classList.remove('hidden');
      submitButton.classList.add('hidden');
    }

    setPlanLink(el);
  }

  function showHideCustomInput(el) {
    const customInputWrapper = document.querySelector('.contribute--custom');
    const customFieldFocused = el.classList.value.includes('contribute--custom');

    // Show and hide the "other" field when selected.
    el.dataset.amount === 'other' || customFieldFocused
      ? customInputWrapper.classList.remove('hidden')
      : customInputWrapper.classList.add('hidden');
  }

  // Add monthly and annual copy after the plan amount.
  function getButtonFormattedFrequency(selectedFrequency) {
    let formattedFreq = '';

    switch (selectedFrequency) {
    case 'annual':
      formattedFreq = `/${articleFooterCtaButtonAnnualCopy}`;
      break;
    case 'monthly':
      formattedFreq = `/${articleFooterCtaButtonMonthlyCopy}`;
      break;
    default:
      formattedFreq = '';
    }

    return formattedFreq;
  }

  // Set button text based on amount/frequency.
  function updateButtonText(el) {
    let amount = contributionAmount;
    let frequency = el.dataset.chosenFrequency;
    let buttonText;

    const formattedFrequencyToDisplayButton = getButtonFormattedFrequency(frequency);
    const numbersAndDollarOnly = /([0-9]|[$])+/;
    const submitButtons = Array.from(document.querySelectorAll('.contribute--submit-button'));

    // Hide frequency label if selected frequency is once.
    if (frequency === 'once') {
      let onceElements = document.querySelectorAll(
        '.contribute--frequency-label'
      );

      onceElements.forEach((e) => {
        e.style.display = 'none';
      });
    } else {
      let monthlyElements = document.querySelectorAll(
        '.contribute--frequency-label'
      );

      monthlyElements.forEach((e) => {
        e.style.display = 'block';
      });
    }

    // If there is no amount or if the amount is non-numeric — "other" case,
    // we want to remove the "$/monthly" off the end of the CTA copy
    // until a valid amount is accepted.
    if (!amount || !numbersAndDollarOnly.test(amount)) {
      buttonText = `${articleFooterCtaButtonCopy}`;
    } else if (amount.includes('$')) {
      buttonText = `${articleFooterCtaButtonCopy} ${amount}${formattedFrequencyToDisplayButton}`;
    } else {
      buttonText = `${articleFooterCtaButtonCopy} $${amount}${formattedFrequencyToDisplayButton}`;
    }

    submitButtons.forEach(button => (button.innerText = buttonText));
  }

  // If user elects to pay a custom amount.
  function customPlan(el) {
    let planNumber = '';
    let price = document.querySelector('#contribute--custom-input-1').value;
    let frequency = document.querySelector('#contribute--custom-input-1').dataset.chosenFrequency;
    const customInputField = document.querySelector('#contribute--custom-input-1');
    const disabledFrequencyButton = document.querySelector('#contribute--submit-button-disabled');
    const submitButton = document.querySelector('#contribute--submit');

    // Disable button if there is no amount entered.
    if (!price) {
      disabledFrequencyButton.classList.remove('hidden');
      submitButton.classList.add('hidden');
    } else {
      disabledFrequencyButton.classList.add('hidden');
      submitButton.classList.remove('hidden');
    }

    // Set to these plan IDs if custom. (The plan IDs only code for
    // frequency and accepts a price greater than $3 as a query param.)
    switch (frequency) {
    case 'annual':
      planNumber = CUSTOM_ANNUAL_PLAN;
      break;
    case 'monthly':
      planNumber = CUSTOM_MONTHLY_PLAN;
      break;
    default:
      planNumber = CUSTOM_ONCE_PLAN;
    }

    const customPlanLink =
      'https://vox.memberful.com/checkout?plan=' +
      planNumber +
      '&price=' +
      price;

    // Set plan link for submit button.
    submitButton.href = customPlanLink;

    // Grab custom amount and set it as contrib amount.
    contributionAmount = customInputField.value;
    updateButtonText(el);
  }

  // ============== FUNCTIONS FOR ARTICLE READ COUNTER ========================

  /*
   * Returns integer of the current count incremented by 1.
   * @param {string} count - count pulled from localstorage
   * @return {integer} read count incremented by 1.
  */
  function incrementedCount(count) {
    let parsedCount = parseInt(count);

    return parsedCount += 1;
  }

  /*
   * Commits updated read count in localstorage
   * and updates the read counter on the page.
   * @param {Object} readCountData - current user data
  */
  function updateReadCount(readCountData) {
    const userResetDate = new Date(readCountData.resetDate);
    const readCountHeadline = document.querySelector('.c-article-footer--readcount'); // <h3></h3>

    // Check if today's date is still before the reset date.
    // If it's still before, only then, increment the counter.
    if (readCountData && userResetDate >= new Date()) {
      readCountData.count = incrementedCount(readCountData.count);

      // If we are past the reset date, then set a new read count
      // starting at 1 and reset the reset date to one month from now.
    } else {
      readCountData.count = 1;
      readCountData.resetDate = getResetDate();
    }

    // Set the new read count.
    localStorage.setItem('vox_article_readcount', JSON.stringify(readCountData));

    // Add sentence to the h3.
    readCountHeadline.innerHTML = `<em>You've read <span class="c-article--footer-readcount-number">${readCountData.count}</span> <span class="c-article--footer-readcount-quantifier">${readCountData.count > 1 ? 'articles' : 'article'}</span> in the last 30 days.</em>`;
  }

  /*
   * Takes today's date and returns a new date
   * one month from today.
   * @return {Date}
  */
  function getResetDate() {

    // Figure out when to reset the counter.
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    // Make it one month from today.
    const resetCounterDate = new Date(year, month + 1, day);

    return resetCounterDate;
  }

  /*
   * Checks if passed item is valid JSON.
   * @param {string} value
   * @return {boolean}
  */
  function isValidJson(value) {
    try {
      JSON.parse(value);
    } catch (error) {
      console.error('Article read counter: Invalid JSON object.', error);

      return false;
    }

    return true;
  }

  /*
   * Checks to see if user has localstorage record. If they do,
   * pull those values and use it to update the counter.
   * If not, then create a new entry.
  */
  function checkAndUpdateReadCount() {

    // Grab latest read count data.
    const data = localStorage.getItem('vox_article_readcount');

    // Make sure localstorage value valid JSON first.
    let parsedData = isValidJson(data) ? JSON.parse(data) : {};

    // Use existing read count data or create a new entry.
    const readCountData = !!parsedData && !!parsedData.count && !!parsedData.resetDate
      ? parsedData
      : { count: 0, resetDate: getResetDate() };

    // Update localstorage and page element.
    updateReadCount(readCountData);
  }
}, {
  priority: Initializer.HIGH,
  load: function() {
    return deferred.promise();
  }
});
