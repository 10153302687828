import './styles/index.scss';
import Initializer from 'lib/initializer';
import $ from 'jquery';
import Analytics from 'lib/analytics';
import Ajax from 'lib/ajax';
import Storage from 'lib/localstorage';
import currentContext from 'models/context';
import { currentUser } from 'models/context';
import 'promise-polyfill';

const LOCAL_STORAGE_KEY = 'coral_sso_jwt';
const forceJWT = window.location.search.includes('forceJwt=true');
const coralLogging = window.location.search.includes('coralLogging=true');
const jwtVersion = 7;

let accessObject = { token:null };
let errorState = false;
let initPriority = document.location.hash == '#comments' ? Initializer.HIGH : Initializer.MEDIUM;

// INIT for coral 4
function initTalk(element, opts) {
/* eslint-disable */
  Coral.Talk.render(element, {
    /* eslint-enable */
    talk: opts.rootUrl,
    lazy: opts.lazyLoad,
    asset_id: opts.entryId,
    asset_url: opts.entryUrl,
    events: (events) => {
      events.onAny(function trackEvent(eventName, data) {
        var eventMapping = {
          'action.TALK_FRAMEWORK_LOGOUT': 'sign_out',
          'action.TALK_FRAMEWORK_HANDLE_SUCCESSFUL_LOGIN': 'sign_in',
          'mutation.PostComment.success': 'post_comment',
          'mutation.EditComment.success': 'edit_comment',
          'mutation.CreateRespectAction.success': 'respect',
          'mutation.PostFlag.success': 'report',
          'action.SET_ACTIVE_REPLY_BOX': 'reply'
        };
        var action = eventMapping[eventName];
        if (action) {
          // Reply needs an empty id to indicate reply was successfully posted
          if (action === 'reply' && data.action.id.length > 0) return;
          Analytics.eventAutoFormat('coral_comment', action);
        }
      });
    }
  });
}

function fetchChorusAuthJWT() {
  return new Promise((resolve, reject) => {

    currentUser.fetch().then(() => {
      // if there's no current user, we need to be sure to clear out old jwt tokens
      if (!currentUser.get('id')) {
        if (coralLogging) {
          console.log('anonymous user, don\'t request a JWT');
        }
        jwt = null;
        Storage.remove(LOCAL_STORAGE_KEY);
        resolve();
      } else {
        var jwt = Storage.get(LOCAL_STORAGE_KEY);
        // force reload from query param | test to see if the jwt has expired. 600k ms == 10 mins | force update for new version
        if (
          !forceJWT &&
          jwt !== null &&
          jwt.expiration * 1000 > Date.now() - 600000 &&
          jwt.jwt_version === jwtVersion &&
          jwt.userVersion === currentUser.get('user_version') &&
          jwt.lastLogin === currentUser.get('last_login') &&
          !currentUser.get('must_verify_email') ) {
          if (coralLogging) {
            console.log('using localstorage JWT');
          }
          accessObject = jwt;
          resolve(accessObject);
        } else {
          jwt = null;
          Storage.remove(LOCAL_STORAGE_KEY);
          if (coralLogging) {
            console.log('requesting new JWT from SBN');
          }
          var Xhr = Ajax.extend({
            ajaxOptions : {
              timeout: 3000
            },
            urls: {
              fetchToken: '/services/coral-jwt'
            },
          });
          Xhr.ajax('fetchToken', {
            error: () => {
              errorState = true;
              if (coralLogging) {
                console.log('jwt error');
              }
              resolve({ errorState: true });
            },
            success: (ret) => {
              if (coralLogging) {
                console.log('jwt loaded');
              }
              ret.jwt_version = jwtVersion;
              Storage.set(LOCAL_STORAGE_KEY, ret);
              accessObject = ret;
              resolve(accessObject);
            }
          });
        }
      }
    });
  });

}

function loadCoral5Script(url, cacheVersion) {
  let src = `${url}/assets/js/embed.js`;
  if (cacheVersion && typeof cacheVersion === 'string') {
    src += `?v=${cacheVersion}`;
  }
  return new Promise((resolve, reject)=>{
    var d = document;
    var s = d.createElement('script');
    s.src = src;
    s.async = false;
    s.defer = true;
    s.onload = function() {
      resolve();
    };
    s.onerror = function() {
      reject();
    };
    ( d.head || d.body ).appendChild(s);
  });
}

Initializer.registerComponent('apps/coral', function (element, data) {
  const container = document.getElementById('coral-talk');
  let embedUrl = `${data.coral_root_url}/static/embed.js`;
  if (!data.coral_root_url) {
    if (coralLogging) {
      console.log('cannot load coral comments, no coral_root_url');
    }
    return;
  }
  if (data.coral_cache_version && typeof data.coral_cache_version === 'string') {
    embedUrl += `?v=${data.coral_cache_version}`;
  }
  if (data.coral_version === 5) {
    var url = data.coral_root_url;
    var cacheVersion = data.coral_cache_version;
    var containerId = data.coral_container_id || 'coral_thread';
    Promise.all([fetchChorusAuthJWT(), loadCoral5Script(url, cacheVersion)]).then(()=>{
      if (coralLogging) {
        console.log('fetchChorusAuthJWT and loadCoral5Script completed' );
      }
      /* eslint-disable */
      if (window.Coral != undefined) {
        if (coralLogging) {
          console.log('window.Coral is defined' );
        }
      /* eslint-enable */
        if (errorState || accessObject.userMustVerifyEmail || accessObject.userMustProvideEmail || accessObject.userBanned) {
          console.log('my log has a message for you' );
          var verifyMessage = document.createElement('div');
          verifyMessage.classList.add('c-coral__verify-message');
          if (errorState) {
            verifyMessage.innerHTML = 'There was an error loading your account data.';
          } else if (accessObject.userBanned) {
            verifyMessage.innerHTML = `Your commenting account has been frozen, please contact <a href="mailto:${ data.moderation_email }">${ data.moderation_email }</a> for more information.`;
          } else if (accessObject.userMustProvideEmail) {
            verifyMessage.innerHTML = `You're signed in, but you must <a href="${ accessObject.editProfileUrl}">provide and verify your email address</a> before you can use the new commenting experience.`;
          } else {
            verifyMessage.innerHTML = `You're signed in, but you must <a href="${ accessObject.verifyEmailUrl}">verify your email address</a> before you can use the new commenting experience.`;
          }
          var comments = document.querySelector('#comments');
          comments.insertBefore(verifyMessage, comments.childNodes[0]);
        }
        if (coralLogging) {
          console.log('running Coral.createStreamEmbed' );
        }
        /* eslint-disable */
        var stream = Coral.createStreamEmbed({
        /* eslint-enable */
          id: containerId,
          autoRender: true,
          rootURL: url,
          storyID: data.entry_id,
          storyURL: data.entry_url,
          customCSSURL: data.coral_css_url,
          customFontsCSSURL: data.coral_fonts_url,
          containerClassName: `rec-threshold-${data.recommended_threshold}`,
          accessToken: accessObject.token,
          events: (events) => {
            events.onAny(function trackEvent(eventName, data) {
              var eventMapping = {
                'createComment.success': 'post_comment',
                'createCommentReply.success': 'post_comment',
                'editComment.success': 'edit_comment',
                'createCommentReaction.success': 'respect',
                'reportComment.success': 'report',
                'setCommentsOrderBy': 'set_sort_order',
                'unmarkAll': 'unmark_unread',
                'jumpToNextUnseenComment': 'z_key_next',
                'setProfileTab': 'set_profile_tab',
                'ignoreUser.success': 'ignore_user',
                'copyPermalink': 'copy_permalink',
                'refreshCommentsButton': 'refresh_comments',
                'closeRefreshCommentsButton': 'hide_refresh_comments',
                'embedInteraction': 'comment_embed_interaction'
              };
              var action = eventMapping[eventName];
              if (action) {
                Analytics.eventAutoFormat('coral_comment', action);
              }
            });
            events.on('loginPrompt', function() {
              Analytics.eventAutoFormat('coral_comment', 'loginPrompt');
              location.href = `${currentContext.get('login_url')}${encodeURIComponent(location.href)}`;
            });
          }
        });
      }
    }).catch(error => {
      var verifyMessage = document.createElement('div');
      verifyMessage.classList.add('c-coral__verify-message');
      verifyMessage.innerHTML = 'There was an error loading your account data.';
      var comments = document.querySelector('#comments');
      comments.insertBefore(verifyMessage, comments.childNodes[0]);
      if (coralLogging) {
        console.log('error loading coral ', error );
      }
    });
  } else if (data.lazy_load) {
    // Lazy load
    let script = document.createElement('script');
    script.async = true;
    $(element).append(script);
    script.onload = function() {
      initTalk(container, {
        lazyLoad: true,
        rootUrl: data.coral_root_url,
        entryId: data.entry_id,
        entryUrl: data.entry_url
      });
    };
    script.src = embedUrl;
  } else {
    // Manual load by clicking "Show Comments"
    $(element).find('button[data-ui-action="load_coral"]').on('click', ()=>{
      $.getScript(embedUrl, ()=>{
        initTalk(container, {
          lazyLoad: false,
          rootUrl: data.coral_root_url,
          entryId: data.entry_id,
          entryUrl: data.entry_url
        });
      });
    });
  }
}, {
  priority: initPriority
});

window.fetchChorusAuthJWT = fetchChorusAuthJWT;

