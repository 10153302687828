import '../../apps/auth';
import './index.scss';
import Initializer from 'lib/initializer';
import SocialButtonsView from './scripts/view';
import PopoverView from './scripts/popover';

Initializer.registerComponent('site/social_buttons_list', function(element, data) {
  data.el = element;
  new SocialButtonsView(data);
});

Initializer.registerComponent('site/social_buttons_list/popover', function(element, data) {
  data.el = element;
  new PopoverView(data);
});
