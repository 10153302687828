import './index.scss';

import Initializer from 'lib/initializer';
import PianoLoginView from './scripts/view';

Initializer.registerComponent('site/piano/login_controls', function (element, data) {
  new PianoLoginView({ el: element, data: data });
}, {
  priority: Initializer.HIGH
});
