/**
* Detects the brightness of an element's backgroundColor
* Usage:
*
* import ElementBrightness from './scripts/element_brightness';
*
* @example
* // A: Get a specific pseudo element bg color
* ElementBrightness.isBright( document.querySelector('.c-global-header), 'before' );
*
* // B: Get a specific element bg color
* ElementBrightness.isBright( document.querySelector('.c-app-nav) );
*
* // C: Send the child element's parent to find the closest bg color
* var childElement = document.querySelector('.child-element');
* ElementBrightness.isBright( childElement.parentNode );
*/
export default {

  /**
   * Determine the brightness of the background color or an element matching the
   * node and pseudoSelector passed
   * @param  {node}   node             Node to test
   * @param  {string} pseudoSelector   pseudo selector to test
   * @return {Boolean}
   */
  hasLightBackground: function(el, pseudoSelector) {
    var color = this.closestBackgroundColorToElement(el, pseudoSelector);
    return this.brightness(color) > 128;
  },

  /**
   * Find the closest parent element with a backgroundColor set
   * @param  {node} el               Element to start with
   * @param  {string} pseudoSelector A pseudo selector to test against for the initial element
   * @return {object}                Object representing RGBA color
   */
  closestBackgroundColorToElement: function(el, pseudoSelector) {
    var bgcolor;

    while (el instanceof Element) {
      bgcolor = this.parseRGBA(window.getComputedStyle(el, pseudoSelector).backgroundColor);

      if (bgcolor.a === 0) {
        bgcolor = pseudoSelector = undefined;
        el = el.parentNode;
      } else {
        break;
      }
    }

    return bgcolor || this.parseRGBA('#FFFFFF');
  },

  /**
   * Convert a string to an object formatted for RGBA
   * @param  {string} colorString Input string
   * @return {object} RGBA
   */
  parseRGBA: function(str) {
    const RGBA_CODE = /^rgba?\(([\d\.\s]+),([\d\.\s]+),([\d\.\s]+)(?:,([\d\.\s]+))?\)$/i;
    var components = [];

    if (str === 'transparent') {
      components = [0, 0, 0, 0, 0];
    } else if (RGBA_CODE.test(str)) {
      components = str.match(RGBA_CODE);
    } else {
      const SHORT_HEX_CODE = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const LONG_HEX_CODE = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

      if (SHORT_HEX_CODE.test(str)) {
        str = str.replace(SHORT_HEX_CODE, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });
      }

      if (LONG_HEX_CODE.test(str)) {
        components = (str.match(LONG_HEX_CODE) || []).map(function(val, i) {
          return i ? parseInt(val, 16) : val;
        });
      }
    }

    return {
      r: parseFloat(components[1] || 0),
      g: parseFloat(components[2] || 0),
      b: parseFloat(components[3] || 0),
      a: parseFloat(components[4] === 0 ? 0 : (components[4] || 1))
    };
  },

  /**
   * Get the brightness of an RGB color
   * @param  {string} rgb     RGB color
   * @return {float}           Brightness level
   * As determined by the formula: https://www.w3.org/TR/AERT
   */
  brightness: function(rgb) {
    if (typeof rgb === 'string') {
      rgb = this.parseRGBA(rgb);
    }
    return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  }

};
