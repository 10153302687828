import Backbone from 'exoskeleton';
import noticeHTML from '../tmpl/index.html';
import parseTemplate from 'lib/template';
import lclStorage from 'lib/localstorage';
import Notice from 'lib/notification';
import Analytics from 'lib/analytics';

const CONFIRM_ACCEPT_VALUE = '1';

let TOSUpdateNotice = Backbone.View.extend({
  tos_update_id : null,
  notice_type : 'privacy_notice',
  notice_element: null,

  initialize: function(opts) {
    this.tos_update_id = opts.tos_update_id;
    if (opts.ui_format == 'hat') {
      this.notice_element = Notice.hat(parseTemplate(noticeHTML)(opts), false, false );
    } else {
      this.notice_element = Notice.notify(parseTemplate(noticeHTML)(opts), false, false );
    }
    this.notice_element.find('a, .p-button, .p-notice-dismiss').on('click', (evt)=>{ this.handleClick(evt); });
  },

  remember: function() {
    lclStorage.set(this.tos_update_id, CONFIRM_ACCEPT_VALUE);
  },

  track: function(action = close) {
    Analytics.event('ppalert:click', 'view', action);
  },

  handleClick : function(evt) {
    evt.preventDefault();
    if (evt.target.getAttribute('href')) {
      setTimeout(function() { window.location = evt.target.getAttribute('href'); }, 100);
    } else {
      this.track('privacy-agree');
      this.remember();
      Notice.close(this.notice_element) ;
    }
  }
});
export default TOSUpdateNotice;
