import 'promise-polyfill';
import $ from 'jquery';
import { currentCommunity } from 'models/context';

var deferred = $.Deferred();

var dataUrl = function(data) {
  // e.g. <experiment_base_url>/<experiment id>/<community id>/<experiment variant id>/<entry id>.json` ?
  return `${data.experiment_base_url}${data.experiment_id}/${currentCommunity.get('id')}/${data.variant_id}/${data.entry_id}.json`;
};

/*
  Google Optimize experiment -> Chorus loop
  1. Google Optimize script bootstraps on page and fetches active targeted experiment / selects algo variant
  2. Via Optimize suite editor, selected variant assigns its value (eg. var_1) to specific attribute on the link container elem
  3. This method checks for the existence of said attribute every 100ms, until it validates its presence
     - OR - Optimize has set a finished flag on the GTM layer - OR - 4s have expired
*/
var loadData = function(data, el) {
  let $container = $(el);
  currentCommunity.fetch().then(() => {
    // set timeout on optimize fetch
    let timer = window.setTimeout(() => {
      clearInterval(expCheck);
      return fetchRecs(data);
    }, 4000);
    // poll recs container for experiment variant attr populated by optimize
    // or until optimize is finished up processing
    let expCheck = window.setInterval(() => {
      let variantId = $container.attr('data-experiment-alt');
      let optimizeFired = window.dataLayer.hide.end;
      if (variantId || optimizeFired === null) {
        clearTimeout(timer);
        clearInterval(expCheck);
        data.variant_id = variantId;
        return fetchRecs(data);
      }
    }, 100);
  });
};

var fetchRecs = function(data) {
  // If there's no experiment / variant id, we need to ditch out and use the default values
  if (!data.experiment_id || !data.variant_id) {
    deferred.resolve(data.dynamic_links);
    return;
  }
  $.ajax({
    url: dataUrl(data),
    method: 'get',
    format: 'json',
    complete: (xhr) => {
      if (!xhr.responseText) {
        deferred.resolve(data.dynamic_links);
        return;
      }
      try {
        deferred.resolve(JSON.parse(xhr.responseText));
      } catch (e) {
        deferred.resolve(data.dynamic_links);
        console.error(e);
      }
    },
    error: () => {
      deferred.resolve(data.dynamic_links);
    }
  });
};

export default function (data, el) {
  loadData(data, el);
  return deferred.promise();
}
