/**
* Low-level API for opening and closing modal dialog windows.
* Please refrain from using this module directly within components.
* Instead, use the "lib/services" layer for a high-level service API.
* The higher-level API will streamline Modal integration among other services.
*
* @example
* import Modal from 'lib/modal';
*
* Modal.dialog('<b>Hello</b>'); // small-format
* Modal.overlay('<b>Hello</b>'); // large-format
* Modal.close();
*
* @module lib/modal
*/

import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';
import { TRANSITION_END } from 'lib/effects';

const WINDOW_ELEMENT = '<div role="dialog" aria-modal="true" class="p-modal__window" id="modal-window"></div>';
const CLOSE_ELEMENT = '<button class="p-modal__close" id="modal-close">Close</button>';
const ACTIVE_CLASS = 'modal-active';
const OPEN_CLASS = 'modal-open';
const LOADING_CLASS = 'p-modal__loading';
const CLOSE_EVENT = 'click.modal';
const FOCUSABLES_SELECTOR = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

let Modal = _.extend(
/** @lends module:lib/modal */
  {
    $el: null,
    /**
  * Prompts the user with a modal dialog centered on screen at desktop sizes.
  * Centered modal dialog automatically scales to fit the provided content element.
  * This dialog is identical to `.overlay` at mobile sizes.
  * @example
  * +-------------+
  * |             |
  * |     +-+     |
  * |     | |     |
  * |     +-+     |
  * |             |
  * +-------------+
  *
  * Modal.dialog('<b>Hello</b>');
  * Modal.dialog(document.querySelector('#message'));
  * Modal.dialog({ajax: 'https://voxmedia.com'});
  * Modal.dialog({iframe: 'https://voxmedia.com'});
  * @param {String|Element|Object} content HTML or rendered markup to present within the dialog.
  * An options object may also be passed with <code>ajax</code> or <code>iframe</code> URLs.
  * @returns {this}
  */
    dialog: function(content, opts={}) {
      return this._open(content, opts);
    },

    /**
  * Presents the user with a large-format modal overlay,
  * which fills most of the screen at desktop sizes.
  * Overlayed content has a fixed container width sized to the screen.
  * This overlay is identical to `.dialog` at mobile sizes.
  * @example
  * +-------------+
  * | +---------+ |
  * | |         | |
  * | |         | |
  * | |         | |
  * | +---------+ |
  * +-------------+
  *
  * Modal.overlay('<b>Hello</b>');
  * Modal.overlay(document.querySelector('#message'));
  * Modal.overlay({ajax: 'https://voxmedia.com'});
  * Modal.overlay({iframe: 'https://voxmedia.com'});
  * @param {String|Element|Object} content HTML or rendered markup to present within the dialog.
  * An options object may also be passed with <code>ajax</code> or <code>iframe</code> URLs.
  * @returns {this}
  */
    overlay: function(content, opts={}) {
      return this._open(content, _.extend(opts, { fixedSize: true }));
    },

    /**
  * Parses raw input into formatted content.
  * @param {Element|String|Object} content an Element, HTML string, or Object with an <code>ajax</code> or <code>iframe</code> option.
  * @returns {Element|String} formatted content elements or HTML string.
  */
    parse: function(content) {
      var isObject = (typeof content === 'object');
      // Open content as iframe:
      if (isObject && content.iframe) {
        return `<iframe src="${ content.iframe }" width="100%" height="100%"></iframe>`;
      } else if (isObject && content.ajax) {
      // Open content fetched via ajax:
        $.ajax({
          url: content.ajax,
          success: (c) => {
            this.$el && this.$el.find('#modal-ajax').html(c);

            // Now that we’ve populated the modal, look for focusable elements

            this.defineFocusables();
            // Lazy require... addresses a weird issue
            // with presto lib import failing on Jenkins PhantomJS.
            Initializer.run();
          },
          error: () => {
            this.close();
          }
        });
        return '<div id="modal-ajax"><div class="'+ LOADING_CLASS +'">Loading...</div></div>';
      }

      return content || '';
    },

    /**
  * Low-level API for opening a new modal window, replacing any previous modal.
  * Not intended for direct use. Instead, use the high-level "dialog" and "overlay" methods,
  * which are designed to open specially-formatted modal styles.
  * @param {Element|String|Object} content an Element, HTML string, or Object with <code>{ajax: "url"}</code> or <code>{iframe: "url"}</code>.
  * @param {Object} options
  * @returns {this}
  * @private
  */
    _open: function(content, opts) {
      this.close();

      // Let’s assume that the currently-focused element was the trigger for our modal
      this.opener = document.activeElement;

      // Create modal window synchronously...
      this.$el = $(WINDOW_ELEMENT)
        .addClass(opts && opts.fixedSize ? 'fixed-size' : 'auto-size')
        .addClass(opts && opts.className ? opts.className : '')
        .html(this.parse(content))
        .append(CLOSE_ELEMENT);

      this.focusables = [];

      // Find list of focusable elements inside modal
      this.defineFocusables = function() {
        const focusables = this.$el.find(FOCUSABLES_SELECTOR);
        this.focusables = focusables;
      };

      // Function to handle `shift+tab`-driven focus
      const handleBackwardTab = function(e, modal) {
        // Is the FIRST element in our focusables list already selected?
        if (document.activeElement === modal.focusables[0]) {
          // If so, shift focus to the LAST element in our focusables list
          e.preventDefault();
          modal.focusables[modal.focusables.length - 1].focus();
        }
      };

      // Function to handle `tab`-driven focus
      const handleForwardTab = function(e, modal) {
        // Is the LAST element in our focusables list already selected?
        if (document.activeElement === modal.focusables[modal.focusables.length - 1]) {
          // If so, shift focus to the FIRST element in our focusables list
          e.preventDefault();
          modal.focusables[0].focus();
        }
      };

      // When the `tab` key is pressed inside our modal, move focus appropriately
      this.$el.on('keydown', (e) => {
        if (e.keyCode === 9) {
          if (this.focusables.length === 1) {
            e.preventDefault();
          } else {
            if (e.shiftKey) {
              handleBackwardTab(e, this);
            } else {
              handleForwardTab(e, this);
            }
          }
        }
      });

      // Trigger event before starting open transition:
      this.trigger('before:open');

      // Open modal ASYNCHRONOUSLY...
      // Async allows the current event loop to resolve,
      // making sure that a modal opener click doesn't also re-close it.
      setTimeout(() => {
        // Capture current body scroll position:
        var $body = $('body');

        // Display modal overlay and frame:
        $body
          .append(this.$el)
          .addClass(ACTIVE_CLASS)
          .off(CLOSE_EVENT)
          .on(CLOSE_EVENT, (evt) => {
            var $clicked = $(evt.target);
            // Only close modal if user clicks "Close" button to prevent loss of unsaved changes
            if ($clicked.closest($('#modal-close')).length) {
              evt.stopImmediatePropagation();
              this.close();
            }
          });

        // Close modal when escape key is pressed
        $(document).keyup( function(e) {
          if (e.keyCode === 27) {
            Modal.close();
          }
        });

        // Start intro transition on next event loop:
        setTimeout(function() {
          $body.addClass(OPEN_CLASS);
        }, 0);

        // Fire off "open" event.
        this.trigger('open');
        // Find our focusables
        this.defineFocusables();
        // Focus on the first, uh, focusable element inside our modal
        this.focusables[0].focus();
      }, 0);

      return this;
    },

    /**
  * Closes any existing modal window.
  * You may safely call this method without errors at any time,
  * even when there is no modal dialog window active.
  * @example
  * Modal.close();
  * @returns {this}
  */
    close: function() {
      if (!this.$el) return;

      // Trigger event before starting close transition:
      this.trigger('before:close');

      var $body = $('body').off(CLOSE_EVENT);
      var done = () => {
        this.$el.remove();
        this.$el = null;

        // Restore body state, and parse cached scroll position:
        $body.removeClass(ACTIVE_CLASS+' '+OPEN_CLASS+' '+LOADING_CLASS);

        // Fire off "close" event.
        this.trigger('close');
        // Focus on element that triggered the modal, if known.
        this.opener.focus();
      };

      if (TRANSITION_END && $body.css('position') === 'fixed') {
        this.$el.one(TRANSITION_END, done);
        $body.removeClass(OPEN_CLASS);
      } else {
        done();
      }

      return this;
    },

    /**
  * Configures forms within the modal element to submit via Ajax.
  * This configuration only applies to the <em>currently open</em> modal element.
  * Configuration is cleared when the modal window is closed.
  * @returns {Promise} resolved or rejected upon form submission outcome.
  * @example
  * Modal.overlay({ajax: '/get/my/form'})
  *   .ajaxForms()
  *   .done( ... )
  *   .fail( ... );
  */
    ajaxForms: function() {
      var deferred = $.Deferred();

      this.$el && this.$el.on('submit', (evt) => {
        evt.preventDefault();
        var $form = $(evt.target);

        $.ajax({
          url: $form.attr('action'),
          data: $form.serialize(),
          method: 'post',
          success: () => {
            this.close();
            deferred.resolve();
          },
          error: () => {
            deferred.reject();
          }
        });
      });

      return deferred.promise();
    }
  }, Backbone.Events);

export default Modal;
