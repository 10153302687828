import $ from 'jquery';
import templateEngine from 'lib/template';
import Backbone from 'exoskeleton';

var itemTmpl = templateEngine(require('./tmpl/enlarged_template.html'));

function isRightClick(evt) {
  return ( evt.which > 1 || evt.ctrlKey || evt.metaKey );
}

function isNotEnlargeable($el) {
  return $el.parents('.is-not-enlargeable').length > 0;
}

function isTooTall($el) {
  return $el.height() > window.innerHeight;
}

let ImageEnlarger = Backbone.View.extend({
  className: 'is-enlargeable',
  $enlargedOverlay : null,
  $body : $('body'),

  enlarge : function() {
    this._boundClick = this.handleEnlargedClick.bind(this),
    this._boundKeyPress = this.handleKeypress.bind(this),

    this.$enlargedOverlay = $(itemTmpl({ original: this.$el.data('original') }));
    this.$enlargedOverlay.on('click', this._boundClick);
    this.$body.addClass('enlarged-image-active');
    this.$body.on('keydown', this._boundKeyPress);
    this.$body.append(this.$enlargedOverlay);
  },

  removeEnlargedImage: function() {
    if (this.$enlargedOverlay) {
      this.$enlargedOverlay.remove();
      this.$enlargedOverlay.off('click', this._boundClick);
      this.$enlargedOverlay = null;
      this.$body.removeClass('enlarged-image-active');
      this.$body.off('keydown', this._boundKeyPress);
    }
  },

  toggleNoMax: function() {
    this.$enlargedOverlay.toggleClass('no-max');
  },

  events: {
    'click' : 'handleImageClick'
  },

  handleEnlargedClick : function(evt) {
    if (isRightClick(evt)) { return; }
    if (evt.target.nodeName.toLowerCase() === 'img' && !this.$enlargedOverlay.hasClass('no-max')) {
      this.toggleNoMax();
    } else {
      this.removeEnlargedImage();
    }
  },

  handleKeypress: function(evt) {
    if (evt.keyCode === 27) { // esc button
      this.removeEnlargedImage();
    }
  },

  handleImageClick: function(evt) {
    if (isRightClick(evt)) { return; }
    if (isTooTall(this.$el)) { return; }
    if (isNotEnlargeable(this.$el)) { return; }
    this.enlarge();
  }


});
export default ImageEnlarger;
