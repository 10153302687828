import './index.scss';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import storage from 'lib/localstorage';

Initializer.registerComponent('site/breaking_news', function(element, data) {
  $('body').addClass('has-breaking-news');
  if (storage.get('breaking-news') === data.title) {
    $('[data-ui="breaking-news"]').removeClass('visible');
    $('body').removeClass('has-breaking-news');
  }

  $('[data-ui="close-breaking-news"]').on('click', function() {
    $('[data-ui="breaking-news"]').removeClass('visible');
    storage.set('breaking-news', data.title);
  });
}, {
  priority: Initializer.HIGH
});
