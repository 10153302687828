/* global google:false */
import _ from 'lib/utils';
/**
* Marker class builder
* The marker class is built atop a Google Maps overlay object,
* thus we can't configure the marker class until after Google Maps is loaded.
*
* This class requires that the google maps API has been loaded. This should be enforced
* by the fact that the main param is the OverlayView, but we check just in case.
*
* You can include the google maps api like so:

* import googleMapsLoader from 'lib/google_maps';
* googleMapsLoader().then(() => {
*    //init map and marker
* }
*
* @param {OverlayView} base overlay view object provided by Google Maps API.
* @returns {Function} constructor function for generating marker views.
*/

var MarkerViewClass;

function asyncBuildMarkerClass() {
  if (typeof google === 'undefined' || !google.maps) {
    throw 'googleMapsLoader must be run before requesting a googleMapsMarker';
  }

  var baseView = new google.maps.OverlayView();

  /**
  * Map Marker
  * Constructor for instancing map markers.
  * @constructs
  */
  function MapMarkerView(opts) {
    var el = document.createElement('div');
    el.className = opts.cssClass;
    el.setAttribute('data-marker', opts.slug);
    var html = opts.html ||  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve">
        <path d="M10,0.5c2.7-0.1,6.6,1.8,7.1,7c0.4,5.2-7.1,11.6-7.1,11.6l0,0c0,0-7.5-6.4-7.1-11.6C3.4,2.3,7.2,0.5,10,0.5"/>
      </svg><span>${ opts.label }</span>`;

    el.innerHTML = html;
    this.el = el;
    this.latlng = new google.maps.LatLng(opts.lat, opts.lon);
  }

  MapMarkerView.prototype = _.extend(baseView || {},
  /** @lends MapMarkerView.prototype */
    {
      onAdd: function() {
        this.getPanes().overlayMouseTarget.appendChild(this.el);
      },

      onRemove: function() {
        this.el.parentNode.removeChild(this.el);
        this.el = null;
      },

      draw: function() {
        var pos = this.getProjection().fromLatLngToDivPixel(this.latlng);
        this.el.style.left = pos.x + 'px';
        this.el.style.top = pos.y + 'px';
      },

      /**
    * Specifies if the view is visually highlighted.
    * @returns {Boolean}
    */
      isHilited: function() {
        return /\bhilite\b/.test(this.el.className);
      },

      /**
    * Visually highlights the marker.
    * @returns {undefined}
    */
      hilite: function() {
        if (!this.isHilited()) {
          this.el.className += ' hilite';
          this.el.parentNode.appendChild(this.el);
        }
      },

      /**
    * Visually unhighlights the marker.
    * @returns {undefined}
    */
      unhilite: function() {
        if (this.isHilited()) {
          this.el.className = this.el.className.replace(/\shilite\b/, '');
        }
      }
    });

  return MapMarkerView;
}

export default function(opts) {
  MarkerViewClass = MarkerViewClass || asyncBuildMarkerClass();
  return new MarkerViewClass(opts);
}
