import 'promise-polyfill';
import $ from 'jquery';
import Initializer from 'lib/initializer';
import TextBalancer from 'lib/text_balancer';

var deferred = $.Deferred();

Initializer.registerComponent('site/font_loader', function(element, data) {
  const FONTS_LOADED_CLASS = 'fonts-loaded';
  var docEL = document.documentElement;

  function textBalance() {
    if (data.headline_balance_div_classes && data.headline_balance_fraction && data.headline_balance_fraction > 0.0) {
      TextBalancer(data.headline_balance_div_classes, data.headline_balance_fraction);
    }
  }

  if (document.fonts) {
    deferred.resolve();
    textBalance();

  } else {

    function fontsSuccess() {
      docEL.classList.add(FONTS_LOADED_CLASS);
      $(docEL).trigger('fontsloaded', {});
      textBalance();
      deferred.resolve();
    }

    function fontsFailure() {
      // mark fonts as loaded (triggering the display of what fonts did successfully load)
      docEL.classList.add(FONTS_LOADED_CLASS);
      deferred.reject();
    }

    import('./scripts/main')
      .then(module => module.loadFonts(data))
      .then(fontsSuccess, fontsFailure);
  }

}, {
  priority: Initializer.HIGH,

  load: function() {
    return deferred.promise();
  }
});
