import Backbone from 'exoskeleton';
import parseTemplate from 'lib/template';
import viewHTML from './tmpl/on_demand.html';
import _ from 'lib/utils';
import volume from 'lib/video';
import UserAgent from 'lib/user_agent';
import EntryFormatter from '../../entry_formatter';
import $ from 'jquery';

const FULL_SCREEN_EVENT = 'webkitfullscreenchange.videoPlayer mozfullscreenchange.videoPlayer fullscreenchange.videoPlayer MSFullscreenChange';

let VideoEmbedOnDemand = Backbone.View.extend({
  _opts : {},
  template: parseTemplate(viewHTML),
  className : 'c-video-embed__on-demand',

  initialize: function(opts = {}) {
    this._opts = opts;
    this.render();
  },

  render: function() {
    var data = _.extend({
      id_uid : new Date().getTime(),
      volume_autoplay : 'true', // this is used as a string by the embed
      volume_autoplay_with_sound : 'false', // this is used as a string by the embed
      volume_trigger : 'embed'
    }, this._opts);

    // Autoplay videos
    data.video_embed_code = this.addAutoPlayToEmbed(data.video_embed_code);

    this.$el.html(this.template(data));
  },

  embed : function() {
    try {
      volume.embedVideo(this.$('.c-video-embed')[0]);
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => EntryFormatter.formatVideoEmbeds(this.$el), 600);
  },
  play : function() {
    try {
      var ifrm = this.$('.c-video-embed iframe')[0];
      this.$el.addClass('activated');
      var volumeClient = volume(ifrm);
      volumeClient.play();

      if (UserAgent.isMobile() && !UserAgent.iOSversion().ios) {
        var $doc = this.$(document);
        var exitFS = (evt) => {
          if (!UserAgent.isFullScreen()) {
            setTimeout(() => volumeClient.pause(), 1000);
            this.$el.removeClass('activated');
            $doc.off(FULL_SCREEN_EVENT);
          }
        };
        if (ifrm.requestFullscreen) { ifrm.requestFullscreen(); } else if (ifrm.mozRequestFullScreen) { ifrm.mozRequestFullScreen(); } else if (ifrm.webkitRequestFullScreen) { ifrm.webkitRequestFullScreen(); }
        $doc.off(FULL_SCREEN_EVENT).on(FULL_SCREEN_EVENT, exitFS);
      }
    } catch (e) {
      console.log(e);
    }
  },

  addAutoPlayToEmbed: function(embedCode) {
    var doc = document.createElement('html');
    doc.innerHTML = embedCode;
    var iframe = doc.getElementsByTagName('iframe')[0];

    if (iframe) {
      // Allow autoplay: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#iframe
      var allow = (iframe.getAttribute('allow') || '').split(';').concat('autoplay');
      allow = $.unique(allow).
        filter(x => x && x.length > 0).
        join(';');
      iframe.setAttribute('allow', allow);

      // Add ?autoplay=1 to YouTube embed src
      var src = iframe.getAttribute('src');
      if (/youtube\.com/i.test(src)) {
        if (!/\?$/.test(src)) src += '?';                     // append '?'
        if (!/&autoplay=1$/i.test(src)) src += 'autoplay=1'; // append '&autoplay=1' query param
        iframe.setAttribute('src', src);
      }
    }

    return doc.innerHTML;
  }

});
export default VideoEmbedOnDemand;
