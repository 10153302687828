import Backbone from 'exoskeleton';
import currentContext from 'models/context';
import { currentUser } from 'models/context';
import $ from 'jquery';
import _ from 'lib/utils'; // eslint-disable-line
import Analytics from 'lib/analytics';

var HeaderLogin = Backbone.View.extend({
  // Initializes the model with events:
  initialize: function() {
    this.listenTo(currentUser, 'change', this.render);
    this.render();
  },

  $body : $('body'), // deal with it.

  // Renders the nav with the current user state:
  render: function() {
    currentUser.fetch().then(() => {
      if (currentUser.isLoggedIn()) {
        var data = currentUser.toJSON();
        data.logout_url = currentContext.get('auth_url') + '/logout';
        var menu = `
          ${this.shouldToggleShowAndEditProfile(data)}
          <li class="c-global-header__login-menu-item logged_in_item"><a data-ui="logout" href="${ data.logout_url }">Log out</a></li>
        `;

        this.$('[data-ui="menu"] .logged_out_item, [data-ui="menu"] .logged_in_item').remove();
        this.$('.c-global-header__login').removeClass('logged_out_menu').addClass('logged_in_menu');
        this.$('[data-ui="menu"]').append(menu);

        this.showMenuIcon(this.$('[data-ui="icon"]'), data);
        this.$body.addClass('chorus-logged-in');

        if (data.auth0_id) {
          Analytics.event('auth-0', 'auth-0-log-in', 'auth-0-logged-in', data.auth0_id);
        }

        if (data.passes_paywall) {
          Analytics.event('paywall', 'paywall-status', 'passed-paywall', data.paywall_member_id);
        }
      }
    });
  },

  events: {
    'mouseenter .c-global-header__login' : 'onLoginEnter',
    'mouseleave .c-global-header__login' : 'onLoginLeave',
    'focus .c-global-header__login' : 'onLoginEnter',
    'blur .c-global-header__login': 'onLoginLeave',
    'click [data-ui="logout"]': 'onLogoutClick'
  },

  // TOH: since user profiles are hidden, the cursor should change to default
  showMenuIcon: function(el, data) {
    const { currentNetwork: { attributes: { slug } } } = currentContext;
    const menuIcon = (
      (slug === 'thisoldhouse') ? el.css('cursor', 'default') : el.attr('href', currentUser.get('profile_url'))
    ).html(`<span class="sr-only">View profile</span><img src="${ data.profile_image_url }" alt="">`);
    return menuIcon;
  },

  // TOH: user profile should be hidden and diff 'Edit Profile' link
  shouldToggleShowAndEditProfile: function(data) {
    const { currentNetwork: { attributes: { slug } } } = currentContext;
    return  slug === 'thisoldhouse'
      ? '<li class="c-global-header__login-menu-item logged_in_item"><a href="https://profile.thisoldhouse.com/my-account/edit-account/">My Profile</a></li>'
      : `<li class="c-global-header__login-menu-item logged_in_item"><a href="${ data.profile_url }">${ data.username }<span class="sr-only"> (view profile)</span></a></li>
      <li class="c-global-header__login-menu-item logged_in_item"><a href="${ data.edit_profile_url }">Edit Profile</a></li>`;
  },

  onLoginEnter : function() {
    this.$el.addClass('is-login-active');
    this.action_to = null;
  },

  onLoginLeave: function() {
    this.action_to = setTimeout(()=> {
      if (this.action_to !== null) {
        this.$el.removeClass('is-login-active');
      }
    }, 200);
  },

  onLogoutClick: function(evt) {
    var link = evt.currentTarget;
    var href = link.href;
    var form = document.createElement('form');
    var input = document.createElement('input');
    input.type = 'submit';
    form.appendChild(input);
    form.method = 'post';
    form.action = href;
    form.style.display = 'none';
    document.body.appendChild(form);
    input.click();
    evt.preventDefault();
  },

  onLinkClick: function(evt) {
    Analytics.nav('click', evt.currentTarget.innerText.trim());
  },

});

export default HeaderLogin;
