import './index.scss';
import 'promise-polyfill';
import Initializer from 'lib/initializer';

var pending;

function load() {
  pending = pending || import(/* webpackChunkName: "app-poll" */ './scripts/main');
  return pending;
}

Initializer.registerComponent('apps/poll', function(element, data) {
  load().then(module => new module.default(element, data));
});
