/**
* <p>Fetches social counts for a URL (shares, likes, pins, etc).</p>
* <p>The default model is preconfigured to fetch counts for the current page URL.
* When fetching, a separate network request must be made for each social service,
* so it is encouraged that you be judicious about the services requested.</p>
* <p>If you need counts for a URL other than the current page, then you may
* build a new model instance for the URL, and fetch counts through that model.</p>
* <p>NOTE: Twitter social counts are no longer supported due to
* <a href="https://blog.twitter.com/2015/hard-decisions-for-a-sustainable-platform">removal of the API</a>.</p>
* @example
* import SocialCounts from 'models/social_counts';
*
* // Fetch counts for the current page URL:
* SocialCounts.fetch('pinterest', 'facebook', ...).always(function() {
*   // ... all share counts are ready now (success or failure) ...
*   var pins = SocialCounts.get('pinterest');
*   var shares = SocialCounts.get('facebook_shares');
* });
*
* // Bind events onto current page SocialCounts:
* SocialCounts.on('change', function() { ... });
*
* // Fetch counts for another page URL:
* var counts = SocialCounts.forUrl('https://aweso.me');
* counts.fetch('pinterest', 'facebook', ...).always(function() {
*   // ... all share counts are ready now (success or failure) ...
* });
* @module models/social_counts
*/
import Backbone from 'exoskeleton';
import Ajax from 'lib/ajax';
import _ from 'lib/utils';

let SocialCounts = Backbone.Model.extend(Ajax.extend(
/** @lends models/social_counts.SocialCounts.prototype */
  {
  /** @constructs */

    urls: {
      facebook: 'https://api.facebook.com/method/links.getStats',
      linkedin: 'https://www.linkedin.com/countserv/count/share',
      pinterest: 'https://widgets.pinterest.com/v1/urls/count.json'
    },

    ajaxOptions: {
      method: 'get'
    },

    defaults: {
      url: location.href.split('?')[0],
      // "twitter" will always be blank now that the API has been removed...
      // the field is left so that components can still request the empty data.
      twitter: null,
      facebook: null,
      facebook_shares: null,
      facebook_likes: null,
      linkedin: null,
      pinterest: null
    },

    /**
  * Checks if there is a count available for the requested service.
  * Use this check instead of pulling a count number and testing its truthiness
  * (because zero is a valid count, but will fail truthiness checks).
  * @param {String} service name of the service count to check.
  * @returns {Boolean} true if a count number is available for the service.
  * @example
  * var count = '';
  * if (SocialCounts.hasCount('facebook')) {
  *   count = ' ('+ SocialCounts.get('facebook') +')';
  * }
  * this.$el.text('Shares' + count);
  */
    hasCount: function(service) {
      return typeof this.get(service) === 'number';
    },

    /**
  * Fetches data for the requested services.
  * Each service must be requested separately, so be judicious about what services are requested.
  * @param {...String} services the names of the services to fetch data for.
  * @returns {Promise} resolved after all requested services have been fetched.
  * @example
  * SocialCounts.fetch('pinterest', 'facebook').always(function() {
  *   // ... all social counts have loaded (success or failure) ...
  * });
  */
    fetch: function(...services) {
    // Create a pending cache:
      if (services[0] instanceof Array) services = services[0];
      var cache = this._c = this._c || {};
      var promises = [];

      for (var i = 0; i < services.length; i++) {
        var service = services[i];
        if (this.urls[service]) {
          if (!cache[service]) {
            cache[service] = this[service === 'facebook' ? '_fb' : '_other'](service);
          }
          promises.push(cache[service]);
        }
      }

      return _.all(promises);
    },

    /**
  * Fetches data from the unique Facebook API.
  * @private
  */
    _fb: function(service) {
      return this.ajax(service, {
        data: { urls: this.get('url'), format: 'json' },
        success: (res) => {
          if (res && res.length) {
            this.set({
              facebook: res[0].share_count,
              facebook_shares: res[0].share_count,
              facebook_likes: res[0].like_count
            });
          }
        }
      });
    },

    /**
  * Fetches data from the Pinterest and LinkedIn APIs,
  * all of which conveniently share the same prototcol and return format.
  * @private
  */
    _other: function(service) {
      return this.ajax(service, {
        dataType: 'jsonp',
        data: { url: this.get('url'), format: 'jsonp' },
        success: (res) => {
          this.set(service, res.count);
        }
      });
    }
  }));

export default new SocialCounts();

/**
* Builds a counts model for another URL.
* @param {String} other URL.
* @returns {SocialCounts} a new SocialCounts model instance for the requested URL.
*/
export function socialCountsForUrl(url) {
  return new SocialCounts({ url: url });
}
