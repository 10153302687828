import './index.scss';
import Initializer from 'lib/initializer';
import DonationFormView from './scripts/view';

Initializer.registerComponent('snippets/donation_form', function(element) {
  new DonationFormView({ el: element });
}, {
  priority: Initializer.MEDIUM
});

