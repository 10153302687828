import Backbone from 'exoskeleton';
import $ from 'jquery';
import _ from 'lib/utils';

let ToasterView = Backbone.View.extend({
  initialize: function(props) {
    this.opts = props.data;
    if (this.shouldShowToaster()) {
      this.$el.addClass('c-toaster-active');
      this.checkScrollPosition();
      $(document).on('scroll', _.throttle(this.checkScrollPosition.bind(this), 300));
    }
  },

  events: {
    'click #closeToaster': 'closeToaster',
    'click a,button': 'hideToaster'
  },

  hideToaster: function() {
    window.localStorage.setItem('hide_toaster', Date.now());

    // the newsletter toaster stays at the bottom of the page, but with the CTA variation, we want to hide it entirely.
    if (this.opts.show_toaster_cta) {
      this.$el.addClass('c-toaster-hidden');
    }
  },

  closeToaster: function() {
    this.$el.removeClass('c-toaster-stuck');
    this.hideToaster();
    $(document).off('scroll');
  },

  checkScrollPosition: function() {
    const position = $(window).scrollTop();
    const showPosition = parseInt(this.opts.scroll_depth);
    const isVisible = this.$el.hasClass('c-toaster-stuck');
    if (position > showPosition) {
      if (!isVisible) {
        this.$el.addClass('c-toaster-stuck');
      }
    } else if (isVisible) {
      this.$el.removeClass('c-toaster-stuck');
    }
  },

  shouldShowToaster: function() {
    if (this.opts.use_visited_limit) {
      return this.showToasterUsingVisitedPagesLimit();
    } else {
      return this.showToasterUsingDismissInterval();
    }
  },

  showToasterUsingDismissInterval: function() {
    const toasterDate = window.localStorage.getItem('hide_toaster');
    if (toasterDate) {
      const dateNow = Date.now();
      let milliSecondsToExpire = this.opts.dismiss_interval * 24 * 60 * 60 * 1000; // h, m, s, ms
      if ((dateNow - toasterDate) > milliSecondsToExpire) {
        window.localStorage.removeItem('hide_toaster');
        return true;
      }
      return false;
    }
    return true;
  },

  showToasterUsingVisitedPagesLimit: function() {
    let numberOfPagesVisited = sessionStorage.getItem('pagesVisited');
    if (!numberOfPagesVisited) {
      sessionStorage.setItem('pagesVisited', 1);
    } else {
      sessionStorage.setItem('pagesVisited', parseInt(numberOfPagesVisited) + 1);
    }
    const numberOfPagesVisitedBeforeToasterShouldAppear = this.opts.visited_limit;
    if (parseInt(sessionStorage.getItem('pagesVisited')) > numberOfPagesVisitedBeforeToasterShouldAppear) {
      sessionStorage.setItem('pagesVisited', 0);
      return true;
    }
  }

});

export default ToasterView;
