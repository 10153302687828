import Initializer from 'lib/initializer';
import $ from 'jquery';
import _ from 'lib/utils';
import Sharing from 'lib/sharing';

let Formatter = {
  embedSanitizer : function($target) {
    var entryBodyRelativeVideoSelector = [
      'iframe[src*="youtube.com"]',
      'iframe[src*="vine.co"]',
      'iframe[src*="ooyala.com"]',
      'iframe[src*="gfycat.com"]',
      'iframe[src*="giphy.com"]',
      'iframe[src*="iheart.com"]',
      'iframe[src*="theplatform.com"]', // NBC
      'iframe[src*="twitch.tv"]',
      'div[id*="ooyalaPlayer"]',
      'iframe[src*="watchable.com"]',
      'iframe[src*="graphics.suntimes.com"]',
      'iframe[src*="streamable.com"]',
      'iframe[src*="vimeo.com"]',
      'iframe[src*="tmz.com"]',
      'iframe[src*="players.brightcove.net"]'
    ].join(', ');

    $target.find(entryBodyRelativeVideoSelector).each((idx, item) => {
      var $it = $(item);

      $it.find('object, embed, video').attr('width', '100%').attr('height', '100%');
      // ooyala HTML5 video player support
      $it.find('.OoyalaHtml5VideoPlayer, .oo_promoImag').css({ 'width': '100%', 'height': '100%' });
      $it.find('table').addBack().removeAttr('width height style');

      // Check to see if this is a manually-created flexible object.
      // If the responsive container doesn’t already exist, create it.
      //
      // (Per BUG-395, FOD manually created a `.iframe-container` class to patch
      // these embeds in their stories. We don’t want to conflict with those styles, so
      // let’s ignore objects inside that responsive container.)
      if ($it.parents('.p-scalable-video, .iframe-container').length < 1) {
        // A non-zero value for padding-bottom means embed came from
        // iframe.ly and already contains responsive CSS.
        // Remove the padding-bottom and height to avoid
        // the double responsive wrapping with div.p-scalable-video.
        if ($it.parent().css('padding-bottom') !== '0px') {
          $it.parent().css('padding-bottom', 0);
          $it.parent().css('height', 'auto');
        }

        $it.wrap('<div class="p-scalable-video"></div>');
      }
    });

    // MLB Embed come with some problematic js that reassigns the height at runtime
    // Since we are using flexbox for the layout, it can be the case that the width
    // of the main column can change at runtime. This JS simply recalculates the correct
    // height values and reloads the i-frame.
    // Since these get pumped in from iframely, we really have limited control over these embeds.

    var mlbSelfSizingSelector = [
      'iframe[src*="mlb.com"][data-aspect][data-src]'
    ].join(', ');

    $target.find(mlbSelfSizingSelector).each((idx, item) => {
      var $it = $(item);
      var w = $it.width();
      var h = Math.round( w / parseFloat($it.attr('data-aspect')));
      $it.attr('src', $it.attr('data-src') + 'width='+w+'&height='+ h ).css({ 'height' : h+'px' });
    });

    // Facebook, in an awesome turn of events, likes to size its embeds to a ratio that is non-standard!
    // Super helpful, thanks facebook.
    //
    // In these cases we want to remove the aspect-ratio-sizing that gets applied, and let the video size itself
    var fbEmbedsSelector = ['.fb-video', 'iframe[src*="facebook.com"]'].join(', ');

    $target.find(fbEmbedsSelector).each((idx, item) => {
      var videoItem = $(item);
      videoItem.parents('.c-video-embed').removeClass('c-video-embed').addClass('c-video-embed--self-sizing');
      videoItem.parents('.p-modal__window').css('max-width', (window.screen.height * .75) + 'px');
      videoItem.parents('.p-scalable-video').children().unwrap();
    });

    // Instagram and Imgur both add a bunch of inline styles that need to be pulled, lest the embeds break visually.
    // :knife: media embeds.
    var unalignedEmbedsSelector = [
      '.instagram-media',
      'iframe[src*="imgur.com"]',
      'iframe[src*="embed.tumblr.com"]'
    ].join(', ');

    $target.find(unalignedEmbedsSelector).each((idx, item) => {
      $(item).css({ 'marginLeft': 'auto', 'marginRight': 'auto', 'position': 'relative' });
    });

    var scrollEnabledEmbedsSelector = [
      'iframe[src*="cf.datawrapper.de"]'
    ].join(', ');

    $target.find(scrollEnabledEmbedsSelector).each((idx, item) => {
      $(item).attr('scrolling', 'yes').attr('scrollingenabled', 'yes').attr('height', '700');
    });

    // google form iframes need to be sized so that the outer iframe is the same size as the content,
    // as they also use scrolling="no" and start off at a small size.

    var googleFormEmbedsSelector = [
      'iframe[src*="google.com/forms"]:not([style], [width], [height])'
    ].join(', ');
    $target.find(googleFormEmbedsSelector).each((idx, item) => {
      var $it = $(item);
      $it.attr('scrolling', 'auto');
      $it.css({ 'width': '100%' });
    });

  },
  hrIllo : function($target) {
    var hrSelector = 'hr.p-entry-hr';
    var illo = $('#svg_hr_illustration').html();
    if (!illo) { return; }
    $target.find(hrSelector).each((idx, item) => {
      var $hr = $(item);
      // do this rather than "wrap", bacuse the illi is an svg
      // and gets meesed up with the dom
      $hr.replaceWith('<div class="p-hr-illo"><span class="p-hr-illo__box">' + illo + '</span>' + $hr[0].outerHTML +'</div>');
    });
  },
  dropCap : function($target) {
    var dropcapSelector = '.p--has-dropcap';
    var modifyButtonSelector = '.p-anthem-selection';

    $target.addBack().find(dropcapSelector).each((idx, item) => {
      var container = $(item);

      if ( 'createTreeWalker' in document ) {

        // Just a quick method to find the closest ancestor with the matching selector
        // (`Element.contains()` would be better here, but most IE polyfills I’ve seen are…a little involved)
        var getClosest = function ( elem, selector ) {
          if ( !Element.prototype.matches ) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
          }

          for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) {
              return elem;
            }
          }

          return null;
        };

        // Find all the text nodes in our container
        var tree = document.createTreeWalker( container[ 0 ], NodeFilter.SHOW_TEXT, null, false );
        var isAdmin = container[ 0 ].querySelector( modifyButtonSelector );

        // Compile all our text nodes
        var nodes = [];
        var current;
        while ( ( current = tree.nextNode() ) ) {
          if ( current.nodeValue.trim().length > 0 ) {
            // Check to see if we’re in the editor
            if ( isAdmin ) {
              // If we are, is this text node part of an admin button?
              if ( !current.parentNode.classList.contains( modifyButtonSelector ) && getClosest( current.parentNode, modifyButtonSelector ) === null ) {
                nodes.push( current );
              }
            } else {
              nodes.push( current );
            }
          }
        }

        if ( nodes.length ) {
          // The first text node is the one we’ll be manipulating
          var firstNode = nodes[ 0 ];

          // Let’s strip off leading spaces
          var nodeText = firstNode.nodeValue.replace(/^\s+/, '');
          // Let’s get the first ~word from that text
          var firstWord = nodeText.split( ' ' )[ 0 ];
          // Now, let’s store a variable for…everything else after the first word
          var textRemainder = nodeText.substring( firstWord.length );

          // Let’s pick the first word apart a bit: namely, we want to capture everything *up to and including* the first letter, as well as any immediately-following punctuation. [BUG-420]
          // Basically, we’re trying to replicate the expected behavior of `:first-letter`, which is terribly, terribly named. (https://codepen.io/beep/pen/ZZYJWO)

          // Here, we’re defining a regular expression that includes:
          // a.) ASCII letters [a-zA-Z],
          // b.) Numbers [0-9],
          // c.) A sizable-but-narrow range of accented Unicode characters [\u00C0-\u017F] (Display this unicode range: http://qaz.wtf/u/show.cgi?show=00C0-017F&type=hex),
          //
          // NOTE / TODO: If we ever get into non-Latinate alphabets, this needs to be revisited.
          var unicodeRange = 'a-zA-Z0-9\u00C0-\u017F';

          // Given an input string of `“…Matthew” watched the storm` This .match() will return:
          // - [0] The first complete match of everything that, uh, matches the regular expression (`“…M`)
          // - [1] The first “real” letter
          var dropCapRegEx = new RegExp( '^[^' + unicodeRange + ']*([' + unicodeRange + '])[^' + unicodeRange + ']*' );
          var dropCapSearch = firstWord.match( dropCapRegEx );

          // Did our regular expression return anything? If so, we can carry on.
          if ( dropCapSearch.length ) {
            // This is the text we’ll be displaying in our drop cap
            var textToDropCap = dropCapSearch[ 0 ];
            // Let’s store the first “real” letter in the first word, so we can drop it into a class
            var letterInitial = dropCapSearch[ 1 ];

            // Below, we’ll define some wrapper elements for our drop cap, the “real” initial word, and for the content that follows it. In other words, we’re going to take this:
            //
            // <p class="p--has-dropcap">Firstword rest of the text goes here…</p>
            //
            // And turn it into this:
            //
            // <p class="p--has-dropcap">
            //   <span role="text">
            //     <span aria-hidden="true">
            //       <span class="dropcap">F</span>irstword
            //     </span>
            //     <span class="sr-only">Firstword</span>
            //   </span>
            //  rest of the text goes here…
            // </p>
            //
            // (For more info, see [AUD-1278] in Jira.)

            // This is the outer span element, which wraps our entire markup structure
            var $dropcapWrapper = $( '<span role="text"></span>' );

            // This is the drop cap, which is hidden from assistive technology
            var $dropCap = $( '<span aria-hidden="true" class="dropcap-outer"><span class="dropcap">' + textToDropCap + '</span>' + firstWord.substring( textToDropCap.length ) + '</span>' );
            // This is the “real” first word, which is revealed to assistive technology but HIDDEN from sighted readers
            var $realWord = $( '<span class="sr-only">' + firstWord + '</span>' );

            // Set the value of the text node to the “remainder” string, since the first word’s going to sit inside our dropcap markup
            firstNode.nodeValue = textRemainder;

            // Assemble the mess o’ markup for our drop cap
            $dropcapWrapper.append( $dropCap, $realWord );

            // Inject the drop cap before the “rest” of the content
            firstNode.parentNode.insertBefore( $dropcapWrapper[ 0 ], firstNode );

            // Decorate the container with the `.drop-letter-*` class
            container.addClass(' drop-letter-' + letterInitial.toLowerCase());
          }
        }
      }
    });
  },
  captionSpacingNormalizer: function($target) {
    var captionSelector = '.caption';

    $target.find(captionSelector).each((idx, item) => {
      var $cap = $(item);
      $cap.prevUntil('figure, img, .p-fullbleed-block, .wide-image-block, .c-wide-block, div[data-volume-uuid], .p-scalable-video', 'p:empty').remove();

      var $meta = $cap.prev('.e-image').find('.e-image__meta');
      if ($meta.length > 0 ) {
        $meta.prepend(`<figcaption>${ $cap.text() }</figcaption>`);
        $cap.remove();
      }

      var $snippetImages =  $cap.prev('.p-fullbleed-block, .wide-image-block, .c-wide-block').find('.e-image');

      if ($snippetImages.length > 0) {
        $cap.addClass('offset');
      }
    });
  },
  imageEnlarge : function($target) {
    var enlargeableImagesSelector = '.e-image__image';
    var enlargeableImages = $target.find(enlargeableImagesSelector);
    if (enlargeableImages.length < 1) { return; }
    enlargeableImages.each((idx, image) => {
      Initializer.get('site/image_enlarge').build($(image));
    });
  },
  dataTables : function($target) {
    // remove the 'zebra' class to support sbn legacy stuff
    var tables = $target.find('table:not([class]), .sbn-boxscore-table').addClass('p-data-table');
    $target.find('table.zebra').addClass('p-striped-table');
    var bodyWidth = $target.width();
    tables.each(function(idx, item) {
      var $item = $(item);
      var itemWidth = $(item).width();
      if (itemWidth > bodyWidth) {
        $item.wrap('<div class="p-overflow-wrapper"></div>');
      }
    });
  },
  ratingsSnippet : function($target) {
    $target.find('.ratingbox .rating-number').each(function( i, e ) {
      $( e ).html( $( '<span>' ).width( $( e ).text() * 25 ));
    });
  },
  shareQuote : function($target) {
    var url = $('meta[property="og:url"]').attr('content');

    $target.find('.s-share-quote').each(function() {
      var text = encodeURIComponent($(this).find('.s-share-quote__text').html());

      var facebookShareUrl = Sharing.formatSharer(url, 'facebook');
      var twitterShareUrl = Sharing.formatSharer(url, 'twitter', { 'text' : text });

      // Add social buttons to the image
      $(this).append(`<ul class="c-social-buttons c-social-buttons--bright">
        <li class="c-social-buttons__item"><a class="c-social-buttons__item-link c-social-buttons__twitter" href="${twitterShareUrl}" data-analytics-social="twitter"><svg class="p-svg-icon c-social-buttons__svg"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-twitter"></use></svg><span class="c-social-buttons__text">tweet</span></a></li>
        <li class="c-social-buttons__item"><a class="c-social-buttons__item-link c-social-buttons__facebook" href="${facebookShareUrl}" data-analytics-social="facebook"><svg class="p-svg-icon c-social-buttons__svg"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-facebook"></use></svg><span class="c-social-buttons__text">share</span></a></li>
      </ul>`);
    });
  },
  snippetAnimations : function($target) {
    var animatedSelector = '[data-animated]';
    var animatedSnippets = $target.find(animatedSelector);
    if (animatedSnippets.length < 1) { return; }
    animatedSnippets.each((idx, snippet) => {
      Initializer.get('site/snippet_animation').build(snippet);
    });
  },

  listicleSharing : function($target) {
    var listItems = $target.find('.js-social-item');
    if (listItems.length < 1) { return; }
    var url = $('meta[property="og:url"]').attr('content');
    $('body').on('click', '.js-social-item a[data-analytics-social]', function(evt) {
      evt.preventDefault();
      var itemId = $(this).closest('.js-social-item').attr('id');
      var itemUrl = url + '#' + itemId;
      var service = $(this).attr('data-analytics-social');
      Sharing.share(itemUrl, service);
    });
  },

  refreshTwitterEmbeds : function() {
    _.get(window, 'twttr.widgets.load', $.noop)();
  },

  refreshInstagramEmbeds : function() {
    _.get(window, 'instgrm.Embeds.process', $.noop)();
  },

  // Vox media tools js has a problematic reliance on jquery being loaded before they can be called,
  // and since unison delays the load of jquery, they are erroring out.
  // luckilly the tools js is pretty consistent in the way it uses jquery closures, e.g.
  //
  // ImageSlider.Tool = ( function ( $ ) { ... } )( jQuery );

  // This filter simply reloads the tools js at a point after jquery is in memory, and
  // it runs as normal.
  legacyVoxmediaToolsSupprt : function($target) {
    $target.find('script[src*="//apps.voxmedia.com/tools"], script[src*="footnote-script"]').each((idx, item)=> {
      _.loadScript($(item).attr('src'));
    });
  },

  pinterestEmbeds : function() {
    if ($('*[data-pin-do]').length > 0 && $('script[src*="pinit.js"]').length < 1) {
      _.loadScript('https://assets.pinterest.com/js/pinit.js');
    }
  },

  wideImageCompensation: function($target) {
    $target.find('.c-wide-block, .wide-image-block').each((idx, item) => {
      var $block = $(item);
      var $img = $block.find('img');
      if ($img.length < 1 || !$img.hasClass('lazy-image')) { return; }
      if ($img.hasClass('lazy-loaded')) {
        $block.addClass('is-loaded');
      } else {
        $img.on('load', ()=>{ $block.addClass('is-loaded'); });
      }
    });
  },

  narrowImageWidth: function($target) {
    var colWidth = $target.width();
    $target.find('.c-picture img').not('.p-fullbleed-block img').each((idx, item) => {
      var img = $(item);
      if ((img.width() < colWidth) && img.attr('data-upload-width')) {
        img.css({ 'width': img.attr('data-upload-width') + 'px' });
      }
    });
  }

};

export default Formatter;
