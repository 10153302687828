// These al get run before the DOM is ready, so don't make any assuptions about what is available
let RuntimeFormatter = {
  loadTwitter : function(force = false) {
    if (!window.twttr) {
      if (document.querySelector('[class^="twitter-"]') || force) {
        window.twttr = (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0],
            t = window.twttr || {};
          if (d.getElementById(id)) return t;
          js = d.createElement(s);
          js.id = id;
          js.defer = true;
          js.src = 'https://platform.twitter.com/widgets.js';
          fjs.parentNode.insertBefore(js, fjs);

          t._e = [];
          t.ready = function(f) {
            t._e.push(f);
          };
          return t;
        }(document, 'script', 'twitter-wjs'));
      }
    }
  },
  fullBleed : function(target) {
    if (!target) { return; }
    var currentStyleNode = document.getElementById('full-bleed-style');
    var offset = target.offsetLeft;
    var style = document.createElement('style');
    style.id = 'full-bleed-style';
    style.innerHTML = '.fullbleed-block, .p-fullbleed-block{max-width:none !important;margin-left:-'+ offset +'px !important;width:'+ window.innerWidth +'px !important;}';
    if (currentStyleNode) {
      currentStyleNode.parentNode.replaceChild(style, currentStyleNode);
    } else {
      document.head.appendChild(style);
    }
  }
};

export default RuntimeFormatter;
