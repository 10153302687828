import '../social_buttons_list';
import './index.scss';
import Initializer from 'lib/initializer';
import { currentUser } from 'models/context';
import TabBarIndexView from './scripts/view';

Initializer.registerComponent('site/tab_bar/index', function(element, data) {
  currentUser.fetch().done(() => {
    new TabBarIndexView({ el: element, data });
  });
});
