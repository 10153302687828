import LocalStorage from 'lib/localstorage';
import TrackAndRecord from './track_and_record';

let Messaging = {

  displayMessaging: function(selector, messagingUrls) {
    if (this.checkForFeatureLayout()) return;

    let messagingUrl = messagingUrls[this.getMessagingVariant()] || this.chooseMessagingVariant(messagingUrls);

    TrackAndRecord.allowlistView();

    this.appendMessaging(selector, messagingUrl);
  },

  chooseMessagingVariant: function(messagingUrls) {
    let randomIdx = Math.floor(Math.random() * messagingUrls.length);

    LocalStorage.set('whitelist_messaging_variant', randomIdx);
    return messagingUrls[randomIdx];
  },

  getMessagingVariant: function() {
    return LocalStorage.get('whitelist_messaging_variant');
  },

  checkForFeatureLayout: function() {
    if (document.querySelector('.entry_template_minimal')) {
      return true;
    }
  },

  checkForArticlePage: function() {
    if (document.querySelector('.entry_template_standard')) {
      return true;
    }
  },

  appendMessaging: function(selector, url, isTest) {
    if (isTest) {
      let leaderboard = document.querySelector('.m-ad__desktop_leaderboard_variable');
      if (leaderboard) {
        leaderboard.innerHTML = '';
        leaderboard.style.display = 'none';
      }
    }

    let parentSelector = document.querySelector(selector);

    let iframeWrapper = this.createIframeWrapper();
    let iframe = this.createIframe(url);

    iframeWrapper.appendChild(iframe);
    parentSelector.appendChild(iframeWrapper);
  },

  createIframeWrapper: function() {
    let iframeWrapper = document.createElement('div');
    iframeWrapper.className = this.checkForArticlePage() ? 'adblock-allowlist-messaging__article-wrapper' : 'adblock-allowlist-messaging__wrapper';

    return iframeWrapper;
  },

  createIframe: function(url) {
    let iframe = document.createElement('iframe');

    iframe.className = 'adblock-allowlist-messaging__iframe';
    iframe.src = url;
    iframe.scrolling = 'no';
    iframe.frameBorder = 0;
    iframe.title = 'Adblock Allowlist Instructions';

    return iframe;
  }

};

export default Messaging;
