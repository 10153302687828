/* global google:false */
import $ from 'jquery';
import { currentNetwork } from 'models/context';

var pending;

function getUrlOpts(opts) {
  var urlOpts = {
    callback: '_googleMapsAPILoaded',
    libraries: opts.libs || 'places,geometry',
    sensor: false,
    v: 3
  };

  var apiKey = currentNetwork.get('google_maps_api_key');
  if (apiKey) {
    urlOpts.key = apiKey;
  }

  return urlOpts;
}

function getApiUrl(opts) {
  var urlOpts = getUrlOpts(opts);
  var params = [];
  for (var key in urlOpts) params.push(key +'='+ urlOpts[key]);
  return 'https://maps.googleapis.com/maps/api/js?'+ params.join('&');
}

/*
* import googleMapsLoader from 'lib/google_maps';
* googleMapsLoader().then(() => {
*    //init map and marker
* }
*/
export default function(opts) {
  opts = opts || {};
  if (!pending) {
    pending = $.Deferred();
    if (!global.google || !global.google.maps) {

      currentNetwork.fetch().done(() => {
        global._googleMapsAPILoaded = function() { pending.resolve(google.maps); };
        $.getScript(getApiUrl(opts));
      });

    } else {
      pending.resolve(google.maps);
    }
  }
  return pending.promise();
}
