/**
* <p>Manages the tracking and callback of elements entering the viewport.
* This is designed as a generic service to allow any element from any component
* to callback upon entering the viewport.</p>
*
* <p>Callbacks may be configured to occur when the element is just
* outside or inside of the viewport.</p>
*
* @example
* import Viewport from 'lib/viewport';
*
* Viewport.trackElement(<element>, function(el) {
*   console.log('Hey, this is coming into view: ', el);
* });
*
* @module lib/viewport
*/

import _ from 'lib/utils';

let Viewport = {
  els: [],

  _h: null, // Height cache
  _t: null, // Top cache
  _b: null, // Bottom cache

  /**
   * Tests all tracked elements, and invokes callback for visible elements:
   * Elements are omitted from the tracking after being called,
   * UNLESS their callback returns `true` indicating to keep the element tracked.
   * @returns {undefined}
   * @private
   */
  callElementsInViewport: function() {
    for (var i = this.els.length-1; i >= 0; i--) {
      var trackable = this.els[i];

      if (this.testElementInViewport(trackable.el, trackable.t)) {
        var keep = trackable.fn.call(null, trackable.el);
        if (!keep) this.els.splice(i, 1);
      }
    }
  },

  /**
   * Tests a single element for visibility within the viewport.
   * @param {Element} element instance to test.
   * @param {Number} [tolerance] percentage to expand or contract viewport bounding checks.
   * <ul>
   *   <li>Specify a negative percentage (<code>-0.5</code>) to broaden bounding checks to see outside of the viewport.
   *   No limit on allowed percentage.</li>
   *   <li>Specify zero (<code>0</code>) to perform bounding checks using the actual viewport bounds.</li>
   *   <li>Specify a positive percentage (<code>0.25</code>) to narrow bounding checks to a limited space within the visible viewport.
    *   Maximum limit of 0.5 percent (half-height reduction from both top and bottom of viewport).</li>
   * </ul>
   * @returns {Boolean} true if element is within the tracked viewport range.
  * @example
  * import viewport from 'lib/viewport';
  *
  * viewport.testElementInViewport(<element>);
  * viewport.testElementInViewport(<element>, 0);
  */
  testElementInViewport: function(el, tolerance=0) {
    var viewportHeight = this._h;

    // Test for height, and compute as needed:
    if (!viewportHeight) {
      viewportHeight = this._h = window.innerHeight || document.documentElement.clientHeight;
    }

    // Maintain `_t` and `_b` properties for testing validations:
    var viewportTop = this._t = tolerance * viewportHeight;
    var viewportBottom = this._b = viewportHeight - viewportTop;
    var bb = el.getBoundingClientRect();

    return (bb.top >= viewportTop && bb.top <= viewportBottom) ||
      (bb.bottom >= viewportTop && bb.bottom <= viewportBottom);
  },

  /**
   * Adds an element to the trackable queue.
   * @param {Element} element to track within viewport.
   * @param {Function} function to invoke upon entering viewport.
   * @param {Number} [tolerance] percentage to expand or contract viewport bounding checks.
   * @returns {undefined}
  * @example
  * import viewport from 'lib/viewport';
  *
  * viewport.trackElement(<element>, function(el) {
  *   console.log('Hey, this is coming into view: ', el);
  * });
   */
  trackElement: function(el, fn, tolerance) {
    var keep = true;

    // Test if element is already in viewport.
    // if so, callback immediately:
    if (this.testElementInViewport(el, tolerance)) {
      keep = fn(el);
    }

    // If we're keeping the element even after calling it,
    // then add it into the queue:
    if (keep) {
      this.els.push({
        el: el,
        fn: fn,
        t: tolerance
      });

      // Initialize scroll tracking:
      // (this will only allow itself to run once)
      if (!this._init) {
        this._init = true;
        window.addEventListener('scroll', _.throttle(this.callElementsInViewport, 200, this));
        window.addEventListener('resize', _.throttle(this.refresh, 200, this));
      }
    }
  },

  /**
  * Refreshes the viewport by clearing cached dimensions,
  * and then re-checking all elements.
  * @private
  */
  refresh: function() {
    this._h = null;
    this.callElementsInViewport();
  }
};

export default Viewport;
