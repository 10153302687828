import Initializer from 'lib/initializer';
import Backbone from 'exoskeleton';
import currentContext from 'models/context';
import { currentUser } from 'models/context';
import $ from 'jquery';
import _ from 'lib/utils';
import Analytics from 'lib/analytics';
import UserAgent from 'lib/user_agent';

const CLICK_OFF_NS = 'click.search-bar';
const SEARCH_ACTIVE_CLASS = 'is-search-active';

var GlobalHeaderView = Backbone.View.extend({
  // Initializes the model with events:
  initialize: function(opts) {
    this.listenTo(currentUser, 'change', this.render);

    if (opts.data && opts.data.nav_items_limit) {
      this.nav_items_limit= opts.data.nav_items_limit;
    }
    this.render();
    // Wait for fonts to finish loading before calculating nav item widths
    Initializer.get('site/font_loader').load().then(() => {
      this.setNavItemsDisplay();
    });
    this.identifySubmenus();
    $(window).on('resize', _.debounce(this.setNavItemsDisplay.bind(this), 200));
    if(UserAgent.browser().safari === true) {
      this.$body.addClass('safari');
    }
  },

  $body : $('body'), // deal with it.
  nav_items_limit:100,

  // Renders the nav with the current user state:
  render: function() {
    this.setNavItemsDisplay();
    var searchActive = this.$el.hasClass(SEARCH_ACTIVE_CLASS);

    if (searchActive) {
      var $searchbar = this.$('.c-global-header__search-menu');
      this.$el.find('input[name="q"]').focus();
      this.$body.on(CLICK_OFF_NS, (evt) => {
        if (!$(evt.target).closest($searchbar).length) {
          this.$el.removeClass(SEARCH_ACTIVE_CLASS);
          this.$body.off(CLICK_OFF_NS);
        }
      });
    }

    currentUser.fetch().then(() => {
      if (currentUser.isLoggedIn()) {
        var data = currentUser.toJSON();
        data.logout_url = currentContext.get('auth_url') + '/logout';
        var menu = `
          ${this.shouldToggleShowAndEditProfile(data)}
          <li class="c-global-header__login-menu-item logged_in_item"><a data-ui="logout" href="${data.logout_url}">Log out</a></li>
        `;

        this.$('[data-ui="menu"] .logged_out_item, [data-ui="menu"] .logged_in_item').remove();
        this.$('.c-global-header__login').removeClass('logged_out_menu').addClass('logged_in_menu');
        this.$('[data-ui="menu"]').append(menu);

        this.showMenuIcon(this.$('[data-ui="icon"]'), data);
        this.$body.addClass('chorus-logged-in');
        this.updateExclusiveLinks();

        if (data.auth0_id) {
          Analytics.event('auth-0', 'auth-0-log-in', 'auth-0-logged-in', data.auth0_id);
        }

        if (data.passes_paywall) {
          Analytics.event('paywall', 'paywall-status', 'passed-paywall', data.paywall_member_id);
        }

      }
    });

  },

  events: {
    'mouseenter .c-global-header__login' : 'onLoginEnter',
    'mouseleave .c-global-header__login' : 'onLoginLeave',
    'focus .c-global-header__login' : 'onLoginEnter',
    'blur .c-global-header__login' : 'onLoginLeave',
    'click .c-global-header__search-trigger' : 'onSearchToggleClick',
    'click .c-global-header__search' : 'onSearchAreaClick',
    'click .c-global-header__link' : 'onLinkClick',
    'click .c-global-header__link-more' : 'onLinkClick',
    'click .c-global-header__link-communities' : 'onLinkClick',
    'click [data-ui="logout"]': 'onLogoutClick',
    'site_sponsorship_logo_color_rendered' : 'onSiteSponsorshipLogoRender',
    'site_sponsorship_logo_mobile_color_rendered' : 'onSiteSponsorshipLogoRender',
    'site_sponsorship_logo_white_rendered' : 'onSiteSponsorshipLogoRender',
    'site_sponsorship_logo_mobile_white_rendered' : 'onSiteSponsorshipLogoRender'
  },

  // TOH: since user profiles are hidden, the cursor should change to default
  showMenuIcon: function(el, data) {
    const { currentNetwork: { attributes: { slug } } } = currentContext;
    const menuIcon = (
      (slug === 'thisoldhouse') ? el.css('cursor', 'default') : el.attr('href', currentUser.get('profile_url'))
    ).html(`<span class="sr-only">View profile</span><img src="${ data.profile_image_url }" alt="">`);
    return menuIcon;
  },

  // TOH: user profile should be hidden and diff 'Edit Profile' link
  shouldToggleShowAndEditProfile: function(data) {
    const { currentNetwork: { attributes: { slug } } } = currentContext;
    return  slug === 'thisoldhouse'
      ? '<li class="c-global-header__login-menu-item logged_in_item"><a href="https://profile.thisoldhouse.com/my-account/edit-account/">My Profile</a></li>'
      : `<li class="c-global-header__login-menu-item logged_in_item"><a href="${ data.profile_url }">${ data.username }<span class="sr-only"> (view profile)</span></a></li>
      <li class="c-global-header__login-menu-item logged_in_item"><a href="${ data.edit_profile_url }">Edit Profile</a></li>`;
  },

  setNavItemsDisplay : function() {
    var $moreLink = this.$('.c-global-header__link-more');
    var viewableWidth = 0;
    var navItemsWidth = 0;
    var dropdownInMore;
    var navItems = [];

    var useMore = false;

    viewableWidth += $('.c-global-header__links').width();

    // If more is not hidden, account for that in total link width
    if (!$moreLink.hasClass('hidden')) {
      navItemsWidth += $moreLink.width();
    }

    this.$('.c-global-header__link.is-pinned').each(function () {
      navItems.push(this);
    });

    this.$('.c-global-header__link').not('.is-pinned').each(function () {
      navItems.push(this);
    });

    var maxItems = this.nav_items_limit;

    navItems.forEach(function(item, idx) {
      var $item = $(item);
      navItemsWidth += $item.width();
      var itemId = $item.attr('data-nav-item-id');
      var navListElement = $('.c-nav-list').find('[data-nav-item-id=' + itemId + ']');

      if ((navItemsWidth > viewableWidth) || (idx >= maxItems)) {
        // Hide element in global nav
        $item.addClass('hidden');
        // Show element in more list
        navListElement.removeClass('hidden');
        useMore = true;
      } else {
        // Show element in global nav
        $item.removeClass('hidden');
        // Hide element in more list
        navListElement.addClass('hidden');
      }
    });

    // We need to ensure that each login URL heads back to the page we are on.
    // By default, we set this to be the domain (so that we can cache this cell
    // across pages), this will alter that with JS once we load the page.
    var returnToUrlEncoded = encodeURI(window.location.href);
    this.$('.c-global-header__login a[data-return-to]').each(function() {
      this.search = this.search.replace(/return_to=[^&]*/, 'return_to=' + returnToUrlEncoded);
    });

    // check to see if there's a dropdown in the more menu
    // because we need to display it differently
    dropdownInMore = $('.c-global-header__link.hidden[data-nav-list-trigger]');
    if(dropdownInMore.length > 0) {
      $('.c-nav-list').addClass('use-fixed-dropdown');
      $moreLink.addClass('use-fixed-dropdown');
    } else {
      $('.c-nav-list').removeClass('use-fixed-dropdown');
      $moreLink.removeClass('use-fixed-dropdown');
    }

    $moreLink.toggleClass('hidden', !useMore);
  },

  onLoginEnter : function() {
    this.$el.addClass('is-login-active');
    this.action_to = null;
  },

  onLoginLeave: function() {
    this.action_to = setTimeout(()=> {
      if (this.action_to !== null) {
        this.$el.removeClass('is-login-active');
      }
    }, 200);
  },

  onSearchAreaClick: function(evt) {
    evt.stopPropagation();
  },

  onSearchToggleClick: function(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    var searchActive = this.$el.hasClass(SEARCH_ACTIVE_CLASS);

    if (!searchActive) {
      var $searchbar = this.$('.c-global-header__search-menu');
      this.$el.find('input[name="q"]').focus();
      this.$body.on(CLICK_OFF_NS, (evt) => {
        if (!$(evt.target).closest($searchbar).length) {
          this.$el.removeClass(SEARCH_ACTIVE_CLASS);
          this.$body.off(CLICK_OFF_NS);
        }
      });
    } else {
      this.$body.off(CLICK_OFF_NS);
    }

    this.$el.toggleClass(SEARCH_ACTIVE_CLASS, !searchActive);
  },

  onLinkClick: function(evt) {
    Analytics.nav('click', evt.currentTarget.innerText.trim());
  },

  onLogoutClick: function(evt) {
    var link = evt.currentTarget;
    var href = link.href;
    var form = document.createElement('form');
    var input = document.createElement('input');
    input.type = 'submit';
    form.appendChild(input);
    form.method = 'post';
    form.action = href;
    form.style.display = 'none';
    document.body.appendChild(form);
    input.click();
    evt.preventDefault();
  },

  onSiteSponsorshipLogoRender: function(evt) {
    this.$el.addClass('has-site-sponsorship-logo');
    setTimeout(() => { this.setNavItemsDisplay(); }, 1000);
  },

  identifySubmenus : function() {
    var tabIndex = null;
    var anchors = this.$el.find('a, button');
    anchors.each((ixd, item) => {
      var anchor = $(item);
      var parent = anchor.parent();
      var triggerId = parent.attr('data-nav-list-trigger');
      anchor.attr('tabIndex', tabIndex);

      if (!triggerId) {
        parent.addClass('has-no-sublist');
      }
    });
  },
  updateExclusiveLinks : function () {
    var exclusiveLink = this.$('.c-global-header__exclusive-link');
    var exclusiveLinkNewUrl = exclusiveLink.attr('data-logged-in-url');
    if (exclusiveLinkNewUrl) {
      exclusiveLink.attr('href', exclusiveLinkNewUrl);
    }
  }
});

export default GlobalHeaderView;
