import './index.scss';
import _ from 'lib/utils';
import Initializer from 'lib/initializer';
import Detection from './scripts/adblock_detection';
import Messaging from './scripts/ad_allowlist_messaging';
import TrackAndRecord from './scripts/track_and_record';

Initializer.registerComponent('site/ad_allowlist', function(element, data) {
  let isTestUrl = _.deparamitize(location.search).allowlist_test;

  if (isTestUrl) return Messaging.appendMessaging(data.selector, data.messaging_urls[0], true);
  if (!data.adblocker_allowlist_prompt_enabled) return;

  Detection.checkForAdblock().then((adBlockPresent) => {
    if (TrackAndRecord.getAllowlistViews() > 0 && !adBlockPresent) {
      return TrackAndRecord.conversion();
    }

    if (TrackAndRecord.isConverted() && adBlockPresent) {
      return TrackAndRecord.lostConversion();
    }

    if (!adBlockPresent) {
      return;
    }

    TrackAndRecord.sessionPageview();
    TrackAndRecord.setupPageviewLengthTracking();
    Messaging.displayMessaging(data.selector, data.messaging_urls);
  });

}, { waitForConsent: true });
