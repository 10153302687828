import '../../apps/auth';
import './index.scss';
import Initializer from 'lib/initializer';
import EntryStats from 'models/entry_stats';
import EntryStatsView from './scripts/view';

Initializer.registerComponent('site/entry_stat', function(element, data) {
  var entry = EntryStats.add(data);
  new EntryStatsView({ el: element, model: entry });
});
