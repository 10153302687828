import Backbone from 'exoskeleton';
import $ from 'jquery';
import recaptchaLoader from 'lib/recaptcha_loader';
import Modal from 'lib/modal';

let ResendConfirmationView = Backbone.View.extend({

  events: {
    'click .c-resend-confirmation__button': 'handleClick'
  },

  initialize: function(evt) {
    this.$button = this.$('.c-resend-confirmation__button');
    this.$message = this.$('.c-auth__message');
    recaptchaLoader();
  },

  handleClick: function() {
    // Disable button to prevent double click, which will fail Recaptcha test
    this.$button.prop('disabled', true);

    var data = { 'g-recaptcha-response': this.$('[name="g-recaptcha-response"]').val() };

    this.fetch(data).then(() => {
      if (this.messageType == 'success') {
        this.$message.text('').hide();
        Modal.overlay(this.message);

        // Redirect to homepage
        window.setTimeout(function() { window.location = '/'; }, 5000);
      } else {
        this.$button.prop('disabled', false);
        this.$message.text(this.message).show();
      }
    });
  },

  /**
  * Requests confirmation email resend
  */
  fetch: function(data) {
    if (!this._load) {
      this._load = $.Deferred();

      $.ajax({
        url: '/chorus_auth/initiate_resend_confirmation',
        method: 'post',
        data: data,
        format: 'json',
        complete: (xhr) => {
          var response = JSON.parse(xhr.responseText);
          this.message = response.message;
          this.messageType = response.message_type;
          this._load.resolve();
          this._load = null;
        },
        error: () => {
          delete this._load;
        }
      });
    }

    return this._load.promise();
  }

});
export default ResendConfirmationView;
