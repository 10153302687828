import Backbone from 'exoskeleton';
import $ from 'jquery';

let DirectoryView = Backbone.View.extend({

  initialize: function() {

    setTimeout(()=>{ this.handleHashChange(); }, 500);
    $(window).on('hashchange', () => { this.handleHashChange(); } );
  },

  handleHashChange : function() {
    var selection = window.location.hash.slice(1);
    if (selection) {
      this.showSection(selection);
    } else {
      this.resetList();
    }
  },

  handleResetClick : function() {
    this.resetList();
  },

  resetList : function () {
    this.$el.removeClass('is-section-active').find('.is-active-section').removeClass('is-active-section');
  },

  showSection: function(section) {
    this.$el.find('.level-idx-1').text(this.$el.find('h2[data-section-title="' + section + '"]').text() + ' Neighborhoods');
    this.$el.find('is-active-section').removeClass('is-active-section');
    this.$el.addClass('is-section-active')
      .find('.c-directory__extended-body[data-body-for="' + section +'"]')
      .addClass('is-active-section');
    $('html, body').animate({
      scrollTop: this.$el.offset().top
    }, 500);
  },

  events: {
    'click .level-idx-0': 'handleResetClick'
  }

});
export default DirectoryView;
