import 'promise-polyfill';
import Initializer from 'lib/initializer';

var pending;

function load() {
  pending = pending || import(/* webpackChunkName: "snippet-anim" */ './scripts/main');
  return pending;
}

Initializer.registerComponent('site/snippet_animation', {
  build: function(element) {
    load().then(module => new module.default({ el: element }));
  }
});
