import '../dynamic_size_image';
import '../entry_box';
import './index.scss';
import Initializer from 'lib/initializer';
import Time from 'lib/time';

Initializer.registerComponent('site/user_activity', function(componentEl, data) {
  function updateTimestamps() {
    var timestamps = componentEl.querySelectorAll('[data-timestamp]');
    for (var i=0; i < timestamps.length; i++) {
      var el = timestamps[i];
      el.innerText = Time.relative(Number(el.getAttribute('data-timestamp')));
    }
  }

  setInterval(updateTimestamps, 1000 * 60);
});
