export default function ImagesliderApp(el) {
  this.el = el;

  el.addEventListener('mousemove', updateSlider.bind(this));
  el.addEventListener('touchstart', updateSlider.bind(this));
  el.addEventListener('touchmove', updateSlider.bind(this));
}

function updateSlider(e) {
  const rightImageContainer = this.el.querySelector('.c-imageslider__image--right');
  const { left, width } = rightImageContainer.getBoundingClientRect();
  const pageX = e.type == 'mousemove' ? e.pageX : e.changedTouches[0].pageX;
  const position = ((pageX - left) / width) * 100;

  if (position < 100 && position > 0) {
    this.el.querySelector('.c-imageslider__image--left').style.width = `${position}%`;
    this.el.querySelector('.c-imageslider__handle').style.left = `${position}%`;
  }
}
